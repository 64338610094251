import { ThemeOptions } from '@mui/material/styles';

const tabs: ThemeOptions['components'] = {
  MuiTabs: {
    styleOverrides: {
      root: {
        position: 'relative',
      },
      flexContainer: ({ theme }) => ({
        gap: theme.spacing(7.5),
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: 'max-content',
        [theme.breakpoints.up('md')]: {
          gap: theme.spacing(11),
        },
      }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: 'none',
        padding: 0,
        marginRight: theme.spacing(2),
        maxWidth: 'none',
      }),
    },
  },
};

export default tabs;
