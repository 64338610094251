enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  LOCAL = 'local',
}

// const { env: process.env } = process;

/** Expected environment variables used thoughout the app.
 * @remarks REMINDER: Please also list these varaible names in "./env.example".
 */

const envVar = {
  ENVIRONMENT: (process.env.NEXT_PUBLIC_ENVIRONMENT as Environment) || Environment.DEVELOPMENT,

  // APIs
  API_ORIGIN: process.env.NEXT_PUBLIC_API_ORIGIN as string,
  BFF_ORIGIN: process.env.NEXT_PUBLIC_BFF_ORIGIN as string,

  // API keys
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,

  // Analytics
  HOTJAR_ID: process.env.NEXT_PUBLIC_HOTJAR_ID as string,
  // SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY as string,

  // Crash reporting
  SENTRY_DSN: (process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN) as string,
  /** @remarks This env is explictly listed in the build script "./config/env.js". */
  SENTRY_RELEASE_PREFIX: process.env.NEXT_PUBLIC_SENTRY_RELEASE_PREFIX as string,
  /** @remarks This env is explictly listed in the build script "./config/env.js". */
  SENTRY_RELEASE: process.env.NEXT_PUBLIC_SENTRY_RELEASE as string,

  SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY as string,
};

const envUtils = {
  isClient: typeof window !== 'undefined',
  isServer: typeof window === 'undefined',
  isProduction: envVar.ENVIRONMENT === Environment.PRODUCTION,
  isStaging: envVar.ENVIRONMENT === Environment.STAGING,
  isDevelopment: [Environment.LOCAL, Environment.DEVELOPMENT].includes(envVar.ENVIRONMENT),
  showLogs: [Environment.LOCAL, Environment.DEVELOPMENT].includes(envVar.ENVIRONMENT),
};

const environment = { ...envVar, ...envUtils };

export default environment;
