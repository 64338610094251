import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import environment from '../../../../utils/environment';

export interface NotificationMessageProps {
  title?: string;
  message?: string;
  sentryId?: string;
  subMessage?: string;
  subMessageNumber?: string;
}

const SENTRY_ISSUE_URL = 'https://sentry.io/organizations/found-concrete/issues/2762278503/events/';

function SentryLink({ id }: { id?: string }) {
  if (!id) return null;
  // Don't attach a link to the error ID in production.
  if (environment.isProduction)
    return (
      <Typography gutterBottom variant="body2">
        {id}
      </Typography>
    );
  return (
    <Typography gutterBottom variant="body2">
      <Link role="link" variant="caption" underline="always" href={`${SENTRY_ISSUE_URL}${id}`}>
        {id}
      </Link>
    </Typography>
  );
}

export default function NotificationMessage({
  title,
  message,
  sentryId,
  subMessage,
  subMessageNumber,
}: NotificationMessageProps) {
  return (
    <>
      {!!title && <Typography variant="h6">{title}</Typography>}
      {!!message && (
        <Typography gutterBottom variant="body1">
          {message}
        </Typography>
      )}
      <SentryLink id={sentryId} />
      {!!subMessage && !!subMessageNumber && (
        <Typography variant="body1">
          {subMessage}
          <strong>{subMessageNumber}</strong>
        </Typography>
      )}
    </>
  );
}
