import omit from 'lodash/omit';
import { ActionType, createReducer } from 'typesafe-actions';

import {
  AppContextActionTypeNames,
  initiateQuoteToOrder,
  updateOrderSteps,
  updateQuoteSteps,
  updateValues,
} from './actions';
import { AppContextActionTypes, AppContextField, AppContextState } from './types';
import { OrderFilter, OrderMode } from '../../components/Orders/utils';
import { OrderStep } from '../../components/OrderBuilder/utils/formState';

export const initalAppContextState = (params?: Partial<AppContextState>): AppContextState => ({
  [AppContextField.FILTER]: OrderFilter.UPCOMING,
  [AppContextField.FRESH_CATALOGUE]: true,
  [AppContextField.IS_HUB]: false,
  [AppContextField.ORDER_MODE]: OrderMode.VIEW,
  [AppContextField.ORDER_STEP]: OrderStep.DETAILS,
  ...params,
});

const appReducer = createReducer<AppContextState, AppContextActionTypes>(initalAppContextState())
  .handleType(
    AppContextActionTypeNames.UPDATE_QUOTE_VALUES,
    (state, { payload }: ActionType<typeof updateQuoteSteps>) => ({
      ...omit(state, AppContextField.ORDER_ID),
      ...payload,
    })
  )
  .handleType(
    AppContextActionTypeNames.UPDATE_ORDER_VALUES,
    (state, { payload }: ActionType<typeof updateOrderSteps>) => ({
      ...state,
      ...payload,
    })
  )
  .handleType(AppContextActionTypeNames.UPDATE, (state, { payload }: ActionType<typeof updateValues>) => ({
    ...state,
    ...payload,
  }))
  .handleType(
    AppContextActionTypeNames.INITIATE_QUOTE_TO_ORDER,
    (state, { payload }: ActionType<typeof initiateQuoteToOrder>) => ({
      ...state,
      ...payload,
      [AppContextField.ORDER_MODE]: OrderMode.QUOTE_TO_ORDER,
    })
  )
  // Reset the app but keep the hub (which is set at a higher component parent level)
  .handleType(AppContextActionTypeNames.RESET, (state) =>
    initalAppContextState({ [AppContextField.IS_HUB]: state[AppContextField.IS_HUB] })
  );

export default appReducer;
