import {
  DEFAULT_MIN_VALUE_MESSAGE,
  MOBILE_INVALID_MESSAGE,
  OPTION_REQUIRED_MESSAGE,
  SITE_ACCESS_RESTRICTION_INVALID_MESSAGE,
  TEXT_REQUIRED_MESSAGE,
  maxOrderMessage,
  minOrderMessage,
  mobileRegExp,
  spaceOnlyRegExp,
} from './forms';
import { PrivateRoutePath, PublicRoutePath } from './routes';

import * as R from 'ramda';
import * as yup from 'yup';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import { CatalogueFieldType } from 'foundshared/src/api/catalogue/codecs';

import type { ServiceAreaResponse } from 'foundshared/src/api/serviceAreas/codecs';
import type { Option } from '../store/catalogues/types';
import type { OptionGroupName, OptionGroupsByProductTypes } from '../store/catalogues/selectors';
import type { OrderStep } from '../components/OrderBuilder/utils/formState';

export enum LocationFormField {
  POSTCODE = 'postcode',
  LOCALITY = 'locality',
  STATE = 'state',
  LAT = 'lat',
  LONG = 'long',
}

export enum DetailsFormField {
  LOCATION = 'location',
  QUANTITY = 'quantity',
  PRODUCT_TYPE_ID = 'productTypeId',
  COLOUR = 'colour',
  MPA = 'mpa',
  SLUMP = 'slump',
  AGG = 'agg',
  ADDITIVES = 'additives',
  ADD_MESSAGE = 'add_message',
  IS_COLOUR_MATCHING_JOB = 'is_colour_matching_job',
  MAX_QUANTITY_REQUIRED = 'max_quantity_required',
}

export enum SiteFormField {
  LOCATION = 'location',
  PLACEMENT = 'placement',
  TRUCK_SIZE = 'truck_size',
  TRUCK_SPACING = 'truck_spacing',
  ONSITE_PERSON_NAME = 'onsite_person_name',
  SUPPLIER_NOTES = 'supplier_notes',
  ONSITE_PERSON_MOBILE = 'onsite_person_mobile',
  DRIVER_NOTES = 'driver_notes',
  STREET_ADDRESS = 'street_address',
  SITE_ACCESS_RESTRICTION = 'site_access_restriction',
}

export enum DeliveryFormField {
  DELIVERY_AT = 'deliver_at',
}

export enum ConcreteCashField {
  APPLY_CASH = 'apply_cash',
}

enum FieldInputType {
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  STRING = 'string',
}

export const FIELD_INPUT_TYPES = {
  [CatalogueFieldType.RADIO_BUTTON]: FieldInputType.NUMBER,
  [CatalogueFieldType.SWATCH]: FieldInputType.NUMBER,
  [CatalogueFieldType.NUMBER_WITH_SLIDER]: FieldInputType.NUMBER,
  [CatalogueFieldType.NUMBER_WITH_SPINNER]: FieldInputType.NUMBER,
  [CatalogueFieldType.CHECKBOX]: FieldInputType.BOOLEAN,
  [CatalogueFieldType.TEXT_PAIR]: FieldInputType.STRING,
  [CatalogueFieldType.TEXT]: FieldInputType.STRING,
  [CatalogueFieldType.TIMESLOT]: FieldInputType.STRING,
  [CatalogueFieldType.STRING_CONDITIONAL]: FieldInputType.STRING,
  [CatalogueFieldType.STRING_MULTILINE]: FieldInputType.STRING,
};

export type LocationArea = Omit<ServiceAreaResponse, 'status'>;

const getYupType = (option: Option) => {
  const inputType = FIELD_INPUT_TYPES[option.typeCode];
  let type = yup[inputType]();

  if (
    [CatalogueFieldType.NUMBER_WITH_SLIDER, CatalogueFieldType.NUMBER_WITH_SPINNER].includes(
      option.typeCode as CatalogueFieldType
    ) &&
    inputType !== FieldInputType.BOOLEAN
  ) {
    // Declaring again for TypeScript's sake.
    type = yup[inputType]();

    if (isNil(option.min)) type = type.min(option.min, minOrderMessage(option.min, option.unit));

    if (isNil(option.max)) type = type.max(option.max, maxOrderMessage(option.max, option.unit));
  }

  if (option.required) type = type.required(OPTION_REQUIRED_MESSAGE);

  return type;
};

export const serviceAreaSchema = yup.object().shape({
  location: yup
    .object()
    .shape({
      [LocationFormField.POSTCODE]: yup.string().required(),
      [LocationFormField.LOCALITY]: yup.string().required(),
      // These fields are not passed when submitting a quote; so have to be ignored when converting into an order.
      [LocationFormField.STATE]: yup.string(),
      [LocationFormField.LAT]: yup.number(),
      [LocationFormField.LONG]: yup.number(),
    })
    .required(TEXT_REQUIRED_MESSAGE)
    .nullable(),
});

export const siteAccessRestrictionSchema = yup.object().shape({
  [SiteFormField.SITE_ACCESS_RESTRICTION]: yup
    .string()
    .matches(spaceOnlyRegExp, SITE_ACCESS_RESTRICTION_INVALID_MESSAGE)
    .defined(SITE_ACCESS_RESTRICTION_INVALID_MESSAGE)
    .nullable(true),
});

export const contactSchema = yup.object().shape({
  [SiteFormField.ONSITE_PERSON_NAME]: yup.string().required(TEXT_REQUIRED_MESSAGE),
  [SiteFormField.ONSITE_PERSON_MOBILE]: yup
    .string()
    .matches(mobileRegExp, MOBILE_INVALID_MESSAGE)
    .min(9, MOBILE_INVALID_MESSAGE)
    .max(10, MOBILE_INVALID_MESSAGE)
    .required(TEXT_REQUIRED_MESSAGE),
});

export const streetAddressSchema = yup.object().shape({
  [SiteFormField.STREET_ADDRESS]: yup.string().required(TEXT_REQUIRED_MESSAGE).nullable(),
});

export function mergeSchemas(...schemas: yup.ObjectSchema<Record<string, any>>[]) {
  const [first, ...rest] = schemas;

  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

  return merged;
}

export const getSchema = (options: Option[]) => {
  const schemaMap: Record<string, any> = {};
  options.forEach((option: Option) => {
    if (option.typeCode !== CatalogueFieldType.TEXT_PAIR) {
      if (option.required || option.min || option.max) schemaMap[option.name] = getYupType(option);
    }
  });
  return yup.object().shape(schemaMap);
};

export const minValueComparison = (fieldName: string, minValue: number, errorMessage?: string) =>
  yup.object().shape({
    [fieldName]: yup.number().min(minValue, errorMessage || DEFAULT_MIN_VALUE_MESSAGE),
  });

export const rangeStep = R.curry((start: number, end: number, step: number) =>
  R.unfold((n: number) => (n <= end ? [n, n + step] : false), start)
);

export function serviceAreaLabel(suggestion?: LocationArea | null) {
  if (R.isNil(suggestion)) return '';

  const suggestionKeys = pick(suggestion, ['locality', 'postcode', 'state']);

  return Object.values(suggestionKeys).filter(Boolean).join(', ');
}

export function getOptions(
  productTypeId: string | undefined,
  name: OptionGroupName,
  optionGroupsByProductTypes: OptionGroupsByProductTypes
): Option[] {
  const group = R.pathOr({}, [`${productTypeId}`, name.toString()], optionGroupsByProductTypes);
  const options = R.pathOr([], ['options'], group);
  return options;
}

export function getNextStepPath(step: OrderStep, isHub: boolean, orderId?: string) {
  if (isHub) {
    return orderId ? `${PrivateRoutePath.ORDERS}/${orderId}/edit/${step}` : `${PrivateRoutePath.ORDERS}/new/${step}`;
  }

  return `${PublicRoutePath.ORDERS}/new/${step}`;
}
