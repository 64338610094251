import * as palette from './palette';
import overrides from './overrides';
import typography from './typography';

import { createTheme } from '@mui/material/styles';

import type { PaletteMode } from '@mui/material';

declare module '@mui/material/styles/createTransitions' {
  export interface Easing {
    decelerate: string;
  }
}

declare module '@mui/material/styles' {
  interface Mixins {
    footer: { height: number };
  }
}

export const getTheme = (paletteType: PaletteMode = 'light') => {
  const theme = createTheme({
    typography,
    shape: { borderRadius: 8 },
    mixins: {
      toolbar: {
        minHeight: 64,
      },
      footer: {
        height: 90,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1440,
      },
    },
    transitions: {
      easing: {
        decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
      },
    },
    components: overrides,

    // palette: palette[paletteType],
    palette: palette.light,
  });

  return theme;
};
