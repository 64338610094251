import { TypographyOptions } from '@mui/material/styles/createTypography';

import { ColorPalette } from './palette/colorPalette';

const typography: TypographyOptions = {
  fontFamily: 'IBM Plex Sans',
  // Headline
  h1: {
    fontSize: '4.5rem',
    lineHeight: 1.25,
    fontWeight: 'bold',
    letterSpacing: '-0.01em',
  },
  // Heading 1
  h2: {
    fontSize: '2.5rem',
    lineHeight: '52px',
    fontWeight: 'bold',
    letterSpacing: '-0.01em',
  },
  // Heading 2
  h3: {
    fontSize: '2rem',
    lineHeight: '40px',
    fontWeight: 'bold',
    letterSpacing: '-0.01em',
  },
  // Heading 3
  h4: {
    fontSize: '1.5rem',
    lineHeight: 1.333,
    fontWeight: 'bold',
    letterSpacing: '-0.01em',
  },
  // Heading 4
  h5: {
    fontSize: '1.25rem',
    lineHeight: 1.3,
    fontWeight: 'bold',
    letterSpacing: '-0.01em',
  },
  // Heading 5
  h6: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: 1.33,
  },
  // Body bold
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
  },
  // Small body bold
  subtitle2: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.43,
  },
  // Body
  body1: {
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  // Small body
  body2: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
  },
  // Same as h5
  button: {
    fontSize: '1.25rem',
    lineHeight: 1.3,
    fontWeight: 'bold',
    letterSpacing: '-0.01em',
    textTransform: 'none',
  },
};

// Custom varients coming in MUI v5: https://github.com/mui-org/material-ui/issues/15573
export const extendedTypography = {
  input: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: 1.33,
  },
  inputPlaceholder: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: 1.33,
    color: ColorPalette.GREY_NICKEL,
    opacity: 1,
  },
};

export default typography;
