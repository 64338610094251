import handleError from '../../../../store/handleError';
import { Notifier } from '../../utilities';
import { fetch as fetchAction } from '../actions';

// import { fetchAvailabilityV2 } from 'foundshared/src/api/availability';
import { apiActions } from '../../../../api';

import { call, takeLatest } from 'typed-redux-saga';

function* fetch({ payload: { notifier, locality, postcode } }: ReturnType<typeof fetchAction>) {
  // locality: string, postcode: string, action: fetchPageProps) {
  // notify the action producer that this saga is now executing
  notifier.performing();
  try {
    const data = yield* call(apiActions.availability.fetchAvailabilityV2, locality, postcode);

    // yield* put(fetchPageSuccess(availability))

    // notify action producer that the operation was successful
    notifier.success(data.right);
  } catch (errors) {
    // yield* handleError(errors, put, function* () {
    //   if (errors instanceof ApiError) {
    //     // this is an API error
    //     action.notifier('FAILURE', errors)
    //   } else {
    //     // this is some other error so use a more generic error response
    //     action.notifier('FAILURE', genericError)
    //   }
    // })
  }
}

export default function* () {
  yield takeLatest(fetchAction.actionType, fetch);
}
