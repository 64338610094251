import { OptionValue } from '../../../../store/catalogues/types';

export const MIN_LOAD_FEE_QUANTITY = 3;

export interface Prices {
  basePrice: number;
  smallLoadUnitPrice: number;
}

/**
 *
 * @param value - OptionValue
 * @param prices - Catalogue prices
 * @param includeBase - accumulate base price with option price
 *
 * Returns option value price with basePrice if includeBase
 */
export const calculatePrice = (value: OptionValue, prices: Prices, includeBase?: boolean): number =>
  includeBase ? prices.basePrice + value.price : value.price;

/**
 *
 * @param value - OptionValue
 * @param prices - Catalogue prices
 * @param includeBase - accumulate base price with option price
 *
 * Returns a string with $ and + if not includeBase
 */
export const formattedValuePrice = (value: OptionValue, prices: Prices, includeBase?: boolean): string => {
  const price = calculatePrice(value, prices, includeBase);
  return includeBase ? `$${price}` : `+ $${price}`;
};

export function withGST(price: number) {
  return +price * 1.1;
}

/**
 *
 * @param values - OptionValue[] (order form values)
 * @param prices - Catalogue prices
 *
 * Returns the accumulated option value prices with Catalogue basePrice
 */
export function calculatePricePerCubicMeter(values: Array<OptionValue>, basePrice: number) {
  return values.reduce((acc: number, optionValue: OptionValue) => acc + optionValue.price, basePrice);
}

export function totalPrice(pricePerCubicMeter: number, quantity: number) {
  return +quantity * pricePerCubicMeter;
}

export const getSmallOrderFee = (quantity: number, loadFee: number) => {
  if (quantity === 0) return 0;
  if (quantity >= MIN_LOAD_FEE_QUANTITY) return 0;
  return (MIN_LOAD_FEE_QUANTITY - quantity) * loadFee;
};

export const totalRate = (pricePerCubicMeter: number, quantity: number, prices: Prices) =>
  totalPrice(pricePerCubicMeter, quantity) + getSmallOrderFee(quantity, prices.smallLoadUnitPrice);
