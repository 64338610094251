import typography from '../typography';

import type { ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    proceed: true;
  }
}

const buttons: ThemeOptions['components'] = {
  // New
  MuiButtonBase: {
    defaultProps: { disableRipple: true },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained', color: 'secondary' },
        style: ({ theme: { palette } }) => ({
          '&.Mui-disabled': {
            backgroundColor: palette.secondary.main,
            opacity: 0.5,
            color: palette.common.white,
          },
        }),
      },
    ],

    defaultProps: { variant: 'contained', color: 'standard', disableRipple: true },
    styleOverrides: {
      root: ({ theme }) => ({
        // color: theme.palette.primary.light,
        padding: theme.spacing(2.25, 3),
        ...theme.typography.h6,
        textTransform: 'none',
      }),
      contained: () => ({
        // backgroundColor: theme.palette.secondary.lightest,
        '&:hover': {
          // backgroundColor: theme.palette.secondary.lightest,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            // backgroundColor: theme.palette.secondary.lightest,
          },
        },
      }),
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(1.5, 2.25),
        ...typography.subtitle1,
      }),
      sizeLarge: ({ theme }) => ({
        padding: theme.spacing(2.5, 4),
        ...typography.h5,
      }),
    },
  },
};

export default buttons;
