type BrandContent = {
  companyName: string;
  name: string;
  orderTitle: string;
  signUpButton: string;
  accountsEmail: string;
  applicationFormLink: string;
  fullName: string;
  logoAlt: string;
};

const FOUND_BRAND_CONTENT: BrandContent = {
  logoAlt: 'Found Concrete logo',
  fullName: 'Found Concrete',
  name: 'Found',
  companyName: 'Found Concrete Pty Ltd',
  orderTitle: 'Found order #',
  signUpButton: 'Sign-up to Found',
  accountsEmail: 'accounts@foundconcrete.com.au',
  applicationFormLink: 'https://about.foundconcrete.com.au/credit-application-form',
};

// Not sure what point of doing this is?
export function getBrandContent(): BrandContent {
  return FOUND_BRAND_CONTENT;
}
