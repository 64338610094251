import { action } from 'typesafe-actions';

import { AppContextField, AppContextStateBase, AppOrderContextState, AppQuoteContextState } from './types';
import { OrderStep } from '../../components/OrderBuilder/utils/formState';

export enum AppContextActionTypeNames {
  UPDATE = 'app/UPDATE_VALUES',
  UPDATE_QUOTE_VALUES = 'app/UPDATE_QUOTE_VALUE',
  UPDATE_ORDER_VALUES = 'app/UPDATE_ORDER_VALUE',
  INITIATE_QUOTE_TO_ORDER = 'app/INITIATE_QUOTE_TO_ORDER',
  RESET = 'app/RESET',
}

interface InitiateQuoteToOrderPayload {
  [AppContextField.QUOTE_ID]?: string;
  /** This is also when dealing with initiating an order from a quote */
  [AppContextField.ORDER_STEP]: OrderStep;
}

export const updateValues = (payload: Partial<AppContextStateBase>) =>
  action(AppContextActionTypeNames.UPDATE, payload);

export const updateOrderSteps = (payload: Partial<AppOrderContextState>) =>
  action(AppContextActionTypeNames.UPDATE_ORDER_VALUES, payload);

/** This also sets OrderMode = QUOTE_TO_ORDER in the reducer */
export const initiateQuoteToOrder = (payload: InitiateQuoteToOrderPayload) =>
  action(AppContextActionTypeNames.INITIATE_QUOTE_TO_ORDER, payload);

export const updateQuoteSteps = (payload: Partial<AppQuoteContextState>) =>
  action(AppContextActionTypeNames.UPDATE_QUOTE_VALUES, payload);

export const resetApp = () => action(AppContextActionTypeNames.RESET);
