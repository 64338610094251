import type { CSSObject } from 'tss-react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { StyleProps } from './DesktopItem';

export const useMenuItemStyles = makeStyles<StyleProps>()(({ palette, typography, spacing }: Theme, { dark }) => ({
  root: {
    ...(typography.subtitle1 as CSSObject),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    color: dark ? palette.common.white : palette.text.primary,
    opacity: 1,
    fontFamily: 'Articulat CF, sans-serif',

    '&:hover': {
      color: dark ? palette.common.white : palette.text.primary,
      opacity: 1,
    },
  },
}));

export const useActiveMenuItemStyles = makeStyles<StyleProps>()(({ palette, typography }: Theme, { dark }) => ({
  active: {
    ...(typography.subtitle1 as CSSObject),
    color: dark ? palette.common.white : palette.text.primary,
    opacity: 1,
  },
}));
