import { SessionActionTypes } from './types';

import { action } from 'typesafe-actions';

import type { Session, Signup } from 'foundshared/src/api/session/types';
import type { SignUpPayload } from 'foundshared/src/api/signup/types';
import type { AuthContextFields } from '../../utils/authFormState';
import type { ProfileCallback, TrackingCallback } from '../profile/actions';
import type { TradeCustomerSignUpPayload } from '../../components/TradeDiscount/utils/form';

// REQUEST CODE
export const requestCodePerform = (mobile: string, meta?: { callback: () => void }) =>
  action(SessionActionTypes.REQUEST_CODE_PERFORM, { mobile }, meta);

export const requestCodeSuccess = (data: any) => action(SessionActionTypes.REQUEST_CODE_SUCCESS, data);

export const requestCodeError = (error: Error) => action(SessionActionTypes.REQUEST_CODE_ERROR, error);

export type SignupMeta = {
  callback?: (signupSuccessful?: boolean) => void;
  profileCallback?: ProfileCallback;
  trackingCallback?: TrackingCallback;
};

export interface SessionMeta extends SignupMeta {
  guestSession?: boolean;
}

// CREATE SESSION
export const createSessionPerform = (payload: AuthContextFields, meta?: SessionMeta) =>
  action(SessionActionTypes.CREATE_SESSION_PERFORM, payload, meta);

export const createSessionSuccess = (payload: Session, meta?: { guestSession?: boolean }) =>
  action(SessionActionTypes.CREATE_SESSION_SUCCESS, payload, meta);

export const createSessionError = (error: Error) => action(SessionActionTypes.CREATE_SESSION_ERROR, error);

// LOGOUT
export const deletePerform = (meta?: { callback?: void | (() => void) }) =>
  action(SessionActionTypes.DELETE_PERFORM, undefined, meta);

// logout success resets state. check src/store/reducer.ts
export const deleteSuccess = (data: any) => action(SessionActionTypes.DELETE_SUCCESS, data);

export const deleteError = (error: Error) => action(SessionActionTypes.DELETE_ERROR, error);

export const signupOrSigninPerform = (payload: SignUpPayload | TradeCustomerSignUpPayload, meta?: SignupMeta) =>
  action(SessionActionTypes.SIGNUP_OR_SIGNIN_PERFORM, payload, meta);

export const signupPerform = (payload: SignUpPayload | TradeCustomerSignUpPayload, meta?: SignupMeta) =>
  action(SessionActionTypes.SIGNUP_PERFORM, payload, meta);

export const signupSuccess = (payload: Signup) => action(SessionActionTypes.SIGNUP_SUCCESS, payload);

export const signupError = (error: Error) => action(SessionActionTypes.SIGNUP_ERROR, error);

export const storePricingRegion = (pricingRegionId: string) =>
  action(SessionActionTypes.STORE_PRICING_REGION_ID, pricingRegionId);
