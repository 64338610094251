export enum NotificationActionTypes {
  ADD = '@@notifications/ADD',
  REMOVE = '@@notifications/REMOVE',
}

export enum NotificationType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum NotificationKind {
  SNACKBAR,
  DIALOGUE,
}

interface NotificationBase {
  id: string;
  title?: string;
  message: string;
  sentryId?: string;
  type: NotificationType;
}
export interface SnackBarNotification extends NotificationBase {
  kind: NotificationKind.SNACKBAR;
}

export interface DialogueNotification extends NotificationBase {
  kind: NotificationKind.DIALOGUE;
}

export type Notification = SnackBarNotification | DialogueNotification;

export interface NotficationActionConfig {
  kind?: NotificationKind;
  title?: string;
  sentryId?: NotificationBase['sentryId'];
  subMessage?: string;
  subMessageNumber?: string;
}
