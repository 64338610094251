import { ProfileActionTypes } from './types';

import { action } from 'typesafe-actions';

import type { NormalizedSchema } from 'normalizr';
import type { Customer, CustomerUpdate, Profile } from 'foundshared/src/api/profile/types';
import type { PricingEntities } from 'foundshared/src/api/pricing/types';


export type ProfileCallback = void | ((profile: Profile) => void);
export type TrackingCallback = void | ((isNewCustomer: boolean) => void);

type FetchMeta = {
  callback?: ProfileCallback;
};

// UPDATE CODE
type UpdateData = DeepPartial<Omit<Profile, 'id' | 'mobile'>>;

export const fetchProfilePerform = (meta?: FetchMeta) => action(ProfileActionTypes.FETCH_PERFORM, {}, meta);

export const fetchProfileSuccess = (data: NormalizedSchema<PricingEntities, Profile>) =>
  action(ProfileActionTypes.FETCH_SUCCESS, data);

export const fetchProfileError = (error: Error) => action(ProfileActionTypes.FETCH_ERROR, error);

export const updatePerform = (data: UpdateData, meta?: { onCreateOrder: () => void }) =>
  action(ProfileActionTypes.UPDATE_PERFORM, data, meta);

export const updateSuccess = (data: NormalizedSchema<PricingEntities, Profile>) =>
  action(ProfileActionTypes.UPDATE_SUCCESS, data);

export const updateError = (error: Error) => action(ProfileActionTypes.UPDATE_ERROR, error);

export const updateCustomerPerform = (data: CustomerUpdate, meta?: Record<string, any>) =>
  action(ProfileActionTypes.CUSTOMER_UPDATE_PERFORM, data, meta);

export const updateCustomerSuccess = (data: Customer) => action(ProfileActionTypes.CUSTOMER_UPDATE_SUCCESS, data);

export const updateCustomerError = (error: Error) => action(ProfileActionTypes.CUSTOMER_UPDATE_ERROR, error);
