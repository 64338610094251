import { RefObject, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import tracking from '.';
import { TrackingEventSource, TrackingEventTrait, TrackingLinkEvent } from './types';
import { authenticated$ } from '../../store/session/selectors';

function useTrackLinkSource(
  trackingLink?: TrackingLinkEvent,
  linkRef?: RefObject<HTMLElement>,
  source?: TrackingEventSource
) {
  const isAuthenticated = useSelector(authenticated$);

  const getSource = useCallback(() => {
    switch (trackingLink) {
      case TrackingLinkEvent.VIEW_CONTACT_US:
        return isAuthenticated ? TrackingEventSource.ORDER_HUB : TrackingEventSource.NOT_SIGNED_IN;
      default:
        return undefined;
    }
  }, [isAuthenticated, trackingLink]);

  useEffect(() => {
    if (trackingLink && linkRef?.current) {
      const sourceCheck = source ?? getSource();

      const sourceFormatted = sourceCheck ? { [TrackingEventTrait.SOURCE]: sourceCheck } : undefined;
      tracking.trackLink(linkRef.current, trackingLink, sourceFormatted);
    }
  }, [getSource, linkRef, source, trackingLink]);
}

export default useTrackLinkSource;
