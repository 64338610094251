import { max } from '@segment/snippet';
import { memo } from 'react';

import env from '../../utils/environment';

const script = () => {
  if (!env.isProduction) return null;

  const snippet = max({
    host: 'cdn.segment.com',
    apiKey: env.SEGMENT_KEY,
  });

  return <script dangerouslySetInnerHTML={{ __html: snippet }} />;
};

export default memo(script);
