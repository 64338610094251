import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Alert, { AlertProps } from '@mui/material/Alert';

function AlertBox(props: AlertProps) {
  return (
    <Alert
      iconMapping={{
        info: <InfoIcon fontSize="inherit" />,
        warning: <WarningIcon fontSize="inherit" />,
        error: <ErrorIcon fontSize="inherit" />,
      }}
      {...props}
    />
  );
}

export default AlertBox;
