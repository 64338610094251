import { requestInterceptor, responseInterceptor, responseErrorInterceptor } from './interceptors';
import switchBaseUrl from './switchBaseUrl';

import environment from '../utils/environment';

import apiSetup from 'foundshared/src/api';

const { apiInstance: axiosInstance, requests: apiActions } = apiSetup({ baseURL: environment.API_ORIGIN });

axiosInstance.defaults.baseURL = environment.API_ORIGIN;

axiosInstance.interceptors.request.use(requestInterceptor);

axiosInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

if (!environment.isProduction) switchBaseUrl(axiosInstance);

export { apiActions, axiosInstance };
