import handleError from '../../../../store/handleError';
import { fetchSuggestions as fetchAction, fetchSuggestionsSuccess } from '../actions';
import { apiActions } from '../../../../api';

import { call, put, takeLatest } from 'typed-redux-saga';

function* fetch({ payload: { notifier, query } }: ReturnType<typeof fetchAction>) {
  // notify the action producer that this saga is now executing
  notifier.performing();
  try {
    const result = yield* call(apiActions.serviceAreas.fetchSuggestionsV2, query);
    // notify action producer that the operation was successful
    notifier.success(result.right);
    yield* put(fetchSuggestionsSuccess(result.right));
  } catch (error) {
    // yield* handleError(error, put, function* () {
    //   yield* put(addError('There was a problem fetching suggested locations'));
    //   if (error instanceof Error) yield* put(fetchError(error));
    // });
  }
}

export default function* () {
  yield takeLatest(fetchAction.actionType, fetch);
}
