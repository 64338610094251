import { ReferralsActionTypes, ReferrerActionTypes } from './types';

import { ReferrerStoreField } from 'foundshared/src/api/referrals/constants';
import { action } from 'typesafe-actions';

import type { ReceivedReferrer, ReferralsCollection, Referrer } from 'foundshared/src/api/referrals/types';


type RequiedSlug = Required<Referrer[ReferrerStoreField.SLUG]>;

export const fetchReferralsPerform = () => action(ReferralsActionTypes.FETCH_PERFORM);

export const fetchReferralsSuccess = (data: ReferralsCollection) => action(ReferralsActionTypes.FETCH_SUCCESS, data);

export const fetchReferralsError = (error: Error) => action(ReferralsActionTypes.FETCH_ERROR, error);

export const fetchReferrerPerform = (slug: RequiedSlug) => action(ReferrerActionTypes.FETCH_PERFORM, slug);
export const fetchReferrerSuccess = (data: ReceivedReferrer, slug: RequiedSlug) =>
  action(ReferrerActionTypes.FETCH_SUCCESS, { ...data, [ReferrerStoreField.SLUG]: slug });
export const fetchReferrerError = (slug: RequiedSlug) => action(ReferrerActionTypes.FETCH_ERROR, slug);
