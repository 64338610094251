import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import React, { useEffect, useState } from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';

import * as notificationSnackBarStyles from './NotificationSnackBar.styles';
import NotificationMessage from './components/NotificationMessage';
import { ColorPalette } from '../../design/palette/colorPalette';
import { Notification, NotificationType } from '../../store/notifications/types';
import { remove } from '../../store/notifications/actions';
import { snackbarNotifications$ } from '../../store/notifications/selectors';

const snackbarStyles = {
  [NotificationType.INFO]: {
    borderColor: ColorPalette.UI_DEFAULT_BACKGROUND,
    backgroundColor: ColorPalette.UI_DEFAULT_BACKGROUND,
  },
  [NotificationType.WARNING]: {
    borderColor: ColorPalette.UI_WARNING,
    backgroundColor: ColorPalette.UI_WARNING_BACKGROUND,
  },
  [NotificationType.ERROR]: {
    borderColor: ColorPalette.UI_ERROR,
    backgroundColor: ColorPalette.UI_ERROR_BACKGROUND,
  },
  [NotificationType.SUCCESS]: {
    borderColor: ColorPalette.UI_SUCCESS,
    backgroundColor: ColorPalette.UI_SUCCESS_BACKGROUND,
  },
};

function NotificationSnackBar() {
  const dispatch = useDispatch();
  const notifications = useSelector(snackbarNotifications$);
  const [notification, setNotification] = useState<Notification | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const snackbarType = notification?.type ?? NotificationType.INFO;
  const styles = snackbarStyles[snackbarType ?? NotificationType.INFO];

  const { classes: snackBarBaseClasses } = notificationSnackBarStyles.useSnackBarBase();
  const { classes: snackBarThemeClasses } = notificationSnackBarStyles.useSnackBarTheme(styles);

  useEffect(() => {
    if (!notifications.length) {
      setNotification(undefined);
    }

    if (notifications.length && notifications[0] !== notification) {
      setNotification(notifications[0]);
      setTimeout(() => setOpen(true), 500);
    }
  }, [notification, notifications]);

  const removeNotification = () => {
    if (notification) {
      setOpen(false);
      dispatch(remove(notification.id));
    }
  };

  const handleClose = (_event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;
    removeNotification();
  };

  if (!notification) return null;

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <SnackbarContent
        message={<NotificationMessage {...notification} />}
        classes={snackBarBaseClasses}
        className={snackBarThemeClasses.root}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={removeNotification}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Snackbar>
  );
}

export default NotificationSnackBar;
