import React, { memo } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Theme, useTheme } from '@mui/material/styles';

interface Props extends SvgIconProps {
  alt?: boolean;
  dark?: boolean;
}

function Calling({ dark = false, ...props }: Props) {
  const { palette }: Theme = useTheme();

  const foreground = dark ? palette.secondary.lightest : palette.primary.main;

  return (
    <SvgIcon width={24} height={24} {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.36 2a.85.85 0 00-.95.76c-.05.48.28.9.75.96a6.91 6.91 0 016.13 6.14.86.86 0 101.7-.2 8.62 8.62 0 00-7.63-7.65zm.06 3.49a.86.86 0 00-.33 1.69 3.46 3.46 0 012.75 2.75.86.86 0 101.68-.33 5.17 5.17 0 00-4.1-4.11zm1.58 9.6c.45-.26.96-.55 1.6-.42.59.13 2.57 1.74 3.12 2.3.35.36.55.74.58 1.12.06 1.48-1.96 3.17-2.32 3.38-.5.35-1.06.53-1.7.53a5.2 5.2 0 01-2.16-.55c-4.29-1.8-10.84-8.22-12.58-12.48-.72-1.58-.72-2.89 0-3.87.27-.46 1.9-2.39 3.34-2.33.39.04.76.24 1.13.6.55.54 2.13 2.53 2.25 3.12.13.65-.16 1.16-.42 1.62l-.22.34c-.31.48-.73 1.12-.58 1.53a11.5 11.5 0 006.1 5.92c.4.14 1.03-.28 1.51-.6l.34-.2H16z"
        fill={foreground}
      />
    </SvgIcon>
  );
}

export default memo(Calling);
