import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import * as errorNoticeStyles from './ErrorNotice.styles';
import CallingIcon from '../../assets/icons/CallingIcon';
import Contact from '../../utils/contacts';
import EmailIcon from '../../assets/icons/EmailIcon';
import { formatPhoneNumber } from '../../utils/formatting';

function ErrorNotice() {
  const { classes } = errorNoticeStyles.useWrapper();

  const nationalPhoneNumber = formatPhoneNumber(Contact.PHONE);

  return (
    <div className={classes.root}>
      <Box>
        <Typography variant="body1">If you keep encountering this problem you can:</Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <CallingIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body1' }}>
              call us on <MuiLink href={`tel:${Contact.PHONE}`}>{nationalPhoneNumber}</MuiLink>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body1' }}>
              email us at <MuiLink href="mailto:hello@foundconcrete.com.au">hello@foundconcrete.com.au</MuiLink>
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="body1">
          For more ways to contact us please visit:{' '}
          <MuiLink href="https://hello.foundconcrete.com.au/support">foundconcrete.com.au/support</MuiLink>
        </Typography>
      </Box>
    </div>
  );
}

export default ErrorNotice;
