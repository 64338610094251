import 'intersection-observer/intersection-observer';

import smoothScroll from 'smoothscroll-polyfill';

import environment from './environment';

if (environment.isClient) smoothScroll.polyfill();

void (async () => {
  if (environment.isClient && 'ResizeObserver' in window === false) {
    // Loads polyfill asynchronously, only if required.
    // eslint-disable-next-line @next/next/no-assign-module-variable
    const module = await import('@juggle/resize-observer');
    window.ResizeObserver = module.ResizeObserver;
  }
})();
