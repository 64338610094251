import typography from '../typography';

import { alpha } from '@mui/material';

import type { ThemeOptions } from '@mui/material/styles';

const buttons: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: { variant: 'contained', color: 'standard' },
    styleOverrides: {
      root: ({ theme }) => ({
        // color: theme.palette.primary.light,
        boxShadow: theme.shadows[1],
        padding: theme.spacing(2, 3),
        ...theme.typography.h6,
      }),
      contained: () => ({
        // backgroundColor: theme.palette.secondary.lightest,
        '&:hover': {
          // backgroundColor: theme.palette.secondary.lightest,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            // backgroundColor: theme.palette.secondary.lightest,
          },
        },
      }),
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(1.25, 2.25),
        ...typography.subtitle1,
      }),
      sizeLarge: ({ theme }) => ({
        padding: theme.spacing(2, 4),
        ...typography.h5,
      }),
    },
  },
  MuiFab: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.proceed.main,
        color: theme.palette.secondary.lightest,
        boxShadow: theme.shadows[1],
        '&:hover': {
          backgroundColor: alpha(theme.palette.proceed.main, 1 - theme.palette.action.hoverOpacity),
        },
      }),
    },
  },
  MuiButtonGroup: {
    defaultProps: { variant: 'contained' },
    styleOverrides: {
      contained: ({ theme }) => ({
        boxShadow: theme.shadows[1],
        '& .Mui-grouped': {
          '& [class*="MuiButton-label"]': {
            padding: 0,
            minWidth: 120,
          },
        },
        '& [class*="MuiButton-containedSizeSmall"]': {
          padding: theme.spacing(1),
        },
      }),
    },
  },
};

export default buttons;
