import * as R from 'ramda';
import Box from '@mui/material/Box';

import * as menuListStyles from './MenuList.styles';
import MobileItem from '../MobileItem';
import ProceedButton from '../../../ProceedButton';
import { LinkProps } from '../../utils/navItems';
import { handleTradeDiscountClick, tradeDiscountRoute } from '../../TopToolbar';

export interface MenuListProps {
  isAuthenticated: boolean;
  onToggle: () => void;
  unauthLinks: Array<LinkProps>;
  authLinks: Array<LinkProps>;
}

function MenuList({ isAuthenticated, onToggle, unauthLinks, authLinks }: MenuListProps) {
  const { classes: listClasses } = menuListStyles.useListStyles();

  const unauthenticatedLinks = R.map((item) => <MobileItem key={item.id} {...item} />, unauthLinks);

  const authenticatedLinks = R.map((item) => <MobileItem key={item.id} {...item} />, authLinks);

  return (
    <div className={listClasses.root} role="presentation" onClick={onToggle} onKeyDown={onToggle}>
      {isAuthenticated ? (
        <>{authenticatedLinks}</>
      ) : (
        <>
          {unauthenticatedLinks}
          <Box mt="auto" m={1}>
            <ProceedButton fullWidth size="small" onClick={handleTradeDiscountClick} href={tradeDiscountRoute.pathname}>
              Get a trade discount
            </ProceedButton>
          </Box>
        </>
      )}
    </div>
  );
}

export default MenuList;
