import { OrderActionTypes } from './types';

import { action } from 'typesafe-actions';

import type { OrderCreationValues, OrderUpdateValues } from 'foundshared/src/api/orders/types';


export const fetchOrdersPerform = (meta?: any) => action(OrderActionTypes.FETCH_PERFORM, undefined, meta);

export const fetchOrdersSuccess = (data: any, meta?: any) => action(OrderActionTypes.FETCH_SUCCESS, data, meta);

export const fetchOrdersFirstPerform = (meta?: any) => action(OrderActionTypes.FETCH_PERFORM_FIRST, undefined, meta);

export const fetchOrdersFirstSuccess = (data: any, meta?: any) =>
  action(OrderActionTypes.FETCH_SUCCESS_FIRST, data, meta);

export const fetchOrdersError = (error: Error) => action(OrderActionTypes.FETCH_ERROR, undefined, undefined, error);

export const loadPerform = (id: string) => action(OrderActionTypes.LOAD_PERFORM, { id });

export const loadSuccess = (data: any, meta?: any) => action(OrderActionTypes.LOAD_SUCCESS, data, meta);

export const loadError = (error: Error) => action(OrderActionTypes.LOAD_ERROR, undefined, undefined, error);

export const startPolling = (id: string) => action(OrderActionTypes.START_POLLING, { id });

export const stopPolling = () => action(OrderActionTypes.STOP_POLLING);

export const createOrderPerform = (data: OrderCreationValues, meta?: { callback: (id: string) => void }) =>
  action(OrderActionTypes.CREATE_PERFORM, data, meta);

export const createOrderSuccess = (payload: any, meta?: any) => action(OrderActionTypes.CREATE_SUCCESS, payload, meta);

export const updateOrderPerform = (id: string, data: OrderUpdateValues, meta?: any) =>
  action(OrderActionTypes.UPDATE_PERFORM, { id, data }, meta);

export const updateOrderSuccess = (payload: any, meta?: any) => action(OrderActionTypes.UPDATE_SUCCESS, payload, meta);

export const updateOrderError = (error: Error) => action(OrderActionTypes.UPDATE_ERROR, undefined, undefined, error);

export const createOrderError = (error: Error) => action(OrderActionTypes.CREATE_ERROR, undefined, undefined, error);

export const deleteOrderPerform = (id: string, meta?: any) => action(OrderActionTypes.DELETE_PERFORM, { id }, meta);

export const deleteOrderSuccess = (id: string, invoiceIds: string[] | undefined, meta?: any) =>
  action(OrderActionTypes.DELETE_SUCCESS, { id, invoiceIds }, meta);

export const deleteOrderError = (error: Error) => action(OrderActionTypes.DELETE_ERROR, undefined, undefined, error);
