import { ActionType, StateType } from 'typesafe-actions';

const initialState = {};

type IntialStateType = typeof initialState;

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state: IntialStateType = initialState, action: ActionType<any>) => {
  const { type } = action;
  const matches = /(.*)\/(FETCH|LOAD)_.*(PERFORM|SUCCESS|ERROR)/.exec(type);

  if (!matches) return state;

  const [, requestName, , requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'PERFORM',
  };
};

export type LoadingState = StateType<typeof reducer>;

export default reducer;
