import tracking from '.';

import { TrackingLinkEvent } from './types';

import { useEffect, useRef } from 'react';

function useLinkTracker(event: keyof typeof TrackingLinkEvent) {
  const linkRef = useRef<HTMLButtonElement & HTMLAnchorElement>(null);

  useEffect(() => {
    if (linkRef.current) tracking.trackLink(linkRef.current, TrackingLinkEvent[event]);
  }, [event, linkRef]);

  return linkRef;
}

export default useLinkTracker;
