import Link from 'next/link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ReactElement, useEffect, useRef } from 'react';

import tracking from '../../../../utils/tracking';
import { LinkProps } from '../../utils/navItems';

export default function MobileItem({ id, to, primary, onClick, href, trackingLink }: LinkProps): ReactElement {
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (trackingLink && linkRef.current) {
      tracking.trackLink(linkRef.current, trackingLink);
    }
  }, [trackingLink]);

  if (to) {
    return (
      <Link passHref href={to}>
        <ListItem component="a" key={id}>
          <ListItemText primary={primary} />
        </ListItem>
      </Link>
    );
  }
  if (onClick) {
    return (
      <ListItem button key={id} onClick={onClick}>
        <ListItemText primary={primary} />
      </ListItem>
    );
  }
  return (
    <ListItem ref={linkRef} button key={id} component="a" href={href || ''}>
      <ListItemText primary={primary} />
    </ListItem>
  );
}
