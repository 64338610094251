export enum CartActionTypes {
  FETCH_CATALOGUE_PERFORM = '@@cart/FETCH_CATALOGUE_PERFORM',
  FETCH_CATALOGUE_SUCCESS = '@@cart/FETCH_CATALOGUE_SUCCESS',
  FETCH_CATALOGUE_ERROR = '@@cart/FETCH_CATALOGUE_ERROR',
  FETCH_CATALOGUE_RESET = '@@cart/FETCH_CATALOGUE_RESET',

  UPDATE_QUOTE_CART = '@@cart/UPDATE_QUOTE_CART',
  UPDATE_CATALOGUE_CART = '@@cart/UPDATE_CATALOGUE_CART',

  UPDATE_CART = '@@cart/UPDATE_CART',
  CLEAR_CART = '@@cart/CLEAR_CART',

  CLEAR = '@@cart/CLEAR',
}
