import { action } from 'typesafe-actions';

// Useful error type, mirrors errors sent by backend
export interface Errors {
  errorTitle?: string;
  errorMessages?: Array<string>;
  validationMessages?: Record<string, Array<string>>;
}

// Useful abstraction used by sagas/async processes to 'notify' components about
// status & data changes, permitting react components to set state in response to those
// notifications
export type Notifier<DATA> = {
  performing: (message?: string) => unknown;
  success: (data: DATA) => unknown;
  failure: (errors: Errors) => unknown;
};

// Utility used to create actions easily & neatly
// Usage:
//
// const declareAction = declareActionFactory('@@availabilities')
// const fetchPage = declareAction<PAYLOAD_TYPE, META_TYPE>('fetchPage')
//
// dispatch(fetchPage(payload: PAYLOAD_TYPE, meta?: META_TYPE>)
//
// or
//
// function* mySaga(action: ReturnType<typeof fetchPage>) { ... }
// ...
// takeLatest(fetchPage.actionType, mySaga)
//
export function declareActionFactory(actionRoot: string) {
  return <P = unknown, M = unknown>(actionName: string) => {
    const actionType = `${actionRoot}/${actionName}`;

    const func = (payload: P, meta?: M) => action(actionType, payload, meta);
    func.actionType = actionType;

    return func;
  };
}
