import { ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    selectable: true;
  }
}

const chips: ThemeOptions['components'] = {
  MuiChip: {
    variants: [
      {
        props: { variant: 'selectable' },
        style: ({ theme: { shape, palette, transitions } }) => ({
          borderRadius: shape.borderRadius,
          border: `1px solid ${palette.divider}`,
          backgroundColor: palette.common.white,
          height: 'auto',
          outline: `2px solid transparent`,
          outlineOffset: '2px',
          transition: transitions.create(['background-color', 'border-color', 'outline-color'], {
            duration: 100,
            easing: transitions.easing.decelerate,
          }),
          '&.Mui-focusVisible': {
            outlineColor: palette.secondary.darker ?? palette.secondary.dark,
            outlineOffset: '2px',
          },
        }),
      },
      {
        props: { variant: 'selectable', color: 'primary' },
        style: ({ theme: { palette } }) => ({
          border: `1px solid ${palette.primary.main}`,
          backgroundColor: palette.primary.main,
          '&.Mui-focusVisible': {
            backgroundColor: palette.common.white,
          },
          '&:active': {
            boxShadow: 'none',
          },
        }),
      },
      {
        props: { variant: 'selectable', color: 'default' },
        style: ({ theme: { palette } }) => ({
          '&.Mui-focusVisible': {
            backgroundColor: palette.common.white,
          },
          '&:active': {
            boxShadow: 'none',
          },
        }),
      },
    ],
  },
};

export default chips;
