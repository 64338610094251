import type { OrderCatalogue } from '../orders/types';
import type {
  CatalogueResponse,
  OptionGroupResponse,
  OptionResponse,
  OptionValueResponse,
  ProductTypeResponse,
} from 'foundshared/src/api/catalogue/types';


// Normalised types

// Order catalogue and fresh catalogue
export type FreshCatalogue = Omit<CatalogueResponse, 'productTypes'> & {
  productTypes: string[];
};

export type Catalogue = FreshCatalogue | OrderCatalogue;

export type ProductType = Omit<ProductTypeResponse, 'optionGroups'> & {
  optionGroups: string[];
};

export type OptionGroup = Omit<OptionGroupResponse, 'options'> & {
  options: string[];
};

export type Option = Omit<OptionResponse, 'values'> & {
  values?: string[];
};

export type OptionValue = OptionValueResponse;

export enum CatalogueActionTypes {
  FETCH_EXISTING_PERFORM = '@@catalogues/FETCH_EXISTING_PERFORM',
  FETCH_SUCCESS = '@@catalogues/FETCH_SUCCESS',
  FETCH_ERROR = '@@catalogues/FETCH_ERROR',
}
