export enum SessionActionTypes {
  // REQUEST
  REQUEST_CODE_PERFORM = '@@sessions/REQUEST_CODE_CREATE_PERFORM',
  REQUEST_CODE_SUCCESS = '@@sessions/REQUEST_CODE_CREATE_SUCCESS',
  REQUEST_CODE_ERROR = '@@sessions/REQUEST_CODE_CREATE_ERROR',
  // CREATE SESSION
  CREATE_SESSION_PERFORM = '@@sessions/SESSION_CREATE_PERFORM',
  CREATE_SESSION_SUCCESS = '@@sessions/SESSION_CREATE_SUCCESS',
  CREATE_SESSION_ERROR = '@@sessions/SESSION_CREATE_ERROR',
  // LOGOUT
  DELETE_PERFORM = '@@sessions/DELETE_PERFORM',
  // logout success resets state. check common/store/reducers/index.ts
  DELETE_SUCCESS = '@@sessions/DELETE_SUCCESS',
  DELETE_ERROR = '@@sessions/DELETE_ERROR',

  SIGNUP_OR_SIGNIN_PERFORM = '@@sessions/SIGNUP_OR_SIGNIN_CREATE_PERFORM',
  SIGNUP_PERFORM = '@@sessions/SIGNUP_CREATE_PERFORM',
  SIGNUP_SUCCESS = '@@sessions/SIGNUP_CREATE_SUCCESS',
  SIGNUP_ERROR = '@@sessions/SIGNUP_CREATE_ERROR',

  STORE_PRICING_REGION_ID = '@@sessions/STORE_PRICING_REGION_ID',
}

export enum SessionField {
  TOKEN = 'token',
  VERIFICATION_CODE = 'verificationCode',
  MOBILE_NUMBER = 'mobileNumber',
  MESSAGE = 'message',
  EXPIRE = 'expire',
  ON_HOLD = 'onHold',
}
