import { getTheme } from './theme';

import createCache from '@emotion/cache';
import React from 'react';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';

import type { ReactNode } from 'react';
import type { Theme } from '@mui/material/styles';

interface ThemeProviderProps {
  theme?: Theme;
  children: ReactNode;
}

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const defaultTheme = createTheme(getTheme());

function ThemeProvider({ children, theme = defaultTheme }: ThemeProviderProps) {
  return (
    <CacheProvider value={muiCache}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </CacheProvider>
  );
}

export default ThemeProvider;
