import { ThemeOptions } from '@mui/material/styles';

import buttons from './buttons';
import chips from './chips';
import containers from './containers';
import cssBaseline from './cssBaseline';
import inputs from './inputs';

const overrides: ThemeOptions['components'] = {
  // ...alerts,
  ...buttons,
  ...containers,
  ...cssBaseline,
  // ...dialogs,
  ...inputs,
  // ...stepper,
  // ...tabs,
  // ...tables,
  ...chips,
  MuiLink: {
    defaultProps: {
      underline: 'none',
    },
  },
};

export default overrides;
