import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { AppBarStyleProps } from './TopToolbar';

export const useTitleContainer = makeStyles()(({ spacing }: Theme) => ({
  root: {
    flexGrow: 1,
    '& > *': {
      marginLeft: spacing(2),
    },
  },
}));

export const useContainerStyles = makeStyles<AppBarStyleProps>()(
  ({ breakpoints, spacing, palette }: Theme, { dark }) => ({
    root: {
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: dark ? palette.primary.dark : palette.common.white,
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
      [breakpoints.up('sm')]: {
        paddingLeft: spacing(7),
        paddingRight: spacing(7),
      },
    },
  })
);

export const useAppBarStyles = makeStyles<AppBarStyleProps>()(({ palette }: Theme, { dark }) => ({
  root: {
    backgroundColor: dark ? palette.primary.dark : palette.common.white,
    color: dark ? palette.primary.contrastText : palette.text.primary,
    boxShadow: 'none',
  },
  logoFoundButton: {
    padding: 0,
  },
  logoFound: {
    height: 64,
  },
}));

export const useChipStyles = makeStyles()(({ palette }: Theme) => ({
  root: {
    color: palette.common.black,
    fontWeight: 'bold',
    backgroundColor: palette.accent.main,
    textTransform: 'uppercase',
  },
}));

export const innerToolBarStyles = makeStyles<AppBarStyleProps>()(({ palette }: Theme, { dark }) => ({
  root: {
    minHeight: 72,
    backgroundColor: dark ? palette.primary.dark : palette.common.white,
    zIndex: 1,
  },
}));
