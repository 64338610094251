import { TrackingEvent } from '../utils/tracking/types';
import { addFoundTracking, setPageLoaded } from '../utils/tracking';

import * as sharedInterceptors from 'foundshared/src/api/interceptors';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { ApiHeader } from 'foundshared/src/api/types';
import { v4 as uuidv4 } from 'uuid';

import type { ApiErrorData } from 'foundshared/src/api/types';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

interface ApiTrackingMap {
  ApiId: string;
  RequestTime: number;
  ResponsieTime: number;
  Time: number;
  Url: string;
  Method: string;
  Status: number;
  RequestData?: any;
  ResponseData?: any;
  ErrorType?: string;
}

const ApiMap: ApiTrackingMap[] = [];

const sendApiTracking = (response: any, isOK: boolean) => {
  if (!response?.config) {
    addFoundTracking(
      TrackingEvent.WEB_API_ERROR,
      JSON.stringify({ ErrorType: 'Response without config', ResponseData: response })
    );
  } else if (response.config.url && response.config.baseURL && response.config.method && ApiMap.length > 0) {
    ApiMap.forEach((apiItem, index, object) => {
      if (
        apiItem.ApiId === response.config.url &&
        apiItem.Url === response.config.baseURL &&
        apiItem.Method === response.config.method
      ) {
        const sendApiItem: ApiTrackingMap = apiItem;
        sendApiItem.ResponsieTime = new Date().getTime();
        sendApiItem.Time = apiItem.ResponsieTime - apiItem.RequestTime;
        sendApiItem.Status = response.status;
        sendApiItem.RequestData = (response && response.config && response.config.data) || '';
        addFoundTracking(isOK ? TrackingEvent.WEB_API : TrackingEvent.WEB_API_ERROR, JSON.stringify(sendApiItem));
        object.splice(index, 1);
      }
    });
  }
};

// These interceptors may require further information from store, but should not import store
// because that leads to the following circular dependency
// interceptor <imports> store <imports> saga <imports> api <imports> interceptor
// which leads to undefined behaviour leading to uninitialised import errors.
// Best option is for interceptor to maintain it's own state & have
// store use the updateState function
interface InterceptorState {
  session?: {
    token: string;
  };
}

const context: { state: InterceptorState } = { state: {} };

export function updateState(state: InterceptorState) {
  context.state = state;
}

// Key is added stores/syncKeyToRequests.ts
export function requestInterceptor(config: AxiosRequestConfig) {
  const response = sharedInterceptors.requestInterceptor(config);

  if (!context.state.session) return undefined;

  if (!isEmpty(context.state.session.token) && !isNil(context.state.session.token) && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers[ApiHeader.X_AUTH_TOKEN] = context.state.session.token;
    // eslint-disable-next-line no-param-reassign
    config.headers[ApiHeader.X_TRACE_ID] = uuidv4();
  }

  if (config.url && config.baseURL && config.method) {
    const trackingData: ApiTrackingMap = {
      ApiId: config.url,
      RequestTime: new Date().getTime(),
      ResponsieTime: 0,
      Time: 0,
      Url: config.baseURL,
      Method: config.method,
      Status: 0,
      RequestData: '',
    };
    ApiMap.push(trackingData);
  }

  return response;
}

export function responseInterceptor(config: AxiosResponse<any>) {
  const response = sharedInterceptors.reponseInterceptor(config);

  sendApiTracking(response, true);
  setPageLoaded();

  return response;
}

export function responseErrorInterceptor(errorConfig: AxiosError<ApiErrorData>) {
  const error = sharedInterceptors.reponseErrorInterceptor(errorConfig);

  sendApiTracking(error.response, false);
  return Promise.reject(error);
}

export function requestTokenInterceptorSuccess(config: AxiosRequestConfig, token?: string) {
  if (token) return { ...config, headers: { ...config.headers, [ApiHeader.X_AUTH_TOKEN]: token } };

  return config;
}
