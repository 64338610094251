import React, { memo } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { Theme, useTheme } from '@mui/material/styles';

interface Props extends SvgIconProps {
  alt?: boolean;
  dark?: boolean;
}

function EmailIcon({ dark = false, ...props }: Props) {
  const { palette }: Theme = useTheme();
  const foreground = dark ? palette.accent.main : palette.primary.main;

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fill={foreground}
        d="M15.34 22.9l.38-1.87H10.9c-3.65 0-5.86-2.28-5.86-5.95 0-.55.1-1.2.22-1.87l.21-1.1c.87-4.35 4.13-7.25 8.3-7.25 3.51 0 5.93 2.01 5.93 5.3 0 .62-.02 1.03-.12 1.44l-.16.89c-.53 2.54-1.52 3.77-2.67 3.77-.5 0-.77-.27-.77-.8 0-.21.03-.45.08-.72l1.32-6.55h-2.36l-.3 1.54h-.1c-.27-1.06-1.01-1.75-2.43-1.75-2.06 0-3.81 1.68-4.49 4.82-.14.67-.19 1.18-.19 1.66 0 2.4 1.27 3.64 3.15 3.64 1.44 0 2.54-1.05 2.97-2.28h.1v.3c0 1.24.89 2 2.52 2 2.64 0 4.63-2.49 5.28-5.7.14-.72.19-1.37.19-1.97 0-4.61-3.31-7.44-7.7-7.44C8.47 3 4.37 6.9 3.26 12.44c-.19.98-.26 1.85-.26 2.62 0 4.72 2.83 7.84 7.58 7.84h4.76zm-3.82-6.76c-1.06 0-1.58-.6-1.58-1.61 0-.17 0-.31.04-.55l.39-1.88c.24-1.27 1-2.13 2.23-2.13 1.18 0 1.9.77 1.73 1.63l-.5 2.54c-.2.94-1.13 2-2.31 2z"
      />
    </SvgIcon>
  );
}

export default memo(EmailIcon);
