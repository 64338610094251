/* eslint-disable no-return-assign */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import getYear from 'date-fns/getYear';
import { useEffect, useRef } from 'react';

import * as footerStyles from './Footer.styles';
import AppleStoreLogo from '../../assets/images/icon-apple.png';
import FacebookLogo from '../../assets/images/icon-facebook.png';
import GooglePlayLogo from '../../assets/images/icon-play.png';
import Image from '../Image';
import InstagramLogo from '../../assets/images/icon-instagram.png';
import LogoFound from '../LogoFound';
import tracking from '../../utils/tracking';
import { ExternalDomainLink, ExternalLink } from '../../utils/routes';
import { TrackingLinkEvent } from '../../utils/tracking/types';
import { getBrandContent } from '../../store/features/brandingContent';

const contactUsItems = [
  {
    text: 'Help Centre',
    href: ExternalDomainLink.SUPPORT,
    tracking: TrackingLinkEvent.SUPPORT,
  },
  {
    text: '1300 980 626',
    href: ExternalLink.ONETHREEHUNDRED,
    tracking: TrackingLinkEvent.ONETHREEHUNDRED,
  },
  {
    text: 'hello@foundconcrete.com.au',
    href: ExternalLink.EMAIL,
    tracking: TrackingLinkEvent.EMAIL,
  },
];

const resourcesItems = [
  {
    text: 'Reviews',
    href: ExternalLink.REVIEWS,
    tracking: TrackingLinkEvent.REVIEWS,
  },
  {
    text: 'Refer a tradie',
    href: ExternalLink.REFERRED_CONDITION,
    tracking: TrackingLinkEvent.REFERRED_MATE,
  },
  {
    text: 'Tradie Hub',
    href: ExternalLink.TRADIE_HUB,
    tracking: TrackingLinkEvent.REFERRAL_TCS,
  },
  {
    text: 'Partner Program',
    href: ExternalLink.PARTNER_PROGRAM,
    tracking: TrackingLinkEvent.PARTNER_PROGRAM,
  },
];

const companyItems = [
  {
    text: 'Contact us',
    href: ExternalLink.CONTACT_US,
    tracking: TrackingLinkEvent.VIEW_CONTACT_US,
  },
  {
    text: 'Terms and conditions',
    href: ExternalLink.TERMS,
    tracking: TrackingLinkEvent.VIEW_TERMS,
  },
  {
    text: 'Privacy policy',
    href: ExternalLink.PRIVACY,
    tracking: TrackingLinkEvent.PRIVACY,
  },
  {
    text: 'Service fees',
    href: ExternalLink.SERVICE_FEES,
    tracking: TrackingLinkEvent.SERVICE_FEES,
  },
];

const storeButtons = [
  {
    href: ExternalDomainLink.PLAY_STORE,
    altText: 'Google Play',
    logo: GooglePlayLogo,
    tracking: TrackingLinkEvent.PLAY_STORE,
  },
  {
    href: ExternalDomainLink.APP_STORE,
    altText: 'Apple Store',
    logo: AppleStoreLogo,
    tracking: TrackingLinkEvent.APP_STORE,
  },
  {
    href: ExternalDomainLink.FACEBOOK,
    altText: 'Facebook',
    logo: FacebookLogo,
    tracking: TrackingLinkEvent.FACEBOOK,
  },
  {
    href: ExternalDomainLink.INSTAGRAM,
    altText: 'Instagram',
    logo: InstagramLogo,
    tracking: TrackingLinkEvent.INSTAGRAM,
  },
];

const currentYear = getYear(new Date());
const { companyName } = getBrandContent();

function Footer({ isNoMenu = false }: { isNoMenu?: boolean }) {
  const { classes } = footerStyles.useFooterStyles();
  const contactUsItemsRef = useRef<HTMLAnchorElement[]>([]);
  const resourcesItemsRef = useRef<HTMLAnchorElement[]>([]);
  const companyItemsRef = useRef<HTMLAnchorElement[]>([]);
  const btnItemsRef = useRef<HTMLAnchorElement[]>([]);

  useEffect(() => {
    contactUsItemsRef.current = contactUsItemsRef.current.slice(0, contactUsItems.length);
    contactUsItemsRef.current.forEach((htmlLink, index) => {
      tracking.trackLink(htmlLink, contactUsItems[index].tracking);
    });
  }, [contactUsItemsRef]);

  useEffect(() => {
    resourcesItemsRef.current = resourcesItemsRef.current.slice(0, resourcesItems.length);
    resourcesItemsRef.current.forEach((htmlLink, index) => {
      tracking.trackLink(htmlLink, resourcesItems[index].tracking);
    });
  }, [resourcesItemsRef]);

  useEffect(() => {
    companyItemsRef.current = companyItemsRef.current.slice(0, companyItems.length);
    companyItemsRef.current.forEach((htmlLink, index) => {
      tracking.trackLink(htmlLink, companyItems[index].tracking);
    });
  }, [companyItemsRef]);

  useEffect(() => {
    btnItemsRef.current = btnItemsRef.current.slice(0, storeButtons.length);
    btnItemsRef.current.forEach((htmlLink, index) => {
      tracking.trackLink(htmlLink, storeButtons[index].tracking);
    });
  }, [btnItemsRef]);

  return (
    <footer className={classes.siteFooter}>
      <Container>
        <Grid container className={classes.footerLinkArea}>
          {!isNoMenu && (
            <Grid item xs={12} sm={12} md={5} className={classes.footerLinkSection}>
              <h4 className={classes.footerSectionTitle}>Need help?</h4>
              <ul className={classes.footerLinks}>
                {contactUsItems.map((innerItem, innerIndex) => (
                  <li key={`footer-link-${innerItem.href}`} className={classes.footerLinksItem}>
                    <Link href={innerItem.href} key={innerItem.href}>
                      <a
                        ref={(el) => (el ? (contactUsItemsRef.current[innerIndex] = el) : {})}
                        className={classes.footerLinksLink}
                        href={innerItem.href}
                      >
                        {innerItem.text}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          )}
          {!isNoMenu && (
            <Grid item xs={12} sm={12} md={4} className={classes.footerLinkSection}>
              <h4 className={classes.footerSectionTitle}>Resources</h4>
              <ul className={classes.footerLinks}>
                {resourcesItems.map((innerItem, innerIndex) => (
                  <li key={`footer-link-${innerItem.href}`} className={classes.footerLinksItem}>
                    <Link href={innerItem.href} key={innerItem.href}>
                      <a
                        ref={(el) => (el ? (resourcesItemsRef.current[innerIndex] = el) : {})}
                        className={classes.footerLinksLink}
                        href={innerItem.href}
                      >
                        {innerItem.text}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={3} className={classes.footerLinkSection}>
            <h4 className={classes.footerSectionTitle}>Company</h4>
            <ul className={classes.footerLinks}>
              {companyItems.map((innerItem, innerIndex) => (
                <li key={`footer-link-${innerItem.href}`} className={classes.footerLinksItem}>
                  <Link href={innerItem.href} key={innerItem.href}>
                    <a
                      ref={(el) => (el ? (companyItemsRef.current[innerIndex] = el) : {})}
                      className={classes.footerLinksLink}
                      href={innerItem.href}
                    >
                      {innerItem.text}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={9}>
            <LogoFound dark wrapperClassName={classes.footerLogoWrapper} className={classes.footerLogo} />
            <Typography component="small" className={classes.footerCopy}>
              {`© ${currentYear} ${companyName}`}
            </Typography>
          </Grid>
          {!isNoMenu && (
            <Grid item xs={12} sm={12} md={3}>
              <Box display="flex" className={classes.footerIconArea}>
                {storeButtons.map((item, index) => (
                  <Link href={item.href} key={item.href}>
                    <a
                      ref={(el) => (el ? (btnItemsRef.current[index] = el) : {})}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.footerIconLink}
                    >
                      <Image unoptimized src={item.logo} alt={item.altText} className={classes.footerIcon} />
                    </a>
                  </Link>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
