import { ReferralsActionTypes, ReferralsEntities, ReferrerActionTypes } from './types';

import { createReducer } from 'typesafe-actions';
import { ReferrerStoreField } from 'foundshared/src/api/referrals/constants';

import type { ActionType} from 'typesafe-actions';
import type { ReferralsState} from './types';
import type { fetchReferralsSuccess, fetchReferrerError, fetchReferrerSuccess } from './actions';

export const initialState = { [ReferralsEntities.REFERRER]: null, [ReferralsEntities.REFERRALS]: [] };

type ActionTypes =
  | ActionType<typeof fetchReferralsSuccess>
  | ActionType<typeof fetchReferrerSuccess>
  | ActionType<typeof fetchReferrerError>;

export default createReducer<ReferralsState, ActionTypes>(initialState)
  .handleType(ReferralsActionTypes.FETCH_SUCCESS, (state, { payload }) => ({
    ...state,
    [ReferralsEntities.REFERRALS]: payload,
  }))
  .handleType(ReferrerActionTypes.FETCH_ERROR, (state, { payload }) => ({
    ...state,
    [ReferralsEntities.REFERRER]: { [ReferrerStoreField.SLUG]: payload, [ReferrerStoreField.ACTIVE]: false },
  }))
  .handleType(ReferrerActionTypes.FETCH_SUCCESS, (state, { payload }) => ({
    ...state,
    [ReferralsEntities.REFERRER]: { ...payload, [ReferrerStoreField.ACTIVE]: true },
  }));
