export enum QuoteActionTypes {
  FETCH_PERFORM = '@@quotes/FETCH_PERFORM',
  FETCH_SUCCESS = '@@quotes/FETCH_SUCCESS',
  FETCH_ERROR = '@@quotes/FETCH_ERROR',

  CREATE_PERFORM = '@@quotes/CREATE_PERFORM',
  CREATE_SUCCESS = '@@quotes/CREATE_SUCCESS',
  CREATE_ERROR = '@@quotes/CREATE_ERROR',

  UPDATE_PERFORM = '@@quotes/UPDATE_PERFORM',
  UPDATE_SUCCESS = '@@quotes/UPDATE_SUCCESS',
  UPDATE_ERROR = '@@quotes/UPDATE_ERROR',

  DELETE_PERFORM = '@@quotes/DELETE_PERFORM',
  DELETE_SUCCESS = '@@quotes/DELETE_SUCCESS',
  DELETE_ERROR = '@@quotes/DELETE_ERROR',

  LOAD_PERFORM = '@@quotes/LOAD_PERFORM',
  LOAD_SUCCESS = '@@quotes/LOAD_SUCCESS',
  LOAD_ERROR = '@@quotes/LOAD_ERROR',

  UPDATE_CART = '@@quotes/UPDATE_CART',
  CLEAR_CART = '@@quotes/CLEAR_CART',

  CLEAR = '@@quotes/CLEAR',
}
