
import { CatalogueActionTypes } from './types';

import { CartActionTypes } from '../cart/types';
import { OrderActionTypes } from '../orders/types';
import { QuoteActionTypes } from '../quotes/constants';

import { createReducer } from 'typesafe-actions';

import type EntityType from '../../utils/entityType';
import type { Option, OptionGroup, ProductType } from './types';
import type { createQuoteSuccess, fetchQuotesSuccess } from '../quotes/actions';
import type { fetchCatalogueSoonSuccess, fetchCatalogueSuccess as fetchCartCatalogueSuccess } from '../cart/actions';
import type { fetchCatalogueSuccess } from './actions';
import type { fetchOrdersFirstSuccess, fetchOrdersSuccess, updateOrderSuccess } from '../orders/actions';
import type { OptionValueResponse } from 'foundshared/src/api/catalogue/types';
import type { ActionType, StateType} from 'typesafe-actions';

export type CatalogueState = { id: string | undefined; soon: boolean };

export interface CataloguesState {
  [EntityType.VALUES]: Record<string, OptionValueResponse>;
  [EntityType.OPTIONS]: Record<string, Option>;
  [EntityType.OPTION_GROUPS]: Record<string, OptionGroup>;
  [EntityType.PRODUCT_TYPES]: Record<string, ProductType>;
}

export const initalCataloguesState: CataloguesState = {
  values: {},
  options: {},
  optionGroups: {},
  productTypes: {},
};

type ActionTypes =
  | ActionType<typeof fetchCatalogueSuccess>
  | ActionType<typeof fetchCartCatalogueSuccess>
  | ActionType<typeof fetchCatalogueSoonSuccess>
  | ActionType<typeof fetchOrdersSuccess>
  | ActionType<typeof fetchOrdersFirstSuccess>
  | ActionType<typeof fetchQuotesSuccess>
  | ActionType<typeof createQuoteSuccess>
  | ActionType<typeof updateOrderSuccess>;

const mergeCatalogues = (state: CataloguesState, action: ActionTypes) => {
  // Check for fetchCatalogueSoonSuccess
  if ('payload' in action && action.payload) {
    return {
      values: { ...state.values, ...action.payload.entities.values },
      options: { ...state.options, ...action.payload.entities.options },
      optionGroups: { ...state.optionGroups, ...action.payload.entities.optionGroups },
      productTypes: { ...state.productTypes, ...action.payload.entities.productTypes },
    };
  }
  return state;
};

/** All catalogue (fresh and existing orders) products  */
const profileReducer = createReducer<CataloguesState, ActionTypes>(initalCataloguesState)
  .handleType(CatalogueActionTypes.FETCH_SUCCESS, mergeCatalogues)
  .handleType(CartActionTypes.FETCH_CATALOGUE_SUCCESS, mergeCatalogues)
  .handleType(OrderActionTypes.FETCH_SUCCESS, mergeCatalogues)
  .handleType(OrderActionTypes.FETCH_SUCCESS_FIRST, mergeCatalogues)
  .handleType(OrderActionTypes.UPDATE_SUCCESS, mergeCatalogues)
  .handleType(QuoteActionTypes.FETCH_SUCCESS, mergeCatalogues)
  .handleType(QuoteActionTypes.CREATE_SUCCESS, mergeCatalogues);

export type ProfileState = StateType<typeof profileReducer>;

export default profileReducer;
