import React, { memo } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function PhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 23">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9632 2.67336C13.7773 2.64671 13.5906 2.70167 13.442 2.81992C13.2893 2.93984 13.1939 3.11305 13.1731 3.30709C13.1292 3.69932 13.4122 4.05407 13.8039 4.09821C16.5053 4.39967 18.605 6.50406 18.9087 9.21386C18.9494 9.57695 19.254 9.85093 19.6175 9.85093C19.6448 9.85093 19.6714 9.84926 19.6988 9.84593C19.8888 9.82511 20.0581 9.73101 20.1777 9.58111C20.2963 9.43121 20.3503 9.24468 20.3287 9.05397C19.9503 5.67296 17.3335 3.04893 13.9632 2.67336ZM14.0151 5.57512C13.6184 5.50184 13.254 5.755 13.1785 6.14223C13.103 6.52947 13.3569 6.90754 13.7428 6.98332C14.9047 7.20983 15.8019 8.10922 16.0293 9.27508V9.27591C16.094 9.61152 16.3895 9.85552 16.7297 9.85552C16.7754 9.85552 16.821 9.85135 16.8675 9.84302C17.2534 9.76558 17.5073 9.38834 17.4318 9.00027C17.0924 7.25897 15.7521 5.91406 14.0151 5.57512ZM15.3335 13.5755C15.7097 13.3586 16.1346 13.1136 16.6706 13.2276C17.1561 13.33 18.811 14.6733 19.2641 15.1388C19.5613 15.4436 19.7264 15.7584 19.7521 16.0731C19.7969 17.3081 18.1189 18.718 17.8134 18.8937C17.4076 19.186 16.9354 19.3334 16.4051 19.3334C15.8631 19.3334 15.259 19.1793 14.6 18.8721C11.0247 17.3797 5.56302 12.0259 4.11897 8.47836C3.51976 7.1576 3.51561 6.06834 4.10901 5.24974C4.34553 4.86584 5.69415 3.2611 6.90168 3.3119C7.22286 3.33938 7.53491 3.50427 7.84115 3.80407C8.30424 4.25792 9.61385 5.91761 9.7151 6.40478C9.82714 6.94607 9.58149 7.37661 9.36322 7.75469C9.31666 7.83596 9.25394 7.93241 9.18522 8.03807C8.92441 8.4391 8.57728 8.97286 8.70011 9.31362C9.58066 11.4755 11.6148 13.3617 13.7776 14.2477C14.112 14.3693 14.6455 14.0197 15.0452 13.7577C15.1494 13.6895 15.2444 13.6272 15.3245 13.5807L15.3335 13.5755Z"
        fill="#74707A"
      />
    </SvgIcon>
  );
}

export default memo(PhoneIcon);
