import { action } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { NotficationActionConfig, NotificationActionTypes, NotificationKind, NotificationType } from './types';

export const addSuccess = (message: string, config?: NotficationActionConfig) =>
  action(NotificationActionTypes.ADD, {
    id: uuidv4(),
    type: NotificationType.SUCCESS,
    kind: NotificationKind.SNACKBAR,
    ...config,
    message,
  });

export const addError = (message: string, config?: NotficationActionConfig) =>
  action(NotificationActionTypes.ADD, {
    id: uuidv4(),
    type: NotificationType.ERROR,
    kind: NotificationKind.SNACKBAR,
    ...config,
    message,
  });

export const remove = (id: string) => action(NotificationActionTypes.REMOVE, id);
