import { ActionType } from 'typesafe-actions';

import { OrderFilter, OrderMode } from '../../components/Orders/utils';
import { OrderStep } from '../../components/OrderBuilder/utils/formState';
import { initiateQuoteToOrder, resetApp, updateOrderSteps, updateQuoteSteps, updateValues } from './actions';

export type AppContextActionTypes =
  | ActionType<typeof updateValues>
  | ActionType<typeof updateOrderSteps>
  | ActionType<typeof updateQuoteSteps>
  | ActionType<typeof initiateQuoteToOrder>
  | ActionType<typeof resetApp>;

export enum AppContextField {
  FILTER = 'FILTER',
  /** Should the order builder use a new catalogue or an existing one within an order. */
  FRESH_CATALOGUE = 'FRESH_CATALOGUE',
  IS_HUB = 'IS_HUB',
  ORDER_ID = 'ORDER_ID',
  ORDER_MODE = 'ORDER_MODE',
  /** The step index of the order creation proceedure. */
  ORDER_STEP = 'ORDER_STEP',
  QUOTE_ID = 'QUOTE_ID',
}

export interface AppContextStateBase {
  [AppContextField.FILTER]: OrderFilter;
  [AppContextField.IS_HUB]: boolean;
  [AppContextField.ORDER_MODE]: OrderMode;
  [AppContextField.FRESH_CATALOGUE]?: boolean;
}

export interface AppQuoteContextState extends AppContextStateBase {
  [AppContextField.QUOTE_ID]?: string;
}

export interface AppOrderContextState extends AppContextStateBase {
  [AppContextField.ORDER_ID]?: string;
  [AppContextField.ORDER_STEP]: OrderStep;
  /**  Used when progressing though the steps of converting a quote to an order or wiping it */
  [AppContextField.QUOTE_ID]?: string;
}

export interface AppQuoteToOrderContextState extends AppContextStateBase {
  [AppContextField.ORDER_MODE]: OrderMode.QUOTE_TO_ORDER;
  /**  Converting quotes will use a fresh catalogue */
  [AppContextField.FRESH_CATALOGUE]: true;
  [AppContextField.ORDER_STEP]: OrderStep;
  [AppContextField.QUOTE_ID]?: string;
}

export type AppContextState = AppQuoteContextState & AppOrderContextState;

export type AppContextDispatch = (action: AppContextActionTypes) => void;
