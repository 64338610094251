import { SettingsActionType } from './types';

import { createReducer } from 'typesafe-actions';

import type { ActionType} from 'typesafe-actions';
import type { Settings } from 'foundshared/src/api/settings/types';
import type { fetchSettingsSuccess } from './actions';

export const initialSettingssState: Settings = {};

type ActionTypes = ActionType<typeof fetchSettingsSuccess>;

const ordersReducer = createReducer<Settings, ActionTypes>(initialSettingssState).handleType(
  SettingsActionType.FETCH_SUCCESS,
  (state, { payload }) => ({ ...state, ...payload.entities.settings })
);

export default ordersReducer;
