import { makeStyles } from 'tss-react/mui';

export const useListStyles = makeStyles()({
  root: {
    width: 260,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});
