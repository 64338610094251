import * as schemas from './schema';
import { SettingsActionType } from './types';
import { fetchSettingsError, fetchSettingsSuccess } from './actions';

import handleError from '../handleError';
import { apiActions } from '../../api';

import { normalize } from 'normalizr';
import { call, put, spawn, takeLatest } from 'typed-redux-saga';

import type { fetchSettingsPerform } from './actions';
import type { Right } from 'fp-ts/lib/Either';
import type { SettingsResponse } from 'foundshared/src/api/settings/types';
import type { PricingEntities } from 'foundshared/src/api/pricing/types';
import type { Profile } from 'foundshared/src/api/profile/types';

const normaliseEntitysResponse = (result: Right<SettingsResponse>) =>
  normalize<SettingsResponse, PricingEntities, Profile>(result.right, schemas.settingsCollectionSchema);

export function* settingsInstanceSaga(action: ReturnType<typeof fetchSettingsPerform>) {
  try {
    const result = yield* call(apiActions.settings.fetchSettings);
    const normalisedResult = normaliseEntitysResponse(result);

    yield* put(fetchSettingsSuccess(normalisedResult, action.meta));

    if (action.meta?.callback) action.meta.callback();
  } catch (error: any) {
    yield* handleError(error, put, function* () {
      yield* put(fetchSettingsError(error.message));
    });
  }
}

// / /////////////////////////////////  END //////////////////////////////////////

export function* fetchQuotesSaga() {
  yield* takeLatest(SettingsActionType.FETCH_PERFORM, settingsInstanceSaga);
}

const spawnedAppSagas = [spawn(fetchQuotesSaga)];

export default spawnedAppSagas;
