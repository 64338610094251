import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';

import * as topToolbarStyles from './TopToolbar.styles';
import DevEnvSwitcher from '../DevEnvSwitcher';
import LogoFound from '../LogoFound';
import MenuList from './components/MenuList';
import NavList from './components/NavList';
import environment from '../../utils/environment';
import trackRegistrationRefer from '../../utils/tracking/refererTracking';
import { LinkProps, authLinks, unauthLinks } from './utils/navItems';
import { PublicRoutePath } from '../../utils/routes';
import { TrackingEventSource } from '../../utils/tracking/types';

export interface AppBarStyleProps {
  dark?: boolean;
}

export interface TopToolbarProps {
  dark?: boolean;
  isAuthenticated: boolean;
  onLogout: () => void;
  isNoMenu?: boolean;
}

export const handleTradeDiscountClick = () => {
  trackRegistrationRefer(TrackingEventSource.HOME_PAGE);
};

export const tradeDiscountRoute = {
  pathname: PublicRoutePath.TRADE_DISCOUNT,
  state: {
    source: TrackingEventSource.MENU_CTA,
  },
};

function TopToolbar({ dark = true, isAuthenticated, onLogout, isNoMenu = false, ...rest }: TopToolbarProps) {
  const { classes: appContainerClasses } = topToolbarStyles.useContainerStyles({ dark });
  const { classes: appBarClasses } = topToolbarStyles.useAppBarStyles({ dark });
  const { classes: titleContainerClasses } = topToolbarStyles.useTitleContainer();
  const { classes: chipClasses } = topToolbarStyles.useChipStyles();
  const { classes: innerToolbarClasses } = topToolbarStyles.innerToolBarStyles({ dark });

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const authLinksWithLogout: Array<LinkProps> = authLinks.concat([
    {
      id: 'nav-logout',
      onClick: onLogout,
      exact: true,
      primary: 'Sign out',
      public: true,
    },
  ]);

  return (
    <>
      <AppBar classes={{ root: appBarClasses.root }} {...rest}>
        <Toolbar disableGutters classes={{ root: innerToolbarClasses.root }}>
          <Container classes={appContainerClasses}>
            <Link href={PublicRoutePath.HOME}>
              <a className={appBarClasses.logoFoundButton}>
                <LogoFound dark={dark} />
              </a>
            </Link>

            {/* Dev env switcher should be changed to work via a url parm */}
            <Box display="flex" alignItems="center" className={titleContainerClasses.root}>
              {!environment.isProduction && <DevEnvSwitcher chipClasses={chipClasses.root} />}
            </Box>

            {!isNoMenu && (
              <Hidden mdUp implementation="css">
                <IconButton
                  id="menu-button"
                  edge="end"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            )}
            <Hidden mdDown implementation="css">
              <NavList
                isAuthenticated={isAuthenticated}
                unauthLinks={unauthLinks}
                authLinks={authLinksWithLogout}
                isDark={dark}
                isHideMenu={isNoMenu}
              />
            </Hidden>
          </Container>
        </Toolbar>
      </AppBar>
      {!isNoMenu && (
        <Hidden smUp implementation="css">
          <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
            <MenuList
              isAuthenticated={isAuthenticated}
              onToggle={handleDrawerToggle}
              unauthLinks={unauthLinks}
              authLinks={authLinksWithLogout}
            />
          </Drawer>
        </Hidden>
      )}
    </>
  );
}

export default TopToolbar;
