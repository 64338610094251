import environment from '../utils/environment';

import { isEmpty, isNil, tap } from 'ramda';

import type { AxiosInstance } from 'axios';


const LOCAL_STORE_KEY = 'axios:switchBaseUrl:currentOrigin';
const isBrowser = () => typeof window !== 'undefined' && !isNil(window.origin) && window.origin !== 'null';

export const defaultApiOrigin = environment.API_ORIGIN;

function setLocalStorage(key: string, value: any): void {
  if (isBrowser()) {
    window.localStorage.setItem(key, value);
  }
}

function getLocalStorage(key: string): any {
  let returnValue = null;
  if (isBrowser()) {
    returnValue = window.localStorage.getItem(key);
  }
  return returnValue;
}

export function setApiOrigin(origin?: string) {
  if (isEmpty(origin) || isNil(origin)) {
    setLocalStorage(LOCAL_STORE_KEY, '');
  } else {
    setLocalStorage(LOCAL_STORE_KEY, origin);
  }
}

export function getApiOrigin(): string {
  const apiOrigin = getLocalStorage(LOCAL_STORE_KEY);

  if (isEmpty(apiOrigin) || isNil(apiOrigin)) {
    return defaultApiOrigin;
  }
  return apiOrigin as string;
}

// set baseUrl to user chosen api origin
function switchBaseUrl(axiosInstance: AxiosInstance) {
  const apiOrigin = getLocalStorage(LOCAL_STORE_KEY);

  if (isEmpty(apiOrigin) || isNil(apiOrigin)) {
    setApiOrigin(environment.API_ORIGIN);
  }
  axiosInstance.interceptors.request.use(
    tap((config) => {
      const currentOrigin = getApiOrigin();
      // eslint-disable-next-line no-param-reassign
      config.baseURL = currentOrigin;
    })
  );
}

export default switchBaseUrl;
