import { SessionActionTypes } from './types';

import { createReducer } from 'typesafe-actions';

import type { ActionType} from 'typesafe-actions';
import type { Session } from 'foundshared/src/api/session/types';
import type { createSessionSuccess, deleteSuccess, requestCodeSuccess, signupSuccess, storePricingRegion } from './actions';

export type SessionState = Session & { authenticated: boolean; pricingRegionId: string | null };

const blankState: SessionState = {
  authenticated: false,
  token: '',

  /** Temporary, should be stored via the me API */
  pricingRegionId: null,
};

type ActionTypes =
  | ActionType<typeof deleteSuccess>
  | ActionType<typeof requestCodeSuccess>
  | ActionType<typeof createSessionSuccess>
  | ActionType<typeof signupSuccess>
  | ActionType<typeof storePricingRegion>;

const requestCodeHandler = (state: SessionState, action: ActionType<typeof requestCodeSuccess>) => ({
  ...state,
  ...action.payload,
});

const createSessionHandler = (state: SessionState, action: ActionType<typeof createSessionSuccess>) => ({
  ...state,
  ...action.payload,
  authenticated: true,
});

const signupHandler = (state: SessionState, action: ActionType<typeof signupSuccess>) => ({
  ...state,
  ...action.payload,
  authenticated: true,
});

const pricingRegionHandler = (state: SessionState, action: ActionType<typeof storePricingRegion>) => ({
  ...state,
  pricingRegionId: action.payload,
});

// Reducer
const sessionReducer = createReducer<SessionState, ActionTypes>(blankState)
  .handleType(SessionActionTypes.CREATE_SESSION_SUCCESS, createSessionHandler)
  .handleType(SessionActionTypes.REQUEST_CODE_SUCCESS, requestCodeHandler)
  .handleType(SessionActionTypes.SIGNUP_SUCCESS, signupHandler)
  .handleType(SessionActionTypes.STORE_PRICING_REGION_ID, pricingRegionHandler);

export default sessionReducer;
