import { AvailabilityActionTypes, ServiceAreaActionTypes } from './constants';

import { action } from 'typesafe-actions';

import type { AvailabilitiesResponse } from 'foundshared/src/api/availability/codecs';
import type { SuggestionsServiceAreasResponse } from 'foundshared/src/api/serviceAreas/codecs';

export const fetchPerform = (term: string) => action(ServiceAreaActionTypes.FETCH_PERFORM, term);

export const fetchSuccess = (data: SuggestionsServiceAreasResponse) =>
  action(ServiceAreaActionTypes.FETCH_SUCCESS, data);

export const fetchError = (error: Error) => action(ServiceAreaActionTypes.FETCH_ERROR, error);

export const clearSuggestions = () => action(ServiceAreaActionTypes.CLEAR);

export const fetchAvailabilityPerform = (
  locality: string,
  postcode: string,
  productTypeId?: string,
  meta?: { callback: () => void }
) => action(AvailabilityActionTypes.FETCH_PERFORM, { postcode, locality, productTypeId }, meta);

export const fetchAvailabilityBFFPerform = (
  locality: string,
  postcode: string,
  productTypeId?: string,
  meta?: { callback: () => void }
) => action(AvailabilityActionTypes.FETCH_PERFORM_BFF, { postcode, locality, productTypeId }, meta);

export const fetchAvailabilitySuccess = (data: AvailabilitiesResponse) =>
  action(AvailabilityActionTypes.FETCH_SUCCESS, data);

export const fetchAvailabilitySuccessBFF = (data: boolean) => action(AvailabilityActionTypes.FETCH_SUCCESS_BFF, data);

export const fetchAvailabilityError = (error: Error) => action(AvailabilityActionTypes.FETCH_ERROR, error);

export const clear = () => action(AvailabilityActionTypes.CLEAR);
