import { InvoicesActionTypes, SupplierActionTypes } from './types';

import { action } from 'typesafe-actions';

import type { EntityState} from './types';
import type { MarkInvoicesType } from 'foundshared/src/api/invoices/types';



/**
 * Mark a set of invoices as paid or unpaid
 * @param paid To mark following set of invoices as paid or unpaid
 * @param invoiceIds Collection of invoice ids to mark.
 */
export const updatePerform = (paid: boolean, ids: MarkInvoicesType, meta?: any) =>
  action(InvoicesActionTypes.UPDATE_PERFORM, { paid, ids }, meta);

export const updateSuccess = (payload: any, meta?: any) => action(InvoicesActionTypes.UPDATE_SUCCESS, payload, meta);

export const updateError = (error: Error) => action(InvoicesActionTypes.UPDATE_ERROR, undefined, undefined, error);

export const fetchPerform = (meta?: any) => action(SupplierActionTypes.FETCH_PERFORM, undefined, meta);

export const fetchSuccess = (data: EntityState['suppliers'], meta?: any) =>
  action(SupplierActionTypes.FETCH_SUCCESS, data, meta);

export const fetchError = (error: Error) => action(SupplierActionTypes.FETCH_ERROR, error, { error: true });
