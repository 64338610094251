import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { ColorPalette } from '../../design/palette/colorPalette';

export const useFooterStyles = makeStyles()(({ palette, spacing }: Theme) => ({
  siteFooter: {
    backgroundColor: palette.primary.main,
    padding: '36px 0px 96px 0px',
    color: palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerLinkArea: {
    marginBottom: '24px',
  },
  footerLinkSection: {
    marginBottom: '24px',
  },
  footerLinks: {
    padding: 0,
  },
  footerLinksItem: {
    display: 'flex',
    marginRight: spacing(1),
    marginBottom: '10px',
  },
  footerLinksLink: {
    color: palette.common.white,
    textDecoration: 'none',
    fontSize: 20,
    lineHeight: '26px',
    fontWeight: 700,
    fontFamily: "'Articulat CF', sans-serif",
  },
  footerCopy: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
    color: ColorPalette.MERCURY,
    fontFamily: "'Articulat CF', sans-serif",
    marginTop: '4px',
  },
  footerSectionTitle: {
    fontSize: 24,
    lineHeight: '0px',
    fontWeight: 700,
    color: ColorPalette.GREY_NICKEL,
    fontFamily: "'Articulat CF', sans-serif",
  },
  footerLogoWrapper: {
    height: '64px',
  },
  footerLogo: {
    height: '36px',
  },
  footerIconArea: {
    marginTop: '20px',
  },
  footerIconLink: {
    marginRight: '24px',
  },
  footerIcon: {
    height: '28px',
  },
}));
