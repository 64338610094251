import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()(({ breakpoints, spacing, palette, shadows }: Theme) => ({
  chip: {
    fontWeight: 'bold',
    margin: spacing('auto', 4.75),
    [breakpoints.up('lg')]: {
      transform: 'none',
      bottom: 0,
      margin: spacing('auto', 1, 'auto', 2),
    },
    [breakpoints.down('lg')]: {
      margin: spacing(0, 0, 2, 0),
    },
    [breakpoints.between('sm', 'lg')]: {
      position: 'absolute',
      bottom: 0,
      left: spacing(7),
      transform: 'rotate(-35deg)',
    },
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: palette.background.paper,
    border: `2px solid ${palette.common.black}`,
    boxShadow: shadows[5],
    padding: spacing(2, 4, 3),
  },
  modalWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    backgroundColor: palette.background.paper,
    maxWidth: '32rem',
    width: '100%',
  },
  justifyRight: {
    justifyContent: 'flex-end',
  },
  dialogContentText: {
    marginBottom: spacing(2),
  },
}));
