import { fetchSuggestionsSuccess } from './actions';

import { createReducer } from 'typesafe-actions';

import type { ServiceAreas } from 'foundshared/src/api/serviceAreas/codecs';
import type { ActionType } from 'typesafe-actions';

type ActionTypes = ActionType<typeof fetchSuggestionsSuccess>;

const serviceAreaReducer = createReducer<ServiceAreas, ActionTypes>([]).handleType(
  fetchSuggestionsSuccess.actionType,
  (_state, { payload }) => payload // old state needs to be clobbered?
);

export default serviceAreaReducer;
