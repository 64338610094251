import { CartActionTypes } from './types';
import { fetchCatalogueError, fetchCatalogueSoonSuccess, fetchCatalogueSuccess } from './actions';

import handleError, { isAxiosError } from '../handleError';
import { catalogueEntitySchema } from '../catalogues/schema';
import { apiActions } from '../../api';

import { call, put, spawn, takeLatest } from 'typed-redux-saga';
import { normalize } from 'normalizr';

import type { CataloguesState } from '../catalogues/reducer';
import type { FreshCatalogue } from '../catalogues/types';
import type { fetchCataloguePerform } from './actions';

export function* catalogueInstanceSaga({ payload, meta }: ReturnType<typeof fetchCataloguePerform>) {
  try {
    const { postcode, locality } = payload;

    const result = yield* call(apiActions.catalogue.fetchCatalogue, locality, postcode);

    const normalisedResult = normalize<FreshCatalogue, CataloguesState>(result.right, catalogueEntitySchema);

    yield* put(fetchCatalogueSuccess(normalisedResult, meta));
  } catch (error) {
    // TODO: Temporary see FOUND-119
    if (isAxiosError(error) && error.response?.data?.errorCode === 'no_base_price') {
      yield* put(fetchCatalogueSoonSuccess());
    } else {
      yield* handleError(error, put, function* () {
        if (error instanceof Error) yield* put(fetchCatalogueError(error));
      });
    }
  }
}

export function* fetchCatalogueSaga() {
  yield* takeLatest(CartActionTypes.FETCH_CATALOGUE_PERFORM, catalogueInstanceSaga);
}

const spawnedAppSagas = [spawn(fetchCatalogueSaga)];

export default spawnedAppSagas;
