import { makeStyles } from 'tss-react/mui';

export const useWrapper = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
