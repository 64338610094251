import * as DevEnvSwitcherStyles from './DevEnvSwitcher.styles';

import ProceedButton from '../ProceedButton';
import environment from '../../utils/environment';
import { PublicRoutePath } from '../../utils/routes';
import { authenticated$ } from '../../store/session/selectors';
import { defaultApiOrigin, getApiOrigin, setApiOrigin } from '../../api/switchBaseUrl';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MuiBackdrop from '@mui/material/Backdrop';
import { useState } from 'react';
import { invertObj, keys } from 'ramda';
import { useSelector } from 'react-redux';

import type { FormEvent} from 'react';
import type { BackdropProps as MuiBackdropProps } from '@mui/material/Backdrop';


const alternativeOrigins = {
  default: defaultApiOrigin,
  dev: 'https://api.dev.foundconcrete.com.au',
  stg: 'https://api.stg.foundconcrete.com.au',
  feature01: 'https://api.feature01.dev.foundconcrete.com.au',
  feature02: 'https://api.feature02.dev.foundconcrete.com.au',
  feature03: 'https://api.feature03.dev.foundconcrete.com.au',
  feature04: 'https://api.feature04.dev.foundconcrete.com.au',
  feature05: 'https://api.feature05.dev.foundconcrete.com.au',
  feature06: 'https://api.feature06.dev.foundconcrete.com.au',
  feature07: 'https://api.feature07.dev.foundconcrete.com.au',
  feature08: 'https://api.feature08.dev.foundconcrete.com.au',
  feature09: 'https://api.feature09.dev.foundconcrete.com.au',
  feature10: 'https://api.feature10.dev.foundconcrete.com.au',
  feature11: 'https://api.feature11.dev.foundconcrete.com.au',
  feature12: 'https://api.feature12.dev.foundconcrete.com.au',
  feature13: 'https://api.feature13.dev.foundconcrete.com.au',
  feature14: 'https://api.feature14.dev.foundconcrete.com.au',
  feature15: 'https://api.feature15.dev.foundconcrete.com.au',
};

type AlternativeOriginName = keyof typeof alternativeOrigins;

// hacky because 'invertObj' doesn't have a juicy type spec
const alternativeOriginsByUrl = invertObj(alternativeOrigins) as unknown as Record<string, AlternativeOriginName>;

const availableEnvs = keys(alternativeOrigins);

const shortEnvNames = {
  production: 'prd',
  staging: 'stg',
  development: 'dev',
  local: 'loc',
};

interface DevEnvSwitcherProps {
  chipClasses?: string;
}

function Backdrop(props: MuiBackdropProps) {
  return <MuiBackdrop {...props} data-testid="env-backdrop" />;
}

function DevEnvSwitcher({ chipClasses }: DevEnvSwitcherProps) {
  const { classes } = DevEnvSwitcherStyles.styles();

  const authenticated = useSelector(authenticated$);
  const [dialogOpen, setDialogOpen] = useState(false);

  const currentApiOrigin = getApiOrigin();
  const currentApiOriginName = alternativeOriginsByUrl[currentApiOrigin];

  // controlled input
  const [apiOriginNameInput, setApiOriginNameInput] = useState(currentApiOriginName);

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const disabledProceed = !apiOriginNameInput || apiOriginNameInput === currentApiOriginName;

  const currentEnvText = `The current environment you have set for this testing site (${environment.ENVIRONMENT}) is ${currentApiOriginName}.`;

  const subText = `Setting a different environment will reset your browser's data associated with this testing site${
    authenticated ? ' and thus sign you out' : ''
  }.`;

  const chipText = `${shortEnvNames[environment.ENVIRONMENT]}/${currentApiOriginName}`;

  const apiUrlText = alternativeOrigins[apiOriginNameInput];

  const proceedMsg = `Change environment${authenticated ? ' and sign out' : ''}`;

  const proceed = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setApiOrigin(alternativeOrigins[apiOriginNameInput]);
    setTimeout(() => window.location.assign(PublicRoutePath.HOME), 100);
  };

  return (
    <>
      <Chip
        component="button"
        onClick={handleOpen}
        classes={{ root: chipClasses }}
        label={chipText.toUpperCase()}
        size="small"
        data-testid="chip-env-button"
        color="warning"
      />

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="dev-env-dialog-title"
        aria-describedby="dev-env-dialog-description"
        data-testid="dev-env-picker"
        BackdropComponent={Backdrop}
      >
        <form onSubmit={proceed}>
          <DialogTitle id="dev-env-dialog-title">Change backend environment</DialogTitle>
          <DialogContent>
            <DialogContentText classes={{ root: classes.dialogContentText }} id="dev-env-dialog-description">
              Picking a different environment will mean data is sourced (via the API) from a different portal.
            </DialogContentText>
            <DialogContentText classes={{ root: classes.dialogContentText }} id="dev-env-dialog-description">
              {subText}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.dialogContentText }} id="dev-env-dialog-description">
              {currentEnvText}
            </DialogContentText>

            <Autocomplete
              fullWidth
              freeSolo
              options={availableEnvs}
              value={apiOriginNameInput}
              onInputChange={(_event, text) =>
                setApiOriginNameInput(text.toLowerCase().replace(' ', '') as AlternativeOriginName)
              }
              id="dev-env-switcher"
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, autoCapitalize: 'none', 'aria-label': 'backend api affix id' }}
                  label="Choose or type an environment"
                  helperText={apiUrlText}
                />
              )}
            />
            <br />
          </DialogContent>
          <DialogActions>
            <ProceedButton type="submit" disabled={disabledProceed} onClick={() => proceed()} autoFocus size="small">
              {proceedMsg}
            </ProceedButton>
            <Button size="small" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default DevEnvSwitcher;
