import { extendedTypography } from '../typography';

import { alpha } from '@mui/material/styles';

import type { ThemeOptions } from '@mui/material/styles';

const inputs: ThemeOptions['components'] = {
  MuiSelect: {
    defaultProps: {
      variant: 'standard',
      disableUnderline: true,
    },
  },
  MuiInput: {
    defaultProps: {
      disableUnderline: true,
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'filled',
      InputProps: {
        disableUnderline: true,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-focused': {
          color: theme.palette.secondary.main,
        },
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: '1.125rem',
        fontWeight: 400,
        color: theme.palette.secondary.light,
      }),
      filled: ({ theme }) => ({
        transform: `translate(${theme.spacing(2)}, 23px) scale(1)`,

        color: theme.palette.secondary.main,
        '& ~ .MuiInputBase-root .MuiInputBase-input': {
          padding: `28px calc(${theme.spacing(2)} - 2px) 10px`,
        },
        '&.MuiInputLabel-shrink': {
          transform: `translate(${theme.spacing(2)}, 12px) scale(0.75)`,
        },
      }),
      focused: {},
      error: ({ theme }) => ({
        color: theme.palette.error.main,
      }),
      disabled: ({ theme }) => ({
        color: `${alpha(theme.palette.secondary.main, 0.5)}`,
      }),
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
        '&.Mui-checked': {
          color: theme.palette.accent.main,
        },
      }),
      colorSecondary: ({ theme }) => ({
        '&.Mui-checked': {
          color: theme.palette.accent.main,
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
        '&.Mui-checked': {
          color: theme.palette.accent.main,
        },
      }),
      colorSecondary: ({ theme }) => ({
        '&.Mui-checked': {
          color: theme.palette.accent.main,
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.common.white,
        ...extendedTypography.input,
      }),
      input: {
        // padding: `28px calc(${spacing(2)} - 2px) 10px`,
        // this overrides autocomplete input
        '&::placeholder': {
          ...extendedTypography.inputPlaceholder,
        },
      },
      disabled: ({ theme }) => ({
        color: `${alpha(theme.palette.text.primary, 0.5)}`,
      }),
    },
  },
  MuiInputAdornment: {
    defaultProps: {
      disableTypography: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
        flexShrink: 0,
        height: 'auto',
        alignSelf: 'stretch',
        maxHeight: 'none',
        zIndex: 1,
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        ...extendedTypography.input,
      }),
      filled: ({ theme }) => ({
        '&[class*="MuiInputAdornment-positionStart"]:not([class*="MuiInputAdornment-hiddenLabel"])': {
          marginTop: 0,
        },
        boxShadow: 'none',
        backgroundColor: theme.palette.background.default,
        borderStyle: 'solid',
        margin: -2,
        borderColor: theme.palette.secondary.lightest,
        transition: theme.transitions.create('border-color', {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.shortest,
        }),
      }),
      positionEnd: ({ theme }) => ({
        borderWidth: '2px 2px 2px 0',
        borderTopRightRadius: `${theme.shape.borderRadius}px`,
        borderBottomRightRadius: `${theme.shape.borderRadius}px`,
      }),
      positionStart: ({ theme }) => ({
        borderWidth: '2px 0 2px 2px',
        borderTopLeftRadius: `${theme.shape.borderRadius}px`,
        borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
      }),
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        border: `2px solid`,
        borderColor: 'transparent',
        transition: theme.transitions.create('border-color', {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.shortest,
        }),

        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
        '&.Mui-focused, .Mui-focused:hover': {
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.proceed.main,
          '&:not([class*="Mui-error"]) [class*="MuiInputAdornment-root"]': {
            borderColor: theme.palette.proceed.main,
          },
        },
        '&.Mui-error, .Mui-error:hover': {
          borderColor: theme.palette.error.main,
          '& [class*="MuiInputAdornment-root"]': {
            borderColor: theme.palette.error.main,
          },
        },
        '&::before': {
          display: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: alpha(theme.palette.common.white, 0.5),
        },
      }),
      input: ({ theme }) => ({
        padding: `19px calc(${theme.spacing(2)} - 2px)`,
      }),
      inputSizeSmall: ({ theme }) => ({
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      }),
      multiline: {
        padding: 0,
      },
      focused: {},
      adornedEnd: {
        paddingRight: 0,
      },
      adornedStart: {
        paddingLeft: 0,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body2,
        color: theme.palette.primary.lighter,
        margin: theme.spacing(0.5),
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: Number(theme.shape.borderRadius) / 2,
        borderTopLeftRadius: `${Number(theme.shape.borderRadius) / 2}px`,
        borderTopRightRadius: `${Number(theme.shape.borderRadius) / 2}px`,
      }),
      input: {
        '&::placeholder': {
          ...extendedTypography.inputPlaceholder,
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: ({ theme }) => ({
        '&[class*="MuiFilledInput-root"]': {
          paddingTop: 0,
          paddingLeft: 0,

          '& .MuiAutocomplete-input': {
            padding: `28px calc(${theme.spacing(2)} - 2px) 10px`,
          },
        },
      }),
    },
  },
};

export default inputs;
