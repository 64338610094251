import { PricingActionType } from './types';

import { ProfileActionTypes } from '../profile/types';

import merge from 'lodash/merge';
import { createReducer } from 'typesafe-actions';
import { PricingEntity } from 'foundshared/src/api/pricing/types';

import type { ActionType} from 'typesafe-actions';
import type { PricingEntities} from 'foundshared/src/api/pricing/types';
import type { fetchPricingError, fetchPricingOptionsSuccess, fetchPricingSuccess } from './actions';
import type { fetchProfileSuccess, updateSuccess } from '../profile/actions';

type ActionTypes =
  | ActionType<typeof fetchPricingSuccess>
  | ActionType<typeof fetchPricingOptionsSuccess>
  | ActionType<typeof fetchPricingError>
  | ActionType<typeof fetchProfileSuccess>
  | ActionType<typeof updateSuccess>;

const initialPricingState = {
  [PricingEntity.TIERS]: {},
  [PricingEntity.REGIONS]: {},
};

export default createReducer<PricingEntities, ActionTypes>(initialPricingState)
  // Spread the lodash merge to keep immutability
  .handleType(PricingActionType.FETCH_PRICING_REGION_SUCCESS, (state, { payload }) => ({
    ...state,
    [PricingEntity.TIERS]: { ...merge(state[PricingEntity.TIERS], payload[PricingEntity.TIERS]) },
    [PricingEntity.REGIONS]: { ...merge(state[PricingEntity.REGIONS], payload[PricingEntity.REGIONS]) },
  }))
  .handleType(PricingActionType.FETCH_PRICING_REGION_OPTIONS_SUCCESS, (state, { payload }) => ({
    ...state,
    [PricingEntity.TIERS]: { ...merge(state[PricingEntity.TIERS], payload[PricingEntity.TIERS]) },
    [PricingEntity.REGIONS]: { ...merge(state[PricingEntity.REGIONS], payload[PricingEntity.REGIONS]) },
  }))
  .handleType(PricingActionType.FETCH_PRICING_REGION_ERROR, (state) => state)
  .handleType(ProfileActionTypes.FETCH_SUCCESS, (state, { payload }) => ({
    ...state,
    [PricingEntity.TIERS]: { ...merge(state[PricingEntity.TIERS], payload.entities[PricingEntity.TIERS]) },
    [PricingEntity.REGIONS]: { ...merge(state[PricingEntity.REGIONS], payload.entities[PricingEntity.REGIONS]) },
  }))
  .handleType(ProfileActionTypes.UPDATE_SUCCESS, (state, { payload }) => ({
    ...state,
    [PricingEntity.TIERS]: { ...merge(state[PricingEntity.TIERS], payload.entities[PricingEntity.TIERS]) },
    [PricingEntity.REGIONS]: { ...merge(state[PricingEntity.REGIONS], payload.entities[PricingEntity.REGIONS]) },
  }));
