import type EntityType from '../../utils/entityType';
import type { OrderInvoiceSummaryResponse } from 'foundshared/src/api/orders/types';
import type { Supplier } from 'foundshared/src/api/suppliers/types';


export enum SupplierActionTypes {
  FETCH_PERFORM = '@@suppliers/FETCH_PERFORM',
  FETCH_SUCCESS = '@@suppliers/FETCH_SUCCESS',
  FETCH_ERROR = '@@suppliers/FETCH_ERROR',
}

// export const SupplierEntitiesPayloadCodec = t.interface({
//   entities: t.partial({
//     suppliers: SupplierEntitiesCodec,
//   }),
// });

// export type SupplierEntitiesPayload = t.TypeOf<typeof SupplierEntitiesPayloadCodec>;

export type EntityState = {
  [EntityType.SUPPLIERS]: Record<number, Supplier>;
  [EntityType.INVOICES]: Record<string, OrderInvoiceSummaryResponse>;
};

export enum InvoicesActionTypes {
  UPDATE_PERFORM = '@@invoices/UPDATE_PERFORM',
  UPDATE_SUCCESS = '@@invoices/UPDATE_SUCCESS',
  UPDATE_ERROR = '@@invoices/UPDATE_ERROR',
}
