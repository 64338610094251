import { InvoicesActionTypes, SupplierActionTypes } from './types';
import { fetchError, fetchSuccess, updateError, updateSuccess } from './actions';
import { invoicesCollectionEntitySchema, supplierCollectionSchema } from './schema';

import handleError from '../handleError';
import { addSuccess } from '../notifications/actions';
import { apiActions } from '../../api';
import { bffActions } from '../../bff';

import axios from 'axios';
import { call, cancelled, put, spawn, takeLatest } from 'typed-redux-saga';
import { normalize } from 'normalizr';

import type { Supplier } from 'foundshared/src/api/suppliers/types';
import type { OrderInvoiceSummaryResponse } from 'foundshared/src/api/orders/types';
import type { fetchPerform, updatePerform } from './actions';

function* updateInvoicesSaga(action: ReturnType<typeof updatePerform>) {
  try {
    const result = yield* call(apiActions.invoices.updateInvoices, action.payload.paid, action.payload.ids);

    const normalisedResult = normalize<OrderInvoiceSummaryResponse>(result.right, invoicesCollectionEntitySchema);

    const successMessage = `Order${action.payload.ids.length ? 's' : ''} successfully marked as ${
      action.payload.paid ? 'paid' : 'unpaid'
    }.`;

    yield* put(updateSuccess(normalisedResult.entities, action.meta));
    yield* put(addSuccess(successMessage));
    action.meta?.callback();
  } catch (error: any) {
    yield* handleError(error, put, function* () {
      yield* put(updateError(error.message));
    });
  }
}

// / /////////////////////////////////  END //////////////////////////////////////

export function* updateOrderSaga() {
  yield* takeLatest(InvoicesActionTypes.UPDATE_PERFORM, updateInvoicesSaga);
}

function* suppliersInstanceSaga(action: ReturnType<typeof fetchPerform>): any {
  const cancelSource = axios.CancelToken.source();
  try {
    const result = yield* call(bffActions.suppliers.fetchSuppliers, cancelSource.token);
    const { entities } = normalize<Supplier>(result.right, supplierCollectionSchema);

    if (entities.suppliers) {
      yield* put(fetchSuccess(entities.suppliers));
      if (action?.meta?.callback) action.meta.callback();
    }
  } catch (error: any) {
    yield* handleError(error, put, function* () {
      yield* put(fetchError(error));
    });
  } finally {
    if (yield* cancelled()) {
      yield* call(cancelSource.cancel);
    }
  }
}

export function* fetchSuppliersSaga() {
  yield* takeLatest(SupplierActionTypes.FETCH_PERFORM, suppliersInstanceSaga);
}

const spawnedAppSagas = [spawn(updateOrderSaga), spawn(fetchSuppliersSaga)];

export default spawnedAppSagas;
