import { ReferralsActionTypes, ReferrerActionTypes } from './types';
import { fetchReferralsError, fetchReferralsSuccess, fetchReferrerError, fetchReferrerSuccess } from './actions';

import handleError, { isAxiosError } from '../handleError';
import { apiActions } from '../../api';

import { call, put, takeLatest, takeLeading } from 'typed-redux-saga';

import type { AxiosError } from 'axios';
import type { ApiError } from '../../api/types';
import type { fetchReferrerPerform } from './actions';

function* fetchReferralsInstanceSaga() {
  try {
    const result = yield* call(apiActions.referrals.fetchReferrals);
    yield* put(fetchReferralsSuccess(result.right));
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(fetchReferralsError(error));
    });
  }
}

function* handleReferrerError(error: Error | AxiosError<ApiError>, slug: string) {
  if (isAxiosError(error) && error.response?.status === 422) {
    yield* put(fetchReferrerError(slug));
  } else {
    yield* handleError(error, put, function* () {
      yield* put(fetchReferrerError(slug));
    });
  }
}

export function* fetchReferrerInstanceSaga({ payload }: ReturnType<typeof fetchReferrerPerform>) {
  try {
    const result = yield* call(apiActions.referrals.fetchReferrer, payload);
    yield* put(fetchReferrerSuccess(result.right, payload));
  } catch (error) {
    if (error instanceof Error) yield* handleReferrerError(error, payload);
  }
}

// The "takeLeading" action is used in a hook, so is subject to fire excessively on mount, so using takeLeading makes sense.
const referralsSagas = [
  takeLeading(ReferrerActionTypes.FETCH_PERFORM, fetchReferrerInstanceSaga),
  takeLatest(ReferralsActionTypes.FETCH_PERFORM, fetchReferralsInstanceSaga),
];

export default referralsSagas;
