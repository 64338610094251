import omit from 'lodash/omit';
import { ActionType, createReducer } from 'typesafe-actions';

import { Quote } from './types';
import { QuoteActionTypes } from './constants';
import { createQuoteSuccess, deleteQuoteSuccess, fetchQuotesSuccess } from './actions';

export type QuotesState = Record<string, Quote>;

export const initialQuotesState: QuotesState = {};

type ActionTypes =
  | ActionType<typeof fetchQuotesSuccess>
  | ActionType<typeof createQuoteSuccess>
  | ActionType<typeof deleteQuoteSuccess>;

const ordersReducer = createReducer<QuotesState, ActionTypes>(initialQuotesState)
  .handleType(QuoteActionTypes.FETCH_SUCCESS, (state, { payload }) => ({ ...state, ...payload.entities.quotes }))
  .handleType(QuoteActionTypes.CREATE_SUCCESS, (state, { payload }) => ({ ...state, ...payload.entities.quotes }))
  .handleType(QuoteActionTypes.DELETE_SUCCESS, (state, action) => omit(state, action.payload.id));

export default ordersReducer;
