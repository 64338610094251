import * as schemas from './schema';
import { PricingActionType } from './types';
import {
  fetchPricingError,
  fetchPricingOptionsError,
  fetchPricingOptionsSuccess,
  fetchPricingSuccess,
} from './actions';

import handleError from '../handleError';
import { apiActions } from '../../api';

import { call, put, spawn, takeLatest } from 'typed-redux-saga';
import { normalize } from 'normalizr';

import type { Right } from 'fp-ts/lib/Either';
import type {
  PricingEntities,
  PricingRegionTierOptionsResponse,
  PricingRegionsResponse,
} from 'foundshared/src/api/pricing/types';
import type { fetchPricingOptionsPerform } from './actions';

// Note that these use the same schema.

const normalisePricingRegionsResponse = (result: Right<PricingRegionsResponse>) =>
  normalize<PricingRegionsResponse, PricingEntities>(result.right, schemas.pricingRegionCollectionSchema);

const normalisePricingOptionsResponse = (result: Right<PricingRegionTierOptionsResponse>, regionId: string) => {
  const resultWithRegionId = [{ id: regionId, pricingRegionTiers: result.right }];
  return normalize<PricingRegionsResponse, PricingEntities>(resultWithRegionId, schemas.pricingRegionCollectionSchema);
};

function* fetchInstanceSaga() {
  try {
    const result = yield* call(apiActions.pricing.fetchPricing);
    const normalisedResult = normalisePricingRegionsResponse(result);

    yield* put(fetchPricingSuccess(normalisedResult.entities));
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(fetchPricingError(error));
    });
  }
}

function* fetchOptionsInstanceSaga({ payload }: ReturnType<typeof fetchPricingOptionsPerform>) {
  try {
    const result = yield* call(apiActions.pricing.fetchPricingOptions, payload);
    const normalisedResult = normalisePricingOptionsResponse(result, payload);

    yield* put(fetchPricingOptionsSuccess(normalisedResult.entities));
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(fetchPricingOptionsError(error));
    });
  }
}

function* fetchPricingOptionsSaga() {
  yield* takeLatest(PricingActionType.FETCH_PRICING_REGION_OPTIONS_PERFORM, fetchOptionsInstanceSaga);
}

function* fetchPricingSaga() {
  yield* takeLatest(PricingActionType.FETCH_PRICING_REGION_PERFORM, fetchInstanceSaga);
}

const spawnedAppSagas = [spawn(fetchPricingSaga), spawn(fetchPricingOptionsSaga)];

export default spawnedAppSagas;
