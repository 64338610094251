import { QuoteActionTypes } from './constants';

import { action } from 'typesafe-actions';

import type { NewQuote } from 'foundshared/src/api/quotes/types';


export const createQuoteError = (error: Error) => action(QuoteActionTypes.CREATE_ERROR, undefined, undefined, error);
export const createQuotePerform = (data: NewQuote, meta?: { callback: (id: string) => void }) =>
  action(QuoteActionTypes.CREATE_PERFORM, data, meta);
export const createQuoteSuccess = (payload: any, meta?: any) => action(QuoteActionTypes.CREATE_SUCCESS, payload, meta);

export const deleteQuoteError = (error: Error) => action(QuoteActionTypes.DELETE_ERROR, undefined, undefined, error);
export const deleteQuotePerform = (id: string, meta: { callback: () => void }) =>
  action(QuoteActionTypes.DELETE_PERFORM, { id }, meta);
export const deleteQuoteSuccess = (id: string, meta?: any) => action(QuoteActionTypes.DELETE_SUCCESS, { id }, meta);

export const fetchQuotesError = (error: Error) => action(QuoteActionTypes.FETCH_ERROR, undefined, undefined, error);
export const fetchQuotesPerform = (meta?: any) => action(QuoteActionTypes.FETCH_PERFORM, undefined, meta);
export const fetchQuotesSuccess = (data: any, meta?: any) => action(QuoteActionTypes.FETCH_SUCCESS, data, meta);

export const loadError = (error: Error) => action(QuoteActionTypes.LOAD_ERROR, undefined, undefined, error);
export const loadPerform = (id: string) => action(QuoteActionTypes.LOAD_PERFORM, { id });
export const loadSuccess = (data: any, meta?: any) => action(QuoteActionTypes.LOAD_SUCCESS, data, meta);
