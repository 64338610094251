import migrations from './persistMigration';
import rootReducer from './reducer';
import rootSaga from './sagas';

import { updateState as updateInterceptorState } from '../api/interceptors';
import environment from '../utils/environment';
import userTracking from '../utils/tracking/userTracking';

import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { applyMiddleware, compose, createStore } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import { createReduxEnhancer } from '@sentry/react';

import type { AppState } from './reducer';



const PERSIST_KEY = 'pour/root';

const persistConfig = {
  key: PERSIST_KEY,
  storage,
  debug: true,
  version: 3,
  blacklist: ['notifications', 'submitting', 'loading', 'errors', 'orders', 'serviceAreas', 'pricing'],
  migrate: createMigrate(migrations),
};

const sentryReduxEnhancer = createReduxEnhancer();

// Redux Middleware to set the session token in the API layer. This avoids a circlular dependency where:
// store <imports> saga <imports> api <imports> interceptor <imports> store
const informApiInterceptor =
  (api: { getState: () => AppState }) =>
  <ACTION>(next: (action: ACTION) => unknown) =>
  (action: ACTION) => {
    try {
      const state = api.getState();

      updateInterceptorState({ session: state.session });
    } finally {
      next(action);
    }
  };

export function makeStore(initalState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const enhancer = composeWithDevTools({ maxAge: 200 })(applyMiddleware(sagaMiddleware, informApiInterceptor));
  const store = createStore(persistedReducer, initalState, compose(enhancer, sentryReduxEnhancer));
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  if (!environment.isProduction && (module as any).hot) {
    (module as any).hot.accept('./reducer', () => store.replaceReducer(persistedReducer));
  }

  userTracking(store);
  return { persistor, store };
}

export const { store, persistor } = makeStore();
