import { ThemeOptions } from '@mui/material/styles';

import alerts from './alerts';
import buttons from './buttons';
import containers from './containers';
import cssBaseline from './cssBaseline';
import dialogs from './dialogs';
import inputs from './inputs';
import stepper from './steppers';
import tables from './tables';
import tabs from './tabs';

const overrides: ThemeOptions['components'] = {
  ...alerts,
  ...buttons,
  ...containers,
  ...cssBaseline,
  ...dialogs,
  ...inputs,
  ...stepper,
  ...tabs,
  ...tables,
  MuiLink: {
    defaultProps: {
      underline: 'none',
    },
  },
};

export default overrides;
