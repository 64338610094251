import { createReducer } from 'typesafe-actions';

import { Notification, NotificationActionTypes } from './types';
import { addError, addSuccess, remove } from './actions';

export type NotificationState = Array<Notification>;
const initialState: NotificationState = [];

type ActionType = ReturnType<typeof addError> | ReturnType<typeof addSuccess> | ReturnType<typeof remove>;

type AddActionType = ReturnType<typeof addError> | ReturnType<typeof addSuccess>;

const reducer = createReducer<NotificationState, ActionType>(initialState)
  .handleType(NotificationActionTypes.ADD, (state: NotificationState, action: AddActionType) => [
    ...state,
    action.payload,
  ])
  .handleType(NotificationActionTypes.REMOVE, (state: NotificationState, action: ReturnType<typeof remove>) =>
    state.filter((notification: Notification) => notification.id !== action.payload)
  );

export default reducer;
