import { Store } from 'redux';

import { AppState } from './reducer';

function observeStore<T1, T2>(store: Store, select: (state: AppState) => T1, onChange: (selectedState: T1) => T2) {
  let currentState: T1;

  function handleChange() {
    const nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}

export default observeStore;
