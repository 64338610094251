import { Theme, alpha, darken } from '@mui/material';
import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import Button, { ButtonProps } from '../Button';

export interface ProceedButtonProps extends ButtonProps {
  colorType?: 'light' | 'dark' | 'link';
}

const containedPrimary = (theme: Theme) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.light,
  '&:hover': {
    backgroundColor: darken(theme.palette.primary.light, 0.25),
    '@media (hover: none)': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.light,
    },
  },

  '&.Mui-disabled': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.light,
    opacity: 0.5,
  },
});

const outlinedPrimary = (theme: Theme) => ({
  color: theme.palette.text.primary,
  border: `1px solid ${alpha(theme.palette.primary.light, 0.5)}`,
  '&:hover': {
    border: `1px solid ${theme.palette.primary.light}`,
    backgroundColor: alpha(theme.palette.primary.light, theme.palette.action.hoverOpacity),
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
});

export const stylesLight = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.primary,

    '&:hover': {
      textDecoration: 'none',

      backgroundColor: darken(theme.palette.primary.light, theme.palette.action.hoverOpacity),

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.light,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
      opacity: 0.5,
    },
  },
  containedPrimary: containedPrimary(theme),
  outlinedPrimary: outlinedPrimary(theme),
  disabled: {},
  label: {
    whiteSpace: 'nowrap' as const,
  },
});

export const stylesDark = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,

    '&:hover': {
      textDecoration: 'none',

      backgroundColor: darken(theme.palette.primary.dark, theme.palette.action.hoverOpacity),

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: `${alpha(theme.palette.primary.dark, 0.2)}`,
    },
  },
  containedPrimary: containedPrimary(theme),
  outlinedPrimary: outlinedPrimary(theme),
  disabled: {},
  label: {
    whiteSpace: 'nowrap' as const,
  },
});

export const stylesLink = (theme: Theme) => ({
  root: {
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: 'unset',
      boxShadow: 'none',

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'unset',
      },
      '&.Mui-disabled': {
        backgroundColor: 'unset',
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      backgroundColor: 'unset',
      opacity: 0.5,
    },
  },
  containedPrimary: {},
  outlinedPrimary: {},
  disabled: {},
  label: {
    whiteSpace: 'nowrap' as const,
  },
});

function ProceedButton({ colorType = 'light', ...props }: ProceedButtonProps, ref: any) {
  let styles;
  switch (colorType) {
    case 'dark':
      styles = stylesDark;
      break;
    case 'link':
      styles = stylesLink;
      break;
    default:
      styles = stylesLight;
      break;
  }
  const useProceedButtonStyles = makeStyles()(styles);
  const { classes } = useProceedButtonStyles();

  return <Button {...props} ref={ref} colorType={colorType} classes={classes} />;
}

export const ProceedButtonComp = forwardRef(ProceedButton);

export default ProceedButtonComp;
