import EntityType from '../../utils/entityType';
import { catalogueEntitySchema } from '../catalogues/schema';

import { schema } from 'normalizr';

import type { QuoteResponse } from 'foundshared/src/api/quotes/types';


export const quoteEntitySchema = new schema.Entity<QuoteResponse>(EntityType.QUOTES, {
  catalogue: catalogueEntitySchema,
});

export const quoteCollectionSchema = new schema.Array(quoteEntitySchema);
