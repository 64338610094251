import { ThemeOptions } from '@mui/material/styles';

const steppers: ThemeOptions['components'] = {
  MuiStepper: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2),
      }),
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {},
      line: ({ theme }) => ({
        borderColor: theme.palette.secondary.lighter,
      }),
      lineVertical: {
        borderLeftStyle: 'dashed',
        borderLeftWidth: 2,
      },
      vertical: {
        padding: 0,
      },
      active: ({ theme }) => ({
        '& .Mui-lineVertical': {
          borderLeftStyle: 'solid',
        },
        '& .Mui-line': {
          borderColor: theme.palette.success.main,
        },
      }),
      completed: ({ theme }) => ({
        '& .Mui-lineVertical': {
          borderLeftStyle: 'solid',
        },
        '& .Mui-line': {
          borderColor: theme.palette.success.main,
        },
      }),
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        fill: theme.palette.secondary.lighter,

        '&.Mui-completed': {
          fill: theme.palette.success.main,
        },

        '&.Mui-active': {
          fill: theme.palette.primary.main,
          '& .MuiStepIcon-text': {
            fill: theme.palette.common.white,
          },
        },
      }),
      text: ({ theme }) => ({
        fontWeight: 600,
        fontSize: '0.875rem',
        fill: theme.palette.secondary.main,
      }),
      // active: ({ theme }) => ({
      //   '& .MuiStepIcon-text': {
      //     fill: theme.palette.common.white,
      //   },
      // }),
      completed: {},
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: { padding: 0 },
      label: ({ theme }) => ({
        color: theme.palette.secondary.main,

        fontSize: theme.typography.h5.fontSize,
        '&.MuiStepLabel-completed': {
          color: theme.palette.success.main,
        },
      }),
      iconContainer: ({ theme }) => ({
        paddingRight: theme.spacing(2),
      }),
    },
  },
};

export default steppers;
