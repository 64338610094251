import type { ThemeOptions } from '@mui/material/styles';

const cssBaseline: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        '&::WebkitScrollbar': {
          display: 'none',
        },
      },
      html: {
        WebkitFontSmoothing: 'auto',
      },
      'html, body': {
        margin: 0,
        height: '100%',
        fontFamily: "'Inter', sans-serif",
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        MsOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none',
        backgroundColor: '#fff',
        scrollBehavior: 'smooth',
      },
      'html, body, #__next': {
        height: '100%',
      },
      "input[type='number']": {
        '&::WebkitInnerSpinButton, &::WebkitOuterSpinButton': {
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          margin: 0,
        },
      },
      iframe: {
        border: '1px solid black',
        width: '100%',
        minHeight: '400px !important',
      },
      fieldset: {
        border: 'none',
        padding: 0,
        margin: 0,
      },
      address: {
        fontStyle: 'normal',
      },
      a: {
        textDecoration: 'none',
      },
      '@font-face': [
        {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 100,
          fontDisplay: 'swap',
          src: 'url("../fonts/inter/Inter-Thin.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-Thin.woff?v=3.19") format("woff")',
        },
      ],
      // https://github.com/mui/material-ui/issues/24966#issuecomment-973929411
      fallbacks: [
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 100,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-ThinItalic.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 200,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-ExtraLight.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 200,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 300,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-Light.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-Light.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 300,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-LightItalic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-LightItalic.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-Regular.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 400,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-Italic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-Italic.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-Medium.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 500,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-MediumItalic.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 600,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-Bold.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 700,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-BoldItalic.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 800,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-ExtraBold.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 800,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 900,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-Black.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-Black.woff?v=3.19") format("woff")',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Inter',
            fontStyle: 'italic',
            fontWeight: 900,
            fontDisplay: 'swap',
            src: 'url("../fonts/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"), url("../fonts/inter/Inter-BlackItalic.woff?v=3.19") format("woff")',
          },
        },
      ],
    },
  },
};

export default cssBaseline;
