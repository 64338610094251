import reducer, { initalAppContextState } from './reducer';
import { AppContextField } from './types';
import { updateOrderSteps, updateValues } from './actions';

import useQueryString from '../../hooks/useQueryString';
import { OrderMode, mapValuesFromParams, orderFilterFromParams } from '../../components/Orders/utils';
import { clearCart } from '../../store/cart/actions';

import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import type { AppContextDispatch, AppContextState } from './types';

const AppStateContext = React.createContext<AppContextState | undefined>(undefined);

export const AppStateDispatch = React.createContext<AppContextDispatch | undefined>(undefined);

interface AppContextProviderProps {
  children: React.ReactNode;
  isHub?: boolean;
  /** Currently used for unit tests only */
  initialValues?: Partial<AppContextState>;
}

/** @deprecated This is trying to do too much, needs breaking up with a URL state via queries.  */
function AppContextProvider({ children, isHub, initialValues }: AppContextProviderProps) {
  const { query } = useRouter();

  const orderQueryId = useQueryString('order');
  const quoteQueryId = useQueryString('quote');
  const filterQueryId = useQueryString('filter');
  const filteParam = orderFilterFromParams(filterQueryId);

  const dispatch = useDispatch();
  // const { pathname } = useRouter();

  const matchedParams = mapValuesFromParams(query);

  const [state, contextDispatch] = useReducer(
    reducer,
    initalAppContextState({ ...initialValues, [AppContextField.IS_HUB]: isHub, ...matchedParams })
  );

  const order = state[AppContextField.ORDER_ID];
  const quote = state[AppContextField.QUOTE_ID];
  const filter = state[AppContextField.FILTER];

  // Syncs our app context (order, filter, quote) with the URL.
  useEffect(() => {
    contextDispatch(
      updateOrderSteps({ [AppContextField.ORDER_ID]: orderQueryId, [AppContextField.QUOTE_ID]: quoteQueryId })
    );
    if (filteParam && filteParam !== filter) contextDispatch(updateValues({ [AppContextField.FILTER]: filteParam }));
  }, [filter, filteParam, order, orderQueryId, quote, quoteQueryId]);

  const orderMode = state[AppContextField.ORDER_MODE];

  useEffect(() => {
    // Everytime the order mode changes away from 'new' or 'edit', wipe the cart.
    if (![OrderMode.NEW, OrderMode.EDIT, OrderMode.QUOTE_TO_ORDER].includes(orderMode)) dispatch(clearCart());
  }, [dispatch, orderMode]);

  return (
    <AppStateContext.Provider value={state}>
      <AppStateDispatch.Provider value={contextDispatch}>{children}</AppStateDispatch.Provider>
    </AppStateContext.Provider>
  );
}

/** @deprecated This needs to be replaced with a URL state via queries.  */
function useAppContextState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppContextState must be used within a AppContextProvider');
  }
  return context;
}

/** @deprecated This needs to be replaced with a URL state via queries.  */
function useAppContextDispatch() {
  const context = React.useContext(AppStateDispatch);
  if (context === undefined) {
    throw new Error('useAppContextDispatch must be used within a AppContextProvider');
  }
  return context;
}

/** @deprecated This needs to be replaced with a URL state via queries.  */
function useAppContext() {
  return { state: useAppContextState(), dispatch: useAppContextDispatch() };
}

export { AppContextProvider, useAppContextState, useAppContextDispatch, useAppContext };
