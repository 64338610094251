import { FeatureToggleActionTypes } from './constants';
import { fetchError, fetchSuccess } from './actions';

import { apiActions } from '../../api';
import handleError from '../handleError';

import { call, put, takeLatest } from 'typed-redux-saga';

export function* fetchInstanceSaga() {
  try {
    const result = yield* call(apiActions.features.fetchFeatureToggles);
    yield* put(fetchSuccess(result.right));
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(fetchError(error));
    });
  }
}

const spawnedAppSagas = [takeLatest(FeatureToggleActionTypes.FETCH_PERFORM, fetchInstanceSaga)];

export default spawnedAppSagas;
