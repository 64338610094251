import omit from 'lodash/omit';
import { ActionType, StateType, createReducer } from 'typesafe-actions';

import { EntityState, InvoicesActionTypes, SupplierActionTypes } from './types';
import { OrderActionTypes } from '../orders/types';
import { deleteOrderSuccess, fetchOrdersFirstSuccess, fetchOrdersSuccess, loadSuccess } from '../orders/actions';
import { fetchSuccess, updateSuccess } from './actions';

export const initialState: EntityState = {
  suppliers: {},
  invoiceSummaries: {},
};

type ActionTypes =
  | ActionType<typeof updateSuccess>
  | ActionType<typeof fetchSuccess>
  | ActionType<typeof fetchOrdersSuccess>
  | ActionType<typeof fetchOrdersFirstSuccess>
  | ActionType<typeof deleteOrderSuccess>
  | ActionType<typeof loadSuccess>;

const entitiesReducer = createReducer<EntityState, ActionTypes>(initialState)
  .handleType(SupplierActionTypes.FETCH_SUCCESS, (state, action: ActionType<typeof fetchSuccess>) => ({
    ...state,
    suppliers: action.payload,
  }))
  .handleType(OrderActionTypes.FETCH_SUCCESS, (state, { payload }: ActionType<typeof fetchOrdersSuccess>) => ({
    ...state,
    invoiceSummaries: { ...state.invoiceSummaries, ...payload.entities.invoiceSummaries },
  }))
  .handleType(
    OrderActionTypes.FETCH_SUCCESS_FIRST,
    (state, { payload }: ActionType<typeof fetchOrdersFirstSuccess>) => ({
      ...state,
      invoiceSummaries: { ...state.invoiceSummaries, ...payload.entities.invoiceSummaries },
    })
  )
  .handleType(OrderActionTypes.DELETE_SUCCESS, (state, { payload }) => ({
    ...state,
    invoiceSummaries: omit(state.invoiceSummaries, payload.invoiceIds ?? []),
  }))
  .handleType(OrderActionTypes.LOAD_SUCCESS, (state, { payload }) => ({
    ...state,
    invoiceSummaries: { ...state.invoiceSummaries, ...payload.entities.invoiceSummaries },
  }))
  .handleType(InvoicesActionTypes.UPDATE_SUCCESS, (state, { payload }) => ({
    ...state,
    invoiceSummaries: { ...state.invoiceSummaries, ...payload.invoiceSummaries },
  }));

export type EntitiesState = StateType<typeof entitiesReducer>;

export default entitiesReducer;
