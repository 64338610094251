import NextImage, { ImageProps } from 'next/image';
import { memo } from 'react';

function imageLoader({ src }: { src: string }) {
  return src;
}

const Image = (props: ImageProps) => <NextImage {...props} loader={imageLoader} />;

export default memo(Image);
