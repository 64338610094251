import isArray from 'lodash/isArray';

export function joinAddress<T>(parts?: string[] | T | null): string {
  if (!parts) return '';
  if (isArray(parts)) return parts.filter((item) => !!item).join(', ');

  return Object.values(parts)
    .filter((item) => !!item)
    .join(', ');
}

export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.includes('+61')) {
    const shortNum = phoneNumber.replace('+61', '');

    return `(0${shortNum[0]}) ${shortNum.substr(1, 4)} ${shortNum.substr(5, 4)}`;
  }

  if (phoneNumber.indexOf('0') === 0) {
    return `(${phoneNumber.substr(0, 2)}) ${phoneNumber.substr(2, 4)} ${phoneNumber.substr(6, 4)}`;
  }

  return phoneNumber;
}

export function formatMobileNumber(phoneNumber: string): string {
  if (phoneNumber.includes('+61')) {
    const shortNum = phoneNumber.replace('+61', '');

    return `0${shortNum.substr(0, 3)} ${shortNum.substr(3, 3)} ${shortNum.substr(6, 3)}`;
  }

  if (phoneNumber.indexOf('0') === 0) {
    return `${phoneNumber.substr(0, 4)} ${phoneNumber.substr(4, 3)} ${phoneNumber.substr(7, 3)}`;
  }

  return phoneNumber;
}
