import { requestInterceptor, responseInterceptor, responseErrorInterceptor } from './interceptors';

import environment from '../utils/environment';

import bffSetup from 'foundshared/src/api/bff';

const { apiInstance: axiosInstance, requests: bffActions } = bffSetup({ baseURL: environment.BFF_ORIGIN });

axiosInstance.defaults.baseURL = environment.BFF_ORIGIN;

axiosInstance.interceptors.request.use(requestInterceptor);

axiosInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export { bffActions, axiosInstance };
