/**
 * In the event we create a design system
 * it would be nice to have everything in one place
 * to extract into a lib at a later date
 */

import { PaletteOptions } from '@mui/material/styles';

import { ColorPalette } from './colorPalette';

// To make these appear in component's props (i.e. "color"); follow this: https://stackoverflow.com/a/71668418/3114826
declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
    proceed: Palette['primary'];
    standard: Palette['primary'];
  }
  interface PaletteOptions {
    accent: PaletteOptions['primary'];
    proceed: PaletteOptions['primary'];
    standard: PaletteOptions['primary'];
  }

  interface PaletteColor {
    lightest?: string;
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lightest?: string;
    lighter?: string;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
    proceed: true;
    standard: true;
  }
}

// Update the CircularProgress's color prop options
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    accent: true;
    proceed: true;
    standard: true;
  }
}

export const light: PaletteOptions = {
  mode: 'light',
  text: {
    primary: ColorPalette.BASTILLE,
    secondary: ColorPalette.JUMBO,
    disabled: ColorPalette.BERMUDA_GREY_4,
  },
  primary: {
    lightest: ColorPalette.DARK_CLAY_1,
    lighter: ColorPalette.DARK_CLAY_2,
    light: ColorPalette.SHAMROCK,
    main: ColorPalette.BASTILLE,
    dark: ColorPalette.BASTILLE,
    contrastText: ColorPalette.LYNX_WHITE,
  },
  secondary: {
    lightest: ColorPalette.BERMUDA_GREY_1,
    lighter: ColorPalette.MERCURY,
    light: ColorPalette.SHIP_GREY,
    main: ColorPalette.BASTILLE,
    contrastText: ColorPalette.LYNX_WHITE,
  },
  accent: {
    main: ColorPalette.SHAMROCK,
    contrastText: ColorPalette.DARK_CLAY_4,
  },
  standard: {
    light: ColorPalette.SHAMROCK,
    main: ColorPalette.BERMUDA_GREY_1,
    dark: ColorPalette.BERMUDA_GREY_2,
    contrastText: ColorPalette.BASTILLE,
  },
  warning: {
    dark: ColorPalette.CLEMENTINE,
    main: ColorPalette.SCHOOL_BUS_YELLOW,
  },
  error: {
    main: ColorPalette.AMARANTH,
  },
  success: {
    main: ColorPalette.SHAMROCK,
  },
  info: {
    main: ColorPalette.DARK_CLAY_4,
    contrastText: ColorPalette.BASTILLE,
  },
  proceed: {
    main: ColorPalette.SHAMROCK,
    contrastText: ColorPalette.BASTILLE,
    light: ColorPalette.UI_URL,
  },
  background: {
    default: ColorPalette.LYNX_WHITE,
    paper: ColorPalette.WHITE,
  },
  divider: ColorPalette.QUILL_GREY,
  contrastThreshold: 3,
  tonalOffset: 0.2,
  // BASTILLE top
};

export const dark: PaletteOptions = {
  ...light,
  mode: 'dark',
  primary: {
    main: ColorPalette.BERMUDA_GREY_1,
  },
  text: {
    ...light.text,
    primary: ColorPalette.BERMUDA_GREY_1,
  },
  background: {
    default: ColorPalette.BASTILLE,
    paper: ColorPalette.DARK_CLAY_4,
  },
};
