import EntityType from '../../utils/entityType';

import { schema } from 'normalizr';


export const availabilitySchema = new schema.Entity(EntityType.AVAILABILITY);
export const availabilityCollectionSchema = new schema.Array(availabilitySchema);

export const valueEntitySchema = new schema.Entity(EntityType.VALUES);
export const valueCollectionSchema = new schema.Array(valueEntitySchema);

export const optionEntitySchema = new schema.Entity(EntityType.OPTIONS, {
  [EntityType.VALUES]: valueCollectionSchema,
});
export const optionCollectionSchema = new schema.Array(optionEntitySchema);

export const optionGroupEntitySchema = new schema.Entity(EntityType.OPTION_GROUPS, {
  [EntityType.OPTIONS]: optionCollectionSchema,
});

export const optionGroupCollectionSchema = new schema.Array(optionGroupEntitySchema);

export const productTypeEntitySchema = new schema.Entity(EntityType.PRODUCT_TYPES, {
  [EntityType.OPTION_GROUPS]: optionGroupCollectionSchema,
});

export const productTypeCollectionSchema = new schema.Array(productTypeEntitySchema);

export const catalogueEntitySchema = {
  [EntityType.PRODUCT_TYPES]: productTypeCollectionSchema,
  productType: productTypeEntitySchema,
};

// Only way to distinguish each catalogue is to use the order id.
// export const cataloguesEntitySchema = new schema.Entity<Catalogue>(EntityType.CATALOGUES, catalogueEntitySchema, {
//   idAttribute: (_value, parent) => parent.id,
// });
