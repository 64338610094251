import * as navListStyles from './NavList.styles';

import DesktopItem from '../DesktopItem';
import PhoneIcon from '../../../../assets/icons/PhoneIcon';
import ProceedButton from '../../../ProceedButton';
import useLinkTracker from '../../../../utils/tracking/useLinkTracker';
import { PublicRoutePath } from '../../../../utils/routes';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import * as R from 'ramda';

import type { LinkProps } from '../../utils/navItems';

export interface NavListProps {
  isAuthenticated: boolean;
  unauthLinks: Array<LinkProps>;
  authLinks: Array<LinkProps>;
  isDark: boolean;
  isHideMenu?: boolean;
}

function NavList({ isAuthenticated, unauthLinks, authLinks, isDark, isHideMenu = false }: NavListProps) {
  const { classes: containerClasses } = navListStyles.useNavLinkContainerStyles();

  const unauthenticatedLinks = R.map((item) => <DesktopItem key={item.id} {...item} dark={isDark} />, unauthLinks);

  const authenticatedLinks = R.map((item) => <DesktopItem key={item.id} {...item} dark={isDark} />, authLinks);

  const quoteLinkRef = useLinkTracker('NEW_QUOTE_BTN');

  return (
    <Box className={containerClasses.root}>
      {isAuthenticated ? (
        <>{authenticatedLinks}</>
      ) : (
        <>
          {!isHideMenu && unauthenticatedLinks}
          <MenuItem>
            <PhoneIcon />
            <div className={containerClasses.icon}>1300 980 626</div>
          </MenuItem>
          {!isHideMenu && (
            <ProceedButton href={PublicRoutePath.QUOTE_PROCESS} ref={quoteLinkRef} size="medium">
              Get a quote
            </ProceedButton>
          )}
        </>
      )}
    </Box>
  );
}

export default NavList;
