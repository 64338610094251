import type { Referral, Referrer } from 'foundshared/src/api/referrals/types';

export enum ReferralsEntities {
  REFERRER = 'referrer',
  REFERRALS = 'referrals',
}

export enum ReferralsActionTypes {
  FETCH_PERFORM = '@@referrals/FETCH_PERFORM',
  FETCH_SUCCESS = '@@referrals/FETCH_SUCCESS',
  FETCH_ERROR = '@@referrals/FETCH_ERROR',
}

export enum ReferrerActionTypes {
  FETCH_PERFORM = '@@referrer/FETCH_PERFORM',
  FETCH_SUCCESS = '@@referrer/FETCH_SUCCESS',
  FETCH_ERROR = '@@referrer/FETCH_ERROR',
}

export type ReferralsState = {
  [ReferralsEntities.REFERRER]: Referrer | null;
  [ReferralsEntities.REFERRALS]: Referral[];
};
