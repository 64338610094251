import { PaletteMode } from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';
import { createTheme } from '@mui/material/styles';

import * as palette from './palette';
import overrides from './overrides';
import typography from './typography';

declare module '@mui/material/styles/createTransitions' {
  export interface Easing {
    decelerate: string;
  }
}

export const getTheme = (paletteType: PaletteMode = 'light') => {
  const theme = createTheme({
    typography,
    shape: { borderRadius: 8 },
    mixins: {
      toolbar: {
        minHeight: 64,
      },
      footer: {
        height: 90,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1023,
        lg: 1280,
        xl: 1440,
      },
    },
    transitions: {
      easing: {
        decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
      },
    },
    components: overrides,
    palette: palette[paletteType],
  });

  const shadows = theme.shadows.map((shadow, i) => (i === 1 ? '0px 2px 8px rgba(0, 0, 0, 0.08)' : shadow)) as Shadows;

  return { ...theme, shadows };
};
