import { createSelector } from 'reselect';

import { AppState } from '../reducer';
import { productTypeCollection$ } from '../catalogues/selectors';

export const cart$ = (state: AppState) => state.cart.order;

export const catalogueId$ = (state: AppState) => state.cart.id;

export const cartCatalogue$ = (state: AppState) => state.cart.catalogue;

// TODO: Temporary see FOUND-119
export const suburbComingSoon$ = (state: AppState) => state.cart.soon;

export const productTypeCollectionByCart$ = createSelector(
  [productTypeCollection$, cartCatalogue$],
  (options, catalogue) => options.filter((item) => catalogue && catalogue.productTypes.indexOf(item.id.toString()) > -1)
);
