import { declareActionFactory } from '../utilities';

import type { ServiceAreas } from 'foundshared/src/api/serviceAreas/codecs';
import type { Notifier } from '../utilities';

const declareAction = declareActionFactory('@@serviceAreas');

//* ********************************* fetch ***********************************//
export const fetchSuggestions = declareAction<{
  notifier: Notifier<ServiceAreas>;
  query: string;
}>('fetchSuggestions');

//* ************************ fetchSuggestionsSuccess **************************//
export const fetchSuggestionsSuccess = declareAction<ServiceAreas>('fetchSuggestionsSuccess');
