import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useNavLinkContainerStyles = makeStyles()(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      marginRight: spacing(0.5),
    },
  },
  icon: {
    paddingLeft: 16,
  },
}));
