import './app.css';

import '../src/utils/polyfills';
import DefaultLayout from '../src/components/Layout';
import NotificationDialogue from '../src/components/NotificationDialogue';
import NotificationSnackBar from '../src/components/NotificationSnackBar';
import SegmentTracking from '../src/components/SegmentTracking';
import ThemeProvider from '../src/v2/design/ThemeProvider';
import environment from '../src/utils/environment';
import { PrivateRoutePath } from '../src/utils/routes';
import { authenticated$ } from '../src/store/session/selectors';
import { catalogueId$ } from '../src/store/cart/selectors';
import { createQuotePerform } from '../src/store/quotes/actions';
import { fetchCataloguePerform } from '../src/store/cart/actions';
import { fetchPerform as fetchFeatureTogglesPerform } from '../src/store/features/actions';
import { fetchProfilePerform } from '../src/store/profile/actions';
import { getTheme } from '../src/design/theme';
// eslint-disable-next-line import/no-named-as-default
import { persistor, store } from '../src/store';
import { profileState$ } from '../src/store/profile/selectors';
import { signIn } from '../src/utils/e2eHelper';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { hotjar } from 'react-hotjar';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import Script from 'next/script';

import type { EmotionCache } from '@emotion/react';
import type { AppProps as NextAppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next/types';

interface AppProps extends NextAppProps {
  emotionCache: EmotionCache;
}

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const hotjarId = Number(environment.HOTJAR_ID);

if (!Number.isNaN(hotjarId) && !environment.isDevelopment && environment.isClient) {
  hotjar.initialize(hotjarId, 6);
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  /** Use the layout defined at the page level, if available */
  const { current: theme } = useRef(getTheme());

  const getLayout = Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>);

  const dispatch = useDispatch();
  const { push, pathname } = useRouter();

  const componentWithLayout = getLayout(<Component {...pageProps} />);

  const authenticated = useSelector(authenticated$);
  const profile = useSelector(profileState$);
  const catalogueId = useSelector(catalogueId$);

  useEffect(() => {
    dispatch(fetchFeatureTogglesPerform());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    if (authenticated) {
      dispatch(fetchProfilePerform());
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    if (authenticated && profile) {
      const quotePayloadString = window.localStorage.getItem('quotePayload');

      if (profile.customer?.status === 'requires_pricing_tier' && pathname !== PrivateRoutePath.QUESTIONS) {
        void push(PrivateRoutePath.QUESTIONS);
      }

      if (profile.customer?.status === 'active' && pathname !== PrivateRoutePath.QUESTIONS && quotePayloadString) {
        const quotePlayload = JSON.parse(quotePayloadString);
        dispatch(fetchCataloguePerform(quotePlayload.location.locality, quotePlayload.location.postcode));
        const updatedQuotePlayload = quotePlayload;
        updatedQuotePlayload.catalogueId = catalogueId;
        if (catalogueId) {
          dispatch(createQuotePerform(updatedQuotePlayload));
        }
      }
    }
  }, [authenticated, catalogueId, dispatch, pathname, profile, push]);

  return (
    <>
      <Head>
        <meta name="theme-color" content="#000000" key="theme-color" />
        <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
      </Head>
      {/* Start of HubSpot Embed Code  */}
      <Script id="hs-script-loader" async defer src="//js.hs-scripts.com/20084857.js" />
      <SegmentTracking />
      {/* End of HubSpot Embed Code  */}
      <ThemeProvider theme={theme}>
        <NotificationDialogue />
        <NotificationSnackBar />
        {componentWithLayout}
      </ThemeProvider>
    </>
  );
}

/**
 * @remarks Store is currently only configured for client, this will need
 * changing to something like next-redux-cookie-wrapper to reap SSR benefits.
 */
function ClientReduxWrapperOnboarding(props: AppPropsWithLayout) {
  if (environment.isServer)
    return (
      <Provider store={store}>
        <App {...props} />
      </Provider>
    );

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App {...props} />
      </PersistGate>
    </Provider>
  );
}

// must set e2eSignIn here as it will fail in other modules.
// This works in tandem with the 'library: "found"' output
// configuration in webpack.config
if (environment.isClient) (window as any).Found__e2eSignIn = signIn;

export default ClientReduxWrapperOnboarding;
