import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import { groupBy } from 'ramda';

const today = (deliverAt: string) => isSameDay(new Date(), new Date(deliverAt));

const nextSeven = (deliverAt: string) =>
  isAfter(new Date(deliverAt), new Date()) && isBefore(new Date(deliverAt), addDays(new Date(), 7));

const upcoming = (deliverAt: string) => isAfter(new Date(deliverAt), addDays(new Date(), 7));

export enum GroupOrderKey {
  TODAY = 'TODAY',
  NEXT_SEVEN_DAYS = 'NEXT_SEVEN_DAYS',
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
}

export const GroupOrderLabel = {
  [GroupOrderKey.TODAY]: 'Today',
  [GroupOrderKey.NEXT_SEVEN_DAYS]: 'Next 7 days',
  [GroupOrderKey.UPCOMING]: 'Upcoming',
  [GroupOrderKey.PAST]: 'Past',
};

export function groupOrders(orders: { id: string; deliverAt?: string }[]) {
  return groupBy((order: { id: string; deliverAt?: string }) => {
    // Quotes get put into the upcoming group
    if (!order.deliverAt) return GroupOrderKey.UPCOMING;

    if (today(order.deliverAt)) return GroupOrderKey.TODAY;

    if (nextSeven(order.deliverAt)) return GroupOrderKey.NEXT_SEVEN_DAYS;

    if (upcoming(order.deliverAt)) return GroupOrderKey.UPCOMING;

    return GroupOrderKey.PAST;
  }, orders);
}
