import { PlusLoadsActionTypes } from './types';

import { action } from 'typesafe-actions';

import type { PlusLoad, PlusLoadCollection } from 'foundshared/src/api/plusLoads/codecs';
import type { PlusLoadCreationValues } from 'foundshared/src/api/plusLoads/types';

type NewPlusLoadSubmissionMeta = {
  callback?: (orderId: string) => void;
  failedCallback?: () => void;
  successMessage?: string;
};

export const requestPlusLoads = () => action(PlusLoadsActionTypes.REQUEST_PLUS_LOADS, {});

export const requestPlusLoadsFinished = (data: PlusLoadCollection) =>
  action(PlusLoadsActionTypes.REQUEST_PLUS_LOADS_FINISHED, data);

export const requestPlusLoadsError = (error: Error) => action(PlusLoadsActionTypes.REQUEST_PLUS_LOADS_ERROR, error);

export const submitPlusLoad = (values: PlusLoadCreationValues, meta?: NewPlusLoadSubmissionMeta) =>
  action(PlusLoadsActionTypes.REQUEST_PLUS_LOAD_SUBMISSION, values, meta);

export const submitPlusLoadError = (error: Error) =>
  action(PlusLoadsActionTypes.REQUEST_PLUS_LOAD_SUBMISSION_ERROR, error);

export const submitPlusLoadFinished = (plusLoad: PlusLoad) =>
  action(PlusLoadsActionTypes.REQUEST_PLUS_LOAD_SUBMISSION_FINISHED, plusLoad);
