import format from 'date-fns-tz/format';
import { convertToTargetTimeZone, targetTimeZoneNow } from 'foundshared';

let timeOffsetBenchmark = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", {
  timeZone: 'Australia/Sydney',
});

export const setOffsetBenchmark = (input: string) => {
  timeOffsetBenchmark = input;
};

export const resetOffsetBenchmark = () => {
  timeOffsetBenchmark = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", {
    timeZone: 'Australia/Sydney',
  });
};

export const dateInTimeZone = (date?: string | number | Date | undefined) => {
  if (typeof date === 'string' || date instanceof String) {
    return new Date(convertToTargetTimeZone(date.toString()));
  }
  if (!date) {
    return targetTimeZoneNow(timeOffsetBenchmark);
  }
  if (typeof date === 'number') {
    return new Date(date);
  }
  return date;
};

export const toServerIsoDate = (date: string | number | Date): string =>
  format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", {
    timeZone: 'Australia/Sydney',
  });

export const toAvailabilityDate = (date: string | number | Date): string => format(date, 'iiii, d MMMM');
