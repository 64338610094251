export enum PrimaryColourOne {
  EGGPLANT_050 = '#fff7ff',
  EGGPLANT_100 = '#fcf2ff',
  EGGPLANT_200 = '#f7ecff',
  EGGPLANT_300 = '#eadff6',
  EGGPLANT_400 = '#c7bdd3',
  EGGPLANT_500 = '#a89eb4',
  EGGPLANT_600 = '#7e758a',
  EGGPLANT_700 = '#6a6175',
  EGGPLANT_800 = '#4a4255',
  /** Main shade */
  EGGPLANT_900 = '#282132',
}

export enum PrimaryColourTwo {
  GREEN_050 = '#e2fae9',
  GREEN_100 = '#b8f1c9',
  GREEN_200 = '#85e7a6',
  /** Main shade */
  GREEN_300 = '#38de80',
  GREEN_400 = '#00d561',
  GREEN_500 = '#00ca48',
  GREEN_600 = '#00ba3d',
  GREEN_700 = '#00a730',
  GREEN_800 = '#009525',
  GREEN_900 = '#00740d',
}

export enum SecondaryColourOne {
  YELLOW_050 = '#fffce5',
  YELLOW_100 = '#fef7be',
  YELLOW_200 = '#fdf193',
  YELLOW_300 = '#fceb66',
  YELLOW_400 = '#fbe63f',
  YELLOW_500 = '#f8e100',
  /** Main shade */
  YELLOW_600 = '#fad200',
  YELLOW_700 = '#f9b800',
  YELLOW_800 = '#f89f00',
  YELLOW_900 = '#f67300',
}

export enum SecondaryColourTwo {
  RED_050 = '#ffe9ed',
  RED_100 = '#ffc9cf',
  RED_200 = '#f79293',
  RED_300 = '#f06568',
  RED_400 = '#fa3941',
  RED_500 = '#ff151f',
  /** Main shade */
  RED_600 = '#f00022',
  RED_700 = '#de001d',
  RED_800 = '#d20014',
  RED_900 = '#c40003',
}

export enum SecondaryColourThree {
  BLUE_050 = '#e3f2fd',
  BLUE_100 = '#bbdefb',
  BLUE_200 = '#90caf9',
  BLUE_300 = '#64b5f6',
  BLUE_400 = '#42a5f5',
  BLUE_500 = '#2196f3',
  /** Main shade */
  BLUE_600 = '#1e88e5',
  BLUE_700 = '#1976d2',
  BLUE_800 = '#1565c0',
  BLUE_900 = '#0d47a1',
}

export enum SecondaryColourFour {
  GREEN_050 = '#ecfae9',
  GREEN_100 = '#cff1c7',
  GREEN_200 = '#afe8a2',
  GREEN_300 = '#8cde7b',
  GREEN_400 = '#6ed65c',
  GREEN_500 = '#4ece3d',
  /** Main shade */
  GREEN_600 = '#3dbd35',
  GREEN_700 = '#1ea92b',
  GREEN_800 = '#009420',
  GREEN_900 = '#007209',
}

export enum NeutralColourOne {
  GREY_050 = '#f9fafb',
  GREY_100 = '#f3f4f6',
  GREY_200 = '#e5e7eb',
  GREY_300 = '#d1d5db',
  GREY_400 = '#9ca3af',
  GREY_500 = '#6b7280',
  GREY_600 = '#4b5563',
  GREY_700 = '#374151',
  GREY_800 = '#1f2937',
  /** Main shade */
  GREY_900 = '#111827',
}

export enum NeutralColourTwo {
  WHITE_900 = '#fff',
}

// export enum ColorPalette {
//   ALABASTER = '#fafafa',
//   AMARANTH = '#ED2647',
//   ASTRAL = '#357BB1',
//   ACCENT = '#FDD522',
//   AZURE_RADIANCE_1 = '#69ceff',
//   AZURE_RADIANCE_2 = '#009DFF',
//   AZURE_RADIANCE_3 = '#006fcb',
//   BASTILLE = '#282132',
//   BERMUDA_GREY_1 = '#EEF2F5',
//   BERMUDA_GREY_2 = '#DEE5EB',
//   BERMUDA_GREY_3 = '#B6C4CF',
//   BERMUDA_GREY_4 = 'rgb(109 138 159 / 37%)',
//   BLACK = '#000000',
//   BLUE = '#001045',
//   CANDLE_LIGHT = '#FDD523',
//   CLEMENTINE = '#E56700',
//   DARK_CLAY_1 = '#D4D5D9',
//   DARK_CLAY_2 = '#7F808D',
//   DARK_CLAY_3 = '#313353',
//   DARK_CLAY_4 = '#1B1D3D',
//   DARK_CLAY_5 = '#1B1D3D',
//   GREY_NICKEL = '#B3B3B0',
//   JUMBO = '#74707A',
//   LINE = '#231F20',
//   LYNX_WHITE = '#F7F7F7',
//   MERCURY = '#E8E8E8',
//   MOUNTAIN_MEADOW = '#13AF77',
//   QUILL_GREY = '#CFCFCC',
//   SCHOOL_BUS_YELLOW = '#FFDB00',
//   SHAMROCK = '#38DE80',
//   SHIP_GREY = '#3E3747',
//   ULTRAMARINE = '#536EF9',
//   WHITE = '#fff',
//   UI_ERROR = '#B81237',
//   UI_WARNING = '#DC5800',
//   UI_SUCCESS = '#00A908',
//   UI_DEFAULT_BACKGROUND = '#F6F9FB',
//   UI_ERROR_BACKGROUND = '#FFE7EC',
//   UI_WARNING_BACKGROUND = '#FBE7DA',
//   UI_SUCCESS_BACKGROUND = '#E6F6E6',
//   UI_INFO = '#DFF7FF',
//   UI_BILLING = '#ECEDF4',
//   UI_URL = '#0CCC5F',
// }
