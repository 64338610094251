import type { PricingRegionTierOptionItemResponse } from 'foundshared/src/api/pricing/types';

export enum PricingActionType {
  FETCH_PRICING_REGION_PERFORM = '@@pricing/FETCH_PRICING_REGION_PERFORM',
  FETCH_PRICING_REGION_SUCCESS = '@@pricing/FETCH_PRICING_REGION_SUCCESS',
  FETCH_PRICING_REGION_ERROR = '@@pricing/FETCH_PRICING_REGION_ERROR',

  FETCH_PRICING_REGION_OPTIONS_PERFORM = '@@pricing/FETCH_PRICING_REGION_OPTIONS_PERFORM',
  FETCH_PRICING_REGION_OPTIONS_SUCCESS = '@@pricing/FETCH_PRICING_REGION_OPTIONS_SUCCESS',
  FETCH_PRICING_REGION_OPTIONS_ERROR = '@@pricing/FETCH_PRICING_REGION_OPTIONS_ERROR',
}

type TierOptionItemUnknown = Omit<PricingRegionTierOptionItemResponse, 'unitPrice'> & { unitPrice: string };

export type TierOptionItem = [string, (PricingRegionTierOptionItemResponse | TierOptionItemUnknown)[]];
