export const EMPTY_STRING = '';

export const verificationCodeRegExp = /^\d{3} ?\d{3}$/;
export const mobileRegExp = /(?:\+?61)?(?:\(0\)[23478]|\(?0?[23478]\)?)\d{8}/;
export const spaceOnlyRegExp = /.*\S.*/;
export const OPTION_REQUIRED_MESSAGE = 'Please select an option';
export const TEXT_REQUIRED_MESSAGE = 'This field is required';
export const MOBILE_INVALID_MESSAGE = 'Mobile number is not valid';
export const DEFAULT_MIN_VALUE_MESSAGE = 'This value is too small';
export const SITE_ACCESS_RESTRICTION_INVALID_MESSAGE = 'Please describe the site access restrictions';

export const minOrderMessage = (min: number, unit?: string | null) => `Minimum ${min}${unit ?? ''}`;
export const maxOrderMessage = (max: number, unit?: string | null) => `Maximum ${max}${unit ?? ''}`;

export enum ReferralOption {
  GOOGLE = 'Google',
  FACEBOOK = 'Facebook',
  FROM_A_FRIEND = 'From a friend',
  OTHER = 'Other (please specify)',
}

export enum OccupationOption {
  BUILDER = 'Builder',
  CONCRETER = 'Concreter',
  LANDSCAPER = 'Landscaper',
  PUMP = 'Pump Operator',
  OTHER = 'Other (please specify)',
}

export enum AccountField {
  IS_TRADE_CUSTOMER = 'isTradeCustomer',
  MOBILE = 'mobile',
  REFERRAL = 'referral',
  REFERRAL_OTHER = 'referralOther',
  TERMS = 'terms',
  VERIFICATION_CODE = 'verificationCode',
  BUYING_BEHALF = 'buyingOnBehalfOf',
}

export enum CustomerSignUpField {
  ABN = 'abn',
  ABN_STATE = 'abnState',
  COMPANY_NAME = 'companyName',
  OCCUPATION = 'occupation',
  OCCUPATION_OTHER = 'occupationOther',
  ORDERING_FREQUENCY = 'orderingFrequency',
  PREFERRED_SUPPLIER_ID = 'preferredSupplierId',
  BILLING_ADDRESS_LINE_1 = 'billingAddressLine1',
  BILLING_ADDRESS_LINE_2 = 'billingAddressLine2',
  REFERRAL_SLUG = 'referralSlug',
  BUSINESS_ATTRIBUTES = 'businessAttributes',
}

export enum BusinessSignUpField {
  ABN = 'abn',
  ABN_STATE = 'abnState',
  NAME = 'name',
  BUSINESS_NAME = 'businessName',
  OCCUPATION = 'occupation',
  OCCUPATION_OTHER = 'occupationOther',
}

export enum ProfileField {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export enum PromoCodeField {
  PROMO_CODE = 'promoCode',
}

export interface SignUpFormData {
  // Signup fields only
  [AccountField.IS_TRADE_CUSTOMER]: boolean | undefined;
  [AccountField.MOBILE]: string;
  [AccountField.REFERRAL]: ReferralOption | '';
  [AccountField.REFERRAL_OTHER]: string;
  [AccountField.TERMS]: boolean;
  [AccountField.VERIFICATION_CODE]: string;
  [AccountField.BUYING_BEHALF]: string | undefined;
  // Customer fields
  [CustomerSignUpField.BILLING_ADDRESS_LINE_1]: string;
  [CustomerSignUpField.BILLING_ADDRESS_LINE_2]: string;
  [CustomerSignUpField.ABN]: string;
  [CustomerSignUpField.ABN_STATE]: string;
  [CustomerSignUpField.COMPANY_NAME]: string;
  [CustomerSignUpField.OCCUPATION]: OccupationOption | '';
  [CustomerSignUpField.OCCUPATION_OTHER]: string;
  [CustomerSignUpField.PREFERRED_SUPPLIER_ID]: number;
  [CustomerSignUpField.REFERRAL_SLUG]?: string;
  // Profile fields
  [ProfileField.EMAIL]: string;
  [ProfileField.FIRST_NAME]: string;
  [ProfileField.LAST_NAME]: string;
  // Promo code fields
  [PromoCodeField.PROMO_CODE]?: string;
}
