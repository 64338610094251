import { AvailabilityActionTypes, ServiceAreaActionTypes } from './constants';
import {
  fetchAvailabilityError,
  fetchAvailabilitySuccess,
  fetchAvailabilitySuccessBFF,
  fetchError,
  fetchSuccess,
} from './actions';

import handleError from '../handleError';
import { addError } from '../notifications/actions';
import { setOffsetBenchmark } from '../../utils/dateTime';
import { apiActions } from '../../api';
import { bffActions } from '../../bff';

import { call, put, spawn, takeLatest } from 'typed-redux-saga';

import type { fetchAvailabilityBFFPerform, fetchAvailabilityPerform, fetchPerform } from './actions';

function* suggestionsInstanceSaga(action: ReturnType<typeof fetchPerform>) {
  try {
    const result = yield* call(apiActions.serviceAreas.fetchServiceAreas, action.payload);
    yield* put(fetchSuccess(result.right));
  } catch (error) {
    yield* handleError(error, put, function* () {
      yield* put(addError('There was a problem fetching suggested locations'));
      if (error instanceof Error) yield* put(fetchError(error));
    });
  }
}

export function* availabilityInstanceSaga(action: ReturnType<typeof fetchAvailabilityPerform>) {
  try {
    const { locality, postcode, productTypeId } = action.payload;
    const result = yield* call(apiActions.availability.fetchAvailability, locality, postcode, productTypeId);

    // TODO: this could be placed in other places
    if (result.right) {
      setOffsetBenchmark(result.right.availabilities[0].time);
    }

    yield* put(fetchAvailabilitySuccess(result.right));
    if (action.meta?.callback) action.meta.callback();
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(fetchAvailabilityError(error));
    });
  }
}

function* availabilityBffInstanceSaga(action: ReturnType<typeof fetchAvailabilityBFFPerform>) {
  try {
    const { locality, postcode, productTypeId } = action.payload;
    const result = yield* call(bffActions.availability.fetchAvailabilityBFF, locality, postcode, productTypeId);

    const resultData = result.data.data.matches || false;

    yield* put(fetchAvailabilitySuccessBFF(resultData));
    if (action.meta?.callback) action.meta.callback();
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(fetchAvailabilityError(error));
    });
  }
}

export function* availabilitySaga() {
  yield* takeLatest(AvailabilityActionTypes.FETCH_PERFORM, availabilityInstanceSaga);
}

export function* availabilityBffSaga() {
  yield* takeLatest(AvailabilityActionTypes.FETCH_PERFORM_BFF, availabilityBffInstanceSaga);
}

export function* suggestionSaga() {
  yield* takeLatest(ServiceAreaActionTypes.FETCH_PERFORM, suggestionsInstanceSaga);
}

const spawnedSuggesstionSaga = [spawn(suggestionSaga), spawn(availabilitySaga), spawn(availabilityBffSaga)];

export default spawnedSuggesstionSaga;
