import { useTheme } from '@mui/material/styles';

import * as logoStyles from './LogoFound.styles';

export interface LogoFoundProps {
  dark?: boolean;
  wordLetterClass?: string;
  wrapperClassName?: string;
  className?: string;
}

function LogoFound({ dark = false, wordLetterClass = '', wrapperClassName = '', className = '' }: LogoFoundProps) {
  const { palette } = useTheme();

  const { classes: wrapperClasses } = logoStyles.useWrapper();

  const foreground = dark ? palette.common.white : '#282132';

  return (
    <div className={`${wrapperClasses.root} ${wrapperClassName}`}>
      <svg
        className={`${wrapperClasses.container} ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        height="28px"
        preserveAspectRatio="xMinYMid slice"
        viewBox="0 0 243 70"
      >
        <title>Found Concrete</title>
        <path
          fill={foreground}
          d="M46.5 27.1l1.7-8.3a11.4 11.4 0 0111-9.2h13.6A12 12 0 0084.5.1H59.3a21 21 0 00-20.4 16.8l-7 34.3a11.4 11.4 0 01-11 9.2 11.2 11.2 0 01-8.7-4.2 11.6 11.6 0 01-2.4-9.6l.1-.7A11.4 11.4 0 0121 36.7h8l2-9.6H21A21 21 0 00.6 44l-.2.7a21.1 21.1 0 0020.4 25.2 21 21 0 0020.5-16.8l3.3-16.4h10.8a12 12 0 0011.7-9.6H46.5z"
        />

        <path
          fill={foreground}
          className={wordLetterClass}
          d="M92 57c-2.4 3.3-5.7 4.9-10.2 4.9-5.3 0-8-3.2-8-9.5-.2-4.4 1-8.7 3.5-12.3 2.3-3.3 5.6-5 10-5 5.4 0 8 3.2 8 9.5 0 5-1.1 9-3.4 12.4zm-3.7-30c-7.8 0-13.8 2.6-17.9 7.7a29 29 0 00-6.2 18.8c0 5 1.5 9 4.4 12 2.9 2.9 7 4.5 12.2 4.5 3.7 0 7.4-.7 10.8-2.2A19 19 0 0099 62c1.9-2.6 3.3-5.4 4.2-8.4 1-3.2 1.5-6.6 1.5-10 0-5-1.5-9-4.3-12s-6.9-4.5-12.2-4.5z"
        />
        <path
          fill={foreground}
          className={wordLetterClass}
          d="M141.2 27.8l-5 24.3c-1.2 6.2-4.7 9.4-10.5 9.4s-8-3.2-6.8-9.4l4.9-24.3h-9.1l-5.3 25.7c-1 4.8-.1 8.8 2.5 11.8 2.5 3 6.7 4.6 12.4 4.6 6 0 10.8-1.5 14.3-4.5a21 21 0 006.6-11.9l5.3-25.7h-9.3z"
        />
        <path
          fill={foreground}
          className={wordLetterClass}
          d="M179.3 27a16.6 16.6 0 00-13.4 6.2h-.3l.3-5.4h-8l-8.5 41.5h9.2l4.6-23c.5-3 2-5.9 4.3-8 2-2 4.7-3 7.5-3 5.6 0 7.8 3 6.6 9.1l-5.1 24.9h9.2l5.5-27.1c1-4.8.4-8.5-1.8-11.1-2.1-2.7-5.5-4-10.1-4"
        />
        <path
          fill={foreground}
          className={wordLetterClass}
          d="M223.1 56.6a11.4 11.4 0 01-10 5.3c-2.7 0-4.7-1-6-2.6-1.4-2-2.1-4.5-2-7 0-2.6.4-5.3 1.3-8 .7-2.5 2.2-4.7 4.2-6.4 2.2-2 5-2.9 7.9-2.8 2.8 0 4.8 1 6.2 2.6 1.4 2 2.1 4.4 2 6.8a21 21 0 01-3.5 12.1zm5.9-23.9h-.3c-1.8-3.7-5.5-5.7-11.1-5.7a19.2 19.2 0 00-16 8 30 30 0 00-6 18.8 18 18 0 003.7 11.6 12 12 0 0010 4.6 16.5 16.5 0 0013-5.7h.3v5h8.2l12.1-59.6h-9.1l-4.8 23z"
        />
      </svg>
    </div>
  );
}

export default LogoFound;
