/**
 * In the event we create a design system
 * it would be nice to have everything in one place
 * to extract into a lib at a later date
 */

import { PaletteOptions } from '@mui/material/styles';

import {
  NeutralColourOne,
  NeutralColourTwo,
  PrimaryColourOne,
  PrimaryColourTwo,
  SecondaryColourFour,
  SecondaryColourOne,
  SecondaryColourThree,
  SecondaryColourTwo,
} from './colorPalette';

// To make these appear in component's props (i.e. "color"); follow this: https://stackoverflow.com/a/71668418/3114826
declare module '@mui/material/styles' {
  // interface Palette {
  //   accent: Palette['primary'];
  //   proceed: Palette['primary'];
  //   standard: Palette['primary'];
  // }
  // interface PaletteOptions {
  //   accent: PaletteOptions['primary'];
  //   proceed: PaletteOptions['primary'];
  //   standard: PaletteOptions['primary'];
  // }

  interface PaletteColor {
    lightest?: string;
    lighter?: string;
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    lightest?: string;
    lighter?: string;
    darker?: string;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
    proceed: true;
    standard: true;
  }
}

// Update the CircularProgress's color prop options
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    accent: true;
    proceed: true;
    standard: true;
  }
}

// Zeplin style guide: https://zpl.io/p1zwPzr
export const light: PaletteOptions = {
  mode: 'light',
  text: {
    primary: PrimaryColourOne.EGGPLANT_900,
    secondary: NeutralColourOne.GREY_500,
    disabled: NeutralColourOne.GREY_400,
  },
  // Primary colour: Found Eggplant
  primary: {
    lightest: PrimaryColourOne.EGGPLANT_600,
    lighter: PrimaryColourOne.EGGPLANT_700,
    light: PrimaryColourOne.EGGPLANT_800,
    main: PrimaryColourOne.EGGPLANT_900,
    dark: PrimaryColourOne.EGGPLANT_900,
    contrastText: NeutralColourTwo.WHITE_900,
  },
  // Primary colour: Found Green
  secondary: {
    lightest: PrimaryColourTwo.GREEN_050,
    lighter: PrimaryColourTwo.GREEN_100,
    light: PrimaryColourTwo.GREEN_200,
    main: PrimaryColourTwo.GREEN_300,
    dark: PrimaryColourTwo.GREEN_400,
    darker: PrimaryColourTwo.GREEN_500,
    contrastText: NeutralColourOne.GREY_900,
  },
  accent: {
    main: 'accent',
    contrastText: 'accent',
  },
  standard: {
    light: 'standard',
    main: 'standard',
    dark: 'standard',
    contrastText: 'standard',
  },
  warning: {
    dark: SecondaryColourOne.YELLOW_800,
    main: SecondaryColourOne.YELLOW_600,
  },
  error: {
    main: SecondaryColourTwo.RED_600,
  },
  success: {
    main: SecondaryColourFour.GREEN_600,
  },
  info: {
    main: SecondaryColourThree.BLUE_600,
    contrastText: SecondaryColourThree.BLUE_600,
  },
  proceed: {
    main: 'procee',
    contrastText: 'proceed',
    light: 'proceed',
  },
  background: {
    default: NeutralColourOne.GREY_050,
    paper: NeutralColourTwo.WHITE_900,
  },
  divider: NeutralColourOne.GREY_300,
  contrastThreshold: 3,
  tonalOffset: 0.2,
  // BASTILLE top
};

// export const dark: PaletteOptions = {
//   ...light,
//   mode: 'dark',
//   primary: {
//     main: ColorPalette.BERMUDA_GREY_1,
//   },
//   text: {
//     ...light.text,
//     primary: ColorPalette.BERMUDA_GREY_1,
//   },
//   background: {
//     default: ColorPalette.BASTILLE,
//     paper: ColorPalette.DARK_CLAY_4,
//   },
// };
