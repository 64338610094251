import tracking from '.';

import { IdentifyField } from './types';

import observeStore from '../../store/observeStore';
import trackingV2 from '../../v2/utils/tracking';
import { profileState$ } from '../../store/profile/selectors';

import { setUser } from '@sentry/react';

import type { AppState } from '../../store/reducer';
import type { Store } from 'redux';
import type { Profile } from 'foundshared/src/api/profile/types';

const userTracking = (store: Store) => {
  const select = (state: AppState): Profile['id'] | undefined => profileState$(state)?.id;
  const selectEmail = (state: AppState): Profile['email'] | undefined => profileState$(state)?.email;

  const onChange = (userId: Profile['id'] | undefined) => {
    const email = selectEmail(store.getState());
    if (userId) {
      tracking.identify({ [IdentifyField.ID]: userId, [IdentifyField.EMAIL]: email || '' });
      trackingV2.identify({ [IdentifyField.ID]: userId, [IdentifyField.EMAIL]: email || '' });
      setUser({
        [IdentifyField.ID]: userId.toString(),
        ...(email ? { [IdentifyField.EMAIL]: email } : {}),
      });
    } else {
      tracking.reset();
      trackingV2.reset();
    }
  };

  observeStore(store, select, onChange);
};

export default userTracking;
