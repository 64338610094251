enum EntityType {
  AVAILABILITY = 'availabilities',
  CATALOGUES = 'catalogues',
  INVOICES = 'invoiceSummaries',
  LOCATIONS = 'locations',
  OPTION_GROUPS = 'optionGroups',
  OPTIONS = 'options',
  ORDERS = 'orders',
  PRODUCT_TYPES = 'productTypes',
  PROFILE = 'profile',
  QUOTES = 'quotes',
  SERVICE_AREAS = 'serviceAreas',
  SESSIONS = 'sessions',
  SETTINGS = 'settings',
  SUGGESTIONS = 'suggestions',
  SUPPLIERS = 'suppliers',
  VALUES = 'values',
  WALLET = 'wallet',
}

export default EntityType;
