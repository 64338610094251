import type { OrderAppliedDiscountFees, OrderCatalogueResponse, OrderResponse } from 'foundshared/src/api/orders/types';

export enum OrderActionTypes {
  FETCH_PERFORM = '@@orders/FETCH_PERFORM',
  FETCH_PERFORM_FIRST = '@@orders/FETCH_PERFORM_FIRST',
  FETCH_SUCCESS = '@@orders/FETCH_SUCCESS',
  FETCH_SUCCESS_FIRST = '@@orders/FETCH_SUCCESS_FIRST',
  FETCH_ERROR = '@@orders/FETCH_ERROR',

  START_POLLING = '@@orders/START_POLLING',
  STOP_POLLING = '@@orders/STOP_POLLING',

  CREATE_PERFORM = '@@orders/CREATE_PERFORM',
  CREATE_SUCCESS = '@@orders/CREATE_SUCCESS',
  CREATE_ERROR = '@@orders/CREATE_ERROR',

  UPDATE_PERFORM = '@@orders/UPDATE_PERFORM',
  UPDATE_SUCCESS = '@@orders/UPDATE_SUCCESS',
  UPDATE_ERROR = '@@orders/UPDATE_ERROR',

  DELETE_PERFORM = '@@orders/DELETE_PERFORM',
  DELETE_SUCCESS = '@@orders/DELETE_SUCCESS',
  DELETE_ERROR = '@@orders/DELETE_ERROR',

  LOAD_PERFORM = '@@orders/LOAD_PERFORM',
  LOAD_SUCCESS = '@@orders/LOAD_SUCCESS',
  LOAD_ERROR = '@@orders/LOAD_ERROR',
}

export enum CartActionTypes {
  FETCH_CATALOGUE_PERFORM = '@@cart/FETCH_CATALOGUE_PERFORM',
  FETCH_CATALOGUE_SUCCESS = '@@cart/FETCH_CATALOGUE_SUCCESS',
  FETCH_CATALOGUE_ERROR = '@@cart/FETCH_CATALOGUE_ERROR',
  FETCH_CATALOGUE_RESET = '@@cart/FETCH_CATALOGUE_RESET',
}

export type OrderCatalogue = Omit<OrderCatalogueResponse, 'productType' | 'productTypes'> & {
  productType: string;
  productTypes: string[];
};

export type Order = Omit<OrderResponse, 'catalogue' | 'invoiceSummaries'> & {
  catalogue: OrderCatalogue;
  catalogueBack: OrderCatalogueResponse;
  invoiceSummaries: string[];
  appliedDiscounts: OrderAppliedDiscountFees[];
};

export type OrderAppliedFeesList = OrderAppliedDiscountFees[];

export type Orders = Order[];
