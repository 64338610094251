import EntityType from '../../utils/entityType';

import { schema } from 'normalizr';

import type { SettingResponse, SettingsResponse } from 'foundshared/src/api/settings/types';


export const settingEntitySchema = new schema.Entity<SettingResponse>(EntityType.SETTINGS);

export const settingsCollectionSchema = new schema.Array<SettingsResponse>(settingEntitySchema);
