import { ProfileActionTypes } from './types';

import { createReducer } from 'typesafe-actions';

import type { ActionType} from 'typesafe-actions';
import type { Profile } from 'foundshared/src/api/profile/types';
import type {
  fetchProfileError,
  fetchProfileSuccess,
  updateCustomerError,
  updateCustomerSuccess,
  updateError,
  updateSuccess,
} from './actions';

type ActionTypes =
  | ActionType<typeof fetchProfileSuccess>
  | ActionType<typeof fetchProfileError>
  | ActionType<typeof updateSuccess>
  | ActionType<typeof updateError>
  | ActionType<typeof updateCustomerSuccess>
  | ActionType<typeof updateCustomerError>;

export type InitialProfileState = Partial<Profile>;

const profileReducer = createReducer<InitialProfileState | Profile, ActionTypes>({})
  .handleType(ProfileActionTypes.FETCH_SUCCESS, (_state, { payload }) => payload.result)
  .handleType(ProfileActionTypes.FETCH_ERROR, (state) => state)
  .handleType(ProfileActionTypes.UPDATE_SUCCESS, (_state, { payload }) => payload.result)
  .handleType(ProfileActionTypes.UPDATE_ERROR, (state) => state)
  .handleType(ProfileActionTypes.CUSTOMER_UPDATE_SUCCESS, (state, { payload }) => ({ ...state, customer: payload }))
  .handleType(ProfileActionTypes.CUSTOMER_UPDATE_ERROR, (state) => state);

export default profileReducer;
