import CircularProgress from '@mui/material/CircularProgress';
import Link from 'next/link';
import MuiButton, { ButtonProps as MuiButtonProps, ButtonTypeMap } from '@mui/material/Button';
import React, { ReactNode, forwardRef } from 'react';
import { ExtendButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export interface ButtonProps extends MuiButtonProps {
  component?: ReactNode;
  loading?: boolean;
  colorType?: string;
  /** Used alongside 'href' prop - will run Next.js link in shallow mode */
  shallowLink?: boolean;
}

interface LinkWrapperProps {
  href?: string;
  children: ReactNode;
  /** Used alongside 'href' prop - will run Next.js link in shallow mode */
  shallowLink: boolean | undefined;
}

const LinkWrapper = ({ href, children, shallowLink }: LinkWrapperProps) =>
  href ? (
    <Link shallow={shallowLink} passHref href={href}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

function Button({ loading, children, colorType, href, shallowLink, ...props }: ButtonProps, ref: any) {
  const { classes } = useStyles();

  return (
    <LinkWrapper shallowLink={shallowLink} href={href}>
      <MuiButton component={href ? 'a' : 'button'} ref={ref} disabled={loading} {...props}>
        {children}
        {!!loading && (
          <CircularProgress
            color={colorType === 'dark' ? 'inherit' : 'primary'}
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </MuiButton>
    </LinkWrapper>
  );
}

export const CustomButton = forwardRef(Button) as ExtendButtonBase<ButtonTypeMap<ButtonProps>>;

export default CustomButton;
