import { AccountField } from '../../../utils/forms';
import { SessionActionTypes } from '../types';
import { addError, addSuccess } from '../../notifications/actions';
import { createSessionPerform, signupError, signupSuccess } from '../actions';
import { fetchProfilePerform } from '../../profile/actions';
import { isAxiosError } from '../../handleError';
import { apiActions } from '../../../api';

import { captureException } from '@sentry/react';
import { call, put, takeLatest } from 'typed-redux-saga';

import type { signupOrSigninPerform } from '../actions';
import type { ApiError } from '../../../api/types';
import type { AxiosError } from 'axios';

// / ///////////////////////////  SIGNUP OR SIGNIN  //////////////////////////////
function* handleSignupError(
  error: Error | AxiosError<ApiError>,
  { payload, meta }: ReturnType<typeof signupOrSigninPerform>
) {
  if (isAxiosError(error) && (error?.response?.data?.errors || error?.response?.data?.validationMessages)) {
    const { errors, errorMessages, validationMessages } = error.response.data;
    const combinedError = errors ? errors[0] : validationMessages;

    if (typeof combinedError === 'object') {
      const validationErrors = combinedError;

      if (validationErrors.mobile?.includes('has already been taken')) {
        if (payload.key && payload.mobile) {
          const sessionPayload = {
            [AccountField.MOBILE]: payload.mobile,
            [AccountField.VERIFICATION_CODE]: payload.key,
          };
          yield* put(createSessionPerform(sessionPayload, meta));
        }

        yield* put(addSuccess("Your mobile number was already registered, so you've been signed in."));

        const signupSuccessful = true;

        if (meta?.callback) meta.callback(signupSuccessful);
        if (meta?.trackingCallback) {
          meta.trackingCallback(false);
        }
      } else if (validationErrors.email?.includes('has already been taken')) {
        const errorMessage = 'Email has already been taken';
        const sentryId = captureException(error);
        yield* put(addError(errorMessage, { sentryId }));
      } else {
        const errorMessage = errorMessages?.length ? errorMessages.join(', ') : combinedError.join(', ');
        const sentryId = captureException(error);
        yield* put(addError(errorMessage, { sentryId }));
      }
    } else {
      const sentryId = captureException(error);
      yield* put(addError(combinedError.join(', '), { sentryId }));
    }
  } else {
    const sentryId = captureException(error);
    yield* put(addError('There was a problem signing up', { sentryId }));
  }
}

function* signupOrSigninInstanceSaga({ payload, meta, ...action }: ReturnType<typeof signupOrSigninPerform>) {
  try {
    const result = yield* call(apiActions.signup.signup, payload);

    yield* put(signupSuccess(result.right));
    yield* put(fetchProfilePerform({ callback: meta?.profileCallback }));

    const signupSuccessful = true;
    if (meta?.callback) meta?.callback(signupSuccessful);
    if (meta?.trackingCallback) {
      meta.trackingCallback(true);
    }
  } catch (error) {
    if (error instanceof Error) {
      yield* put(signupError(error));
      yield* handleSignupError(error, { payload, meta, ...action });
    }
  }
}

export function* signupOrSigninSaga() {
  yield* takeLatest(SessionActionTypes.SIGNUP_OR_SIGNIN_PERFORM, signupOrSigninInstanceSaga);
}
