import { ActionType, createReducer } from 'typesafe-actions';

import { CartActionTypes } from './types';
import { FreshCatalogue } from '../catalogues/types';
import { OrderContextData } from '../../components/OrderBuilder/utils/formState';
import {
  clearCart,
  fetchCatalogueError,
  fetchCataloguePerform,
  fetchCatalogueSoonSuccess,
  fetchCatalogueSuccess,
  resetCatalogue,
  updateCart,
  updateCatalogueCart,
} from './actions';

export type CartState = {
  /** User order builder choices */
  order: OrderContextData | null;
  /** Only ever a fresh catalogue, existing order catlogues are delt within another context */
  id: FreshCatalogue['id'] | null;
  /** Fresh catalogues */
  catalogue: FreshCatalogue | Omit<FreshCatalogue, 'id' | 'expiresAt' | 'createdAt'> | null;
  /** Temp mark to see if the suburb is coming soon, see FOUND-119 */
  soon: boolean;
};

export const initialCartState: CartState = {
  catalogue: null,
  id: null,
  order: null,
  soon: false,
};

type ActionTypes =
  | ActionType<typeof updateCart>
  | ActionType<typeof clearCart>
  | ActionType<typeof fetchCataloguePerform>
  | ActionType<typeof fetchCatalogueSuccess>
  | ActionType<typeof fetchCatalogueSoonSuccess>
  | ActionType<typeof fetchCatalogueError>
  | ActionType<typeof resetCatalogue>
  | ActionType<typeof updateCatalogueCart>;

export default createReducer<CartState, ActionTypes>(initialCartState)
  .handleType(CartActionTypes.UPDATE_CART, (state, { payload }) => ({
    ...state,
    order: payload,
  }))
  // Keep the catalogue to avoid destorying the form.
  .handleType(CartActionTypes.CLEAR_CART, (state) => ({ ...initialCartState, catalogue: state.catalogue }))
  .handleType(
    CartActionTypes.FETCH_CATALOGUE_SUCCESS,
    (state, action: ActionType<typeof fetchCatalogueSuccess | typeof fetchCatalogueSoonSuccess>) => {
      if ('payload' in action && action.payload) {
        const catalogue = action.payload.result;

        if (!catalogue) throw new Error('There must always be a catalogue here!');

        return { ...state, id: catalogue.id, catalogue, soon: false };
      }
      // TODO: Temporary see FOUND-119
      return { ...state, id: null, soon: true };
    }
  )
  .handleType(CartActionTypes.FETCH_CATALOGUE_ERROR, (state) => ({ ...state, id: null, soon: false }))
  .handleType(CartActionTypes.FETCH_CATALOGUE_RESET, (state) => ({ ...state, id: null, soon: false }));
