
import cartReducer from './cart/reducer';
import cataloguesReducer from './catalogues/reducer';
import entityReducer from './entities/reducer';
import errorsReducer from './errors/reducer';
import featureTogglesReducer from './features/reducer';
import loadingReducer from './loading/reducer';
import notificationReducer from './notifications/reducer';
import ordersReducer from './orders/reducer';
import plusLoadsReducer from './plusLoads/reducer';
import pricingReducer from './pricing/reducer';
import profileReducer from './profile/reducer';
import quotesReducer from './quotes/reducer';
import referralsReducer from './referrals/reducer';
import serviceAreaReducer from './serviceAreas/reducer';
import sessionReducer from './session/reducer';
import settingsReducer from './settings/reducer';
import submittingReducer from './submitting/reducer';
import { SessionActionTypes } from './session/types';

import serviceAreaReducerV2 from '../v2/store/serviceAreas/reducer';

import { combineReducers } from 'redux';

import type { StateType } from 'typesafe-actions';

const allReducers = {
  cart: cartReducer,
  catalogues: cataloguesReducer,
  entities: entityReducer,
  errors: errorsReducer,
  featureToggles: featureTogglesReducer,
  loading: loadingReducer,
  notifications: notificationReducer,
  orders: ordersReducer,
  plusLoads: plusLoadsReducer,
  pricing: pricingReducer,
  profile: profileReducer,
  quotes: quotesReducer,
  referrals: referralsReducer,
  serviceAreas: serviceAreaReducer,
  session: sessionReducer,
  submitting: submittingReducer,
  settings: settingsReducer,
  // WIP v2:
  serviceAreasV2: serviceAreaReducerV2,
};

const combinedReducer = combineReducers(allReducers);

function crossSliceReducer(state: StateType<typeof allReducers>, action: Parameters<typeof combinedReducer>[1]) {
  switch (action.type) {
    /**
     * When sign out action fires; wipe the store but keep the feature toggle slice intact.
     */
    case SessionActionTypes.DELETE_SUCCESS: {
      return {
        ...combineReducers(allReducers)(undefined, action),
        features: featureTogglesReducer(state.featureToggles, action),
      };
    }
    default:
      return state;
  }
}

const rootReducer = (state: Parameters<typeof combinedReducer>[0], action: Parameters<typeof combinedReducer>[1]) => {
  const intermediateState = combinedReducer(state, action);
  return crossSliceReducer(intermediateState, action);
};

export type AppState = StateType<typeof rootReducer>;

export default rootReducer;
