import { useRouter } from 'next/router';

/** ParsedUrlQuery can return an array or a string, this hook ensures we get back a string */
function useQueryString(queryString: string) {
  const { query: queries } = useRouter();

  const { [queryString]: query } = queries;

  return Array.isArray(query) ? query[0] : query || undefined;
}

export default useQueryString;
