export enum ProfileActionTypes {
  FETCH_PERFORM = '@@profile/FETCH_PERFORM',
  FETCH_SUCCESS = '@@profile/FETCH_SUCCESS',
  FETCH_ERROR = '@@profile/FETCH_ERROR',
  UPDATE_PERFORM = '@@profile/UPDATE_PERFORM',
  UPDATE_SUCCESS = '@@profile/UPDATE_SUCCESS',
  UPDATE_ERROR = '@@profile/UPDATE_ERROR',
  CUSTOMER_UPDATE_PERFORM = '@@profile/CUSTOMER_UPDATE_PERFORM',
  CUSTOMER_UPDATE_SUCCESS = '@@profile/CUSTOMER_UPDATE_SUCCESS',
  CUSTOMER_UPDATE_ERROR = '@@profile/CUSTOMER_UPDATE_ERROR',
}
