import * as sharedInterceptors from 'foundshared/src/api/interceptors';

import type { ApiErrorData } from 'foundshared/src/api/types';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export function requestInterceptor(config: AxiosRequestConfig) {
  return sharedInterceptors.requestInterceptor(config);
}

export function responseInterceptor(config: AxiosResponse<any>) {
  return sharedInterceptors.reponseInterceptor(config);
}

export function responseErrorInterceptor(errorConfig: AxiosError<ApiErrorData>) {
  const error = sharedInterceptors.reponseErrorInterceptor(errorConfig);

  const sendApiItem = {
    ResponsieTime: new Date().getTime(),
    Status: error?.response?.status,
    RequestData: error?.response?.config?.data,
    ResponseData: error?.response?.data,
  };

  window.analytics?.track('BFF Api Error', { bffError: sendApiItem });
  return Promise.reject(error);
}
