import { PrivateRoutePath, PublicRoutePath } from '../../../utils/routes';
import { TrackingLinkEvent } from '../../../utils/tracking/types';

export interface LinkProps {
  id: string;
  to?: string;
  href?: string;
  onClick?: () => void;
  exact?: boolean;
  primary: string;
  public: boolean;
  trackingLink?: TrackingLinkEvent;
}

const faqPage: LinkProps = {
  id: 'nav-content-faq',
  href: PublicRoutePath.HOW_IT_WORKS,
  primary: 'How it works',
  public: true,
  trackingLink: TrackingLinkEvent.VIEW_FAQS,
};

const contactPage: LinkProps = {
  id: 'nav-content-contact',
  href: PublicRoutePath.WHO_WE_ARE,
  primary: 'Who we are',
  public: true,
  trackingLink: TrackingLinkEvent.VIEW_CONTACT_US,
};

// - Unauth web ordering pages
const homePage: LinkProps = {
  id: 'nav-new-pour',
  to: PublicRoutePath.HOME,
  primary: 'Book concrete',
  public: true,
  exact: true,
};

const signinPage: LinkProps = {
  id: 'nav-signin',
  to: PublicRoutePath.SIGNIN,
  exact: true,
  primary: 'Sign in',
  public: true,
};

// - Auth web ordering pages

const profilePage: LinkProps = {
  id: 'nav-profile',
  to: PrivateRoutePath.PROFILE,
  exact: true,
  primary: 'Profile',
  public: false,
};

const ordersPage: LinkProps = {
  id: 'nav-orders',
  to: PrivateRoutePath.ORDERS,
  exact: true,
  primary: 'Orders',
  public: false,
};

export const unauthLinks: Array<LinkProps> = [faqPage, contactPage, signinPage];

export const authLinks: Array<LinkProps> = [homePage, ordersPage, profilePage, faqPage, contactPage];
