import { PlusLoadsActionTypes } from './types';

import { createReducer } from 'typesafe-actions';

import type { ActionType } from 'typesafe-actions';
import type { PlusLoad } from 'foundshared/src/api/plusLoads/codecs';
import type {
  requestPlusLoadsError,
  requestPlusLoadsFinished,
  submitPlusLoadFinished,
  submitPlusLoadError,
} from './actions';

type ActionTypes =
  | ActionType<typeof requestPlusLoadsFinished>
  | ActionType<typeof requestPlusLoadsError>
  | ActionType<typeof submitPlusLoadFinished>
  | ActionType<typeof submitPlusLoadError>;

export type InitialPlusLoadState = Partial<PlusLoad>[];

const initialState: InitialPlusLoadState = [];

export default createReducer<InitialPlusLoadState, ActionTypes>(initialState)
  .handleType(PlusLoadsActionTypes.REQUEST_PLUS_LOADS_FINISHED, (_state, { payload }) => payload)
  .handleType(PlusLoadsActionTypes.REQUEST_PLUS_LOAD_SUBMISSION_FINISHED, (state, { payload }) => [...state, payload]);
