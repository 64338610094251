import { ThemeOptions } from '@mui/material/styles';

const cssBaseline: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        '&::WebkitScrollbar': {
          display: 'none',
        },
      },
      html: {
        WebkitFontSmoothing: 'auto',
      },
      'html, body': {
        margin: 0,
        height: '100%',
        fontFamily: "'IBM Plex Sans', sans-serif",
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        MsOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none',
        backgroundColor: '#fff',
        scrollBehavior: 'smooth',
      },
      'html, body, #__next': {
        height: '100%',
      },
      "input[type='number']": {
        '&::WebkitInnerSpinButton, &::WebkitOuterSpinButton': {
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          margin: 0,
        },
      },
      iframe: {
        border: '1px solid black',
        width: '100%',
        minHeight: '400px !important',
      },
      '@font-face': [
        {
          fontFamily: 'Articulat CF',
          fontStyle: 'normal',
          fontWeight: 600,
          fontDisplay: 'swap',
          src: "local('Articulac CF'), local('Articulat-CF'), url(./fonts/Articulat CF Bold.otf), format('otf')",
        },
        {
          fontFamily: 'Articulat CF',
          fontStyle: 'normal',
          fontWeight: 500,
          fontDisplay: 'swap',
          src: "local('Articulac CF'), local('Articulat-CF'), url(./fonts/Articulat CF Medium.otf), format('otf')",
        },
        {
          fontFamily: 'Articulat CF',
          fontStyle: 'normal',
          fontWeight: 700,
          fontDisplay: 'swap',
          src: "local('Articulac CF'), local('Articulat-CF'), url(./fonts/Articulat CF Extra Bold.otf), format('otf')",
        },
        {
          fontFamily: 'Articulat CF',
          fontStyle: 'normal',
          fontWeight: 400,
          fontDisplay: 'swap',
          src: "local('Articulac CF'), local('Articulat-CF'), url(./fonts/Articulat CF Normal.woff2), format('otf')",
        },
        {
          fontFamily: 'Articulat CF',
          fontStyle: 'normal',
          fontWeight: 300,
          fontDisplay: 'swap',
          src: "local('Articulac CF'), local('Articulat-CF'), url(./fonts/Articulat CF Thin.otf), format('otf')",
        },
      ],
      fieldset: {
        border: 'none',
        padding: 0,
        margin: 0,
      },
      address: {
        fontStyle: 'normal',
      },
      a: {
        textDecoration: 'none',
      },
    },
  },
};

export default cssBaseline;
