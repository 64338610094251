import EntityType from '../../utils/entityType';

import { schema } from 'normalizr';

import type { OrderInvoiceSummariesResponse, OrderResponse } from 'foundshared/src/api/orders/types';
import type { Supplier } from 'foundshared/src/api/suppliers/types';


export const invoiceSummariesTypeEntitySchema = new schema.Entity<OrderResponse>(EntityType.INVOICES);

export const invoicesCollectionEntitySchema = new schema.Array<OrderInvoiceSummariesResponse>(
  invoiceSummariesTypeEntitySchema
);

export const supplierEntitySchema = new schema.Entity<Supplier>(EntityType.SUPPLIERS);
export const supplierCollectionSchema = new schema.Array<Supplier>(supplierEntitySchema);
