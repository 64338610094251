
import cartSagas from './cart/sagas';
import entitiesSagas from './entities/sagas';
import featuresSagas from './features/sagas';
import ordersSagas from './orders/sagas';
import plusLoadSagas from './plusLoads/sagas';
import pricingSagas from './pricing/sagas';
import profileSagas from './profile/sagas';
import quoteSagas from './quotes/sagas';
import referralsSagas from './referrals/sagas';
import serviceAreaSagas from './serviceAreas/sagas';
import sessionSagas from './session/sagas';
import settingsSagas from './settings/sagas';

import v2Sagas from '../v2/store/sagas';

import { all } from 'typed-redux-saga';

function* rootSaga() {
  yield* all([
    ...cartSagas,
    ...entitiesSagas,
    ...featuresSagas,
    ...ordersSagas,
    ...plusLoadSagas,
    ...pricingSagas,
    ...profileSagas,
    ...quoteSagas,
    ...referralsSagas,
    ...serviceAreaSagas,
    ...sessionSagas,
    ...settingsSagas,
    ...v2Sagas,
  ]);
}

export default rootSaga;
