import {
  PricingEntity
} from 'foundshared/src/api/pricing/types';
import { schema } from 'normalizr';

import type {
  PricingRegionResponse,
  PricingRegionTierResponse,
  PricingRegionsResponse} from 'foundshared/src/api/pricing/types';

export const pricingRegionTierEntitySchema = new schema.Entity<PricingRegionTierResponse>(
  PricingEntity.TIERS,
  undefined,
  {
    // The 'id' entity passed is arbitrary and unusable to the frontend and we cannot use it to match other endpoints.
    idAttribute: (value: PricingRegionTierResponse, parent: PricingRegionResponse) =>
      `${parent.id}-${value.pricingTierId}`,
  }
);

export const pricingRegionEntitySchema = new schema.Entity(PricingEntity.REGIONS, {
  pricingRegionTiers: [pricingRegionTierEntitySchema],
});

export const pricingRegionCollectionSchema = new schema.Array<PricingRegionsResponse>(pricingRegionEntitySchema);
