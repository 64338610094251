import { alpha } from '@mui/material';

import type { ThemeOptions } from '@mui/material/styles';

const dialogs: ThemeOptions['components'] = {
  MuiModal: {
    defaultProps: {
      /**
       * Hack to get prevent our modals from stealing focus away from an open Appcues modal.
       *
       * @remarks See FOUND-549
       */
      disableEnforceFocus: true,
    },
  },
  MuiDialog: {
    defaultProps: {
      maxWidth: 'xs',
    },
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.lighter,
        paddingBottom: theme.spacing(1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          maxWidth: 460,
        },
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(2),
        },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: theme.spacing(0, 3, 3, 3),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(0, 2, 2, 2),
        },
      }),
      spacing: ({ theme }) => ({
        '&> :not(button:nth-of-type(1), a:nth-of-type(1))': {
          margin: 0,
          marginTop: theme.spacing(1),
        },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        textAlign: 'left',
        padding: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2),
        },
      }),
    },
  },
  MuiDialogContentText: {
    defaultProps: {
      variant: 'h6',
      color: 'textPrimary',
    },
    styleOverrides: {
      root: {
        marginBottom: 0,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.h4,
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: theme.spacing(2.5, 3, 2),
        marginBottom: theme.spacing(2),
      }),
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: alpha(theme.palette.primary.main, 0.75),
      }),
    },
  },
};

export default dialogs;
