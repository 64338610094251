import { FeatureToggleActionTypes } from './constants';

import { action } from 'typesafe-actions';

import type { FeatureToggleCollection } from 'foundshared/src/api/features/codecs';

export const fetchPerform = () => action(FeatureToggleActionTypes.FETCH_PERFORM, {});

export const fetchSuccess = (data: FeatureToggleCollection) => action(FeatureToggleActionTypes.FETCH_SUCCESS, data);

export const fetchError = (error: Error) => action(FeatureToggleActionTypes.FETCH_ERROR, error);
