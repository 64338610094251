import * as R from 'ramda';
import memoize from 'lodash/memoize';
import values from 'lodash/values';
import { createSelector, defaultMemoize } from 'reselect';

import { AppState } from '../reducer';
import { Option, OptionGroup, OptionValue } from './types';

export enum OptionGroupName {
  MIX = 'Mix',
  VOLUME = 'Volume',
  DELIVERY = 'Delivery',
  DATE_AND_TIME = 'DateAndTime',
}

export enum OptionName {
  // OptionGroupName.MIX
  MPA = 'mpa',
  COLOUR = 'colour',
  // OptionGroupName.VOLUME
  QUANTITY = 'quantity',
  // OptionGroupName.DELIVERY
  PLACEMENT = 'placement',
  TRUCK_SPACING = 'truck_spacing',
  TRUCK_SIZE = 'truck_size',
  ONSITE_PERSON_NAME = 'onsite_person_name',
  SUPPLIER_NOTES = 'supplier_notes',
  // OptionGroupName.DATE_AND_TIME
  DELIVER_AT = 'deliver_at',
  ADD_MESSAGE = 'add_message',
}

export const cataloguesState = (state: AppState) => state.catalogues;

export const productTypeEntities$ = createSelector([cataloguesState], (state) => state.productTypes);

export const optionGroupEntities$ = createSelector([cataloguesState], (state) => state.optionGroups);

export const optionEntities$ = createSelector([cataloguesState], (state) => state.options);

export const optionValueEntities$ = createSelector([cataloguesState], (state) => state.values);

export const supplierEntities$ = (state: AppState) => state.entities.suppliers;

export const suppliers$ = createSelector([supplierEntities$], (entities) => Object.values(entities));

export const supplierById$ = defaultMemoize((id?: number | null) =>
  createSelector([supplierEntities$], (entities) => {
    const emptySupplier = {
      id: 0,
      slug: '',
      name: 'No Preference',
      active: false,
      logo: '',
    };

    if (!id) {
      return emptySupplier;
    }

    return entities[id] || emptySupplier;
  })
);

export const getPriceOfOptionValue = (productOptionValue: OptionValue) => {
  if (typeof productOptionValue.price === 'number') {
    return productOptionValue.price;
  }

  return R.pathOr(0, ['price', 'amount'], productOptionValue);
};

export const productTypeCollection$ = createSelector([productTypeEntities$], (entities) =>
  values(entities).sort(
    ({ sortKey = '' }, { sortKey: sortKeyB = '' }) => +(sortKey > sortKeyB) || -(sortKey < sortKeyB)
  )
);

export const optionValuesByIds$ = createSelector(optionValueEntities$, (entities) =>
  memoize((ids: string[]) => ids.map((id) => entities?.[id]).filter(Boolean))
);

export type OptionGroupByProductType = Omit<OptionGroup, 'options'> & { options: Option[] };
export type OptionGroupsByProductTypes = Record<number, { [key in OptionGroupName]: OptionGroupByProductType }>;
