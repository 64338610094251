import { runSaga } from 'redux-saga';

import { createSessionInstanceSaga } from '../store/session/sagas';
import { createSessionPerform } from '../store/session/actions';
import { store } from '../store';

// This function is used by the e2e system to sign in without going through
// the process of clicking around. It does not present any 'back door' or
// securit risk.
export function signIn(mobile: string, verificationCode: string) {
  return runSaga(
    store,
    createSessionInstanceSaga,
    createSessionPerform({
      mobile,
      verificationCode,
    })
  ).toPromise();
}
