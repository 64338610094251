import * as schemas from './schema';
import { QuoteActionTypes } from './constants';
import {
  createQuoteError,
  createQuoteSuccess,
  deleteQuoteError,
  deleteQuoteSuccess,
  fetchQuotesError,
  fetchQuotesSuccess,
  loadError,
  loadSuccess,
} from './actions';

import handleError from '../handleError';
import tracking from '../../utils/tracking';
import { TrackingEvent } from '../../utils/tracking/types';
import { addSuccess } from '../notifications/actions';
import { apiActions } from '../../api';

import { call, put, spawn, takeLatest } from 'typed-redux-saga';
import { normalize } from 'normalizr';

import type { QuoteResponse, QuotesResponse } from 'foundshared/src/api/quotes/types';
import type { createQuotePerform, deleteQuotePerform, fetchQuotesPerform, loadPerform } from './actions';

export function* quotesInstanceSaga(action: ReturnType<typeof fetchQuotesPerform>) {
  try {
    const result = yield* call(apiActions.quotes.fetchQuotes, false);
    const normalisedResult = normalize<QuotesResponse>(result.right, schemas.quoteCollectionSchema);

    yield* put(fetchQuotesSuccess(normalisedResult, action.meta));

    if (action.meta?.callback) action.meta.callback();
  } catch (error: any) {
    yield* handleError(error, put, function* () {
      yield* put(fetchQuotesError(error.message));
    });
  }
}

function* quoteInstanceSaga(action: ReturnType<typeof loadPerform>) {
  const { id } = action.payload;
  try {
    const result = yield* call(apiActions.quotes.fetchQuote, id);
    const normalisedResult = normalize<QuoteResponse>(result.right, schemas.quoteEntitySchema);

    yield* put(loadSuccess(normalisedResult.entities));
  } catch (error: any) {
    yield* handleError(error, put, function* () {
      yield* put(loadError(error.message));
    });
  }
}

function* createQuoteInstanceSaga(action: ReturnType<typeof createQuotePerform>) {
  try {
    const result = yield* call(apiActions.quotes.createQuote, action.payload);
    const normalisedResult = normalize<QuoteResponse>(result.right, schemas.quoteEntitySchema);

    window.localStorage.setItem('quotePayload', '');

    if (normalisedResult.entities) {
      tracking.event(TrackingEvent.NEW_QUOTE_SUBMITTED);

      yield* put(createQuoteSuccess(normalisedResult, action.meta));

      const id = Object.keys(normalisedResult.entities.quotes!)[0];
      if (action.meta?.callback) action.meta.callback(id);
    }
  } catch (error: any) {
    yield* handleError(error, put, function* () {
      yield* put(createQuoteError(error.message));
    });
  }
}

function* deleteQuoteInstanceSaga(action: ReturnType<typeof deleteQuotePerform>) {
  try {
    const { id } = action.payload;

    const result = yield* call(apiActions.quotes.deleteQuote, id);

    // Result expected back from the endpoint is just "true"
    if (result.right)
      // Fire the callback first, so the view can navigate away before the quote is removed from the store.
      action.meta?.callback();

    // Removes the quote from the store.
    yield* put(deleteQuoteSuccess(id));

    // Inform the user of the successful removal
    yield* put(addSuccess('Quote removed.'));
  } catch (error: any) {
    yield* handleError(error, put, function* () {
      yield* put(deleteQuoteError(error.message));
    });
  }
}

// / /////////////////////////////////  END //////////////////////////////////////

export function* fetchQuotesSaga() {
  yield* takeLatest(QuoteActionTypes.FETCH_PERFORM, quotesInstanceSaga);
}

export function* createQuoteSaga() {
  yield* takeLatest(QuoteActionTypes.CREATE_PERFORM, createQuoteInstanceSaga);
}

export function* deleteQuoteSaga() {
  yield* takeLatest(QuoteActionTypes.DELETE_PERFORM, deleteQuoteInstanceSaga);
}

export function* loadQuoteSaga() {
  yield* takeLatest(QuoteActionTypes.LOAD_PERFORM, quoteInstanceSaga);
}

const spawnedAppSagas = [spawn(createQuoteSaga), spawn(fetchQuotesSaga), spawn(loadQuoteSaga), spawn(deleteQuoteSaga)];

export default spawnedAppSagas;
