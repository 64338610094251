// Private Routes
// FIXME: Some of these routes no longer exist
export enum PrivateRoutePath {
  CASH = '/app/concrete-cash',
  CREDIT_ACCOUNT = '/app/credit-account',
  EDIT_ORDER = '/app/orders/[id]/edit/:step',
  NEW_ORDER = '/app/orders/new',
  NEW_PUBLIC_ORDER = '/orders/new/:step',
  ORDER_SUMMARY = '/app/orders/[id]/summary',
  ORDERS = '/app/orders',
  PROFILE = '/app/profile',
  TRADE_BUSINESS_WELCOME = '/app/trade-business-welcome',
  BILLING = '/app/billing',
  REFERRALS = '/app/referrals',
  QUESTIONS = '/app/pricing-questions',
  PROFILE_PRICING_PLAN = '/app/profile/pricing-plan',
}

// Public routes
// FIXME: Some of these routes no longer exist
export enum PublicRoutePath {
  AVAILABILITY = '/check-availability',
  HOME = '/',
  HOW_IT_WORKS = 'https://hello.foundconcrete.com.au/how-it-works',
  LOADING = '/loading',
  NEW_ORDER = '/orders/new',
  ORDERS = '/orders',
  QUOTE_PROCESS = '/get-a-quote',
  QUOTE_PROCESS_LOGIN = '/get-a-quote/details',
  REFER = '/refer/:slug',
  SIGNIN = '/signin',
  SIGNUP = '/signup',
  TRADE_DISCOUNT = '/trade-discount',
  WHO_WE_ARE = 'https://hello.foundconcrete.com.au/who-we-are',
  DISASTER_NOTIFICATION = '/app/notification',
}

export enum PageHeadTitle {
  MAIN = 'Found Concrete',
  SIGNIN = 'Sign-in',
  SIGNUP = 'Sign-up',
  TRADE_BUSINESS_WELCOME = 'Trade business',
  TRADE_DISCOUNT = 'Trade pricing',
  ORDERS = 'Orders',
  NEW_ORDER = 'New order',
  ORDER_SUMMARY = 'Submitting order',
  PROFILE = 'Profile',
  BILLING = 'Billing',
  CREDIT_ACCOUNT = 'Credit account',
  REFERRALS = 'Referrals',
  SIGNUP_REFERRED = `Get credit to buy concrete`,
  NOT_FOUND = '404 - Page not found',
  AVAILABILITY = 'Availability',
  LOADING = 'Loading',
  NEW_QUOTE = 'New quote',
}

// FIXME: Some of these routes no longer exist
export enum ExternalLink {
  SERVICE_FEES = 'https://hello.foundconcrete.com.au/terms/#service-fees',
  PRIVACY = 'https://hello.foundconcrete.com.au/privacy',
  TERMS = 'https://hello.foundconcrete.com.au/terms',
  FAQS = 'https://hello.foundconcrete.com.au/about',
  REFERRED = 'https://hello.foundconcrete.com.au/refer-a-tradie',
  REFERRED_CONDITION = '/app/referrals',
  ONETHREEHUNDRED = 'tel:1300980626',
  EMAIL = 'mailto:hello@foundconcrete.com.au',
  TRADIE_HUB = 'https://hello.foundconcrete.com.au/tradie-hub',
  CONTACT_US = 'https://hello.foundconcrete.com.au/contact-us',
  REVIEWS = 'https://hello.foundconcrete.com.au/reviews',
  PRICING = 'https://hello.foundconcrete.com.au/pricing',
  PARTNER_PROGRAM = 'https://hello.foundconcrete.com.au/partner-program',
}

export enum ExternalDomainLink {
  SUPPORT = 'https://support.foundconcrete.com.au/knowledge',
  EXPRESSION_OF_INTEREST = 'https://foundconcrete.typeform.com/to/dLNkaHlf',
  APP_STORE = 'https://apps.apple.com/au/app/pour/id1473090196',
  PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.pour.pour',
  TYPEFORM = 'https://foundconcrete.typeform.com/to/dLNkaHlf',
  FACEBOOK = 'https://www.facebook.com/FoundCo/',
  INSTAGRAM = 'https://www.instagram.com/foundco/',
}
