import { ProfileActionTypes } from './types';
import {
  fetchProfileError,
  fetchProfileSuccess,
  updateCustomerError,
  updateCustomerSuccess,
  updateError,
  updateSuccess,
} from './actions';

import handleError from '../handleError';
import { OrderActionTypes } from '../orders/types';
import { apiActions } from '../../api';

import { call, put, spawn, take, takeLatest } from 'typed-redux-saga';
import { normalize } from 'normalizr';
import { omit } from 'ramda';

import type { Right } from 'fp-ts/lib/Either';
import type { PricingEntities } from 'foundshared/src/api/pricing/types';
import type { Profile } from 'foundshared/src/api/profile/types';
import type { fetchProfilePerform, updateCustomerPerform, updatePerform } from './actions';

const normaliseEntitysResponse = (result: Right<Profile>) =>
  normalize<Profile, PricingEntities, Profile>(result.right, {});

// / ///////////////////////////////// FETCH  ////////////////////////////////////

export function* fetchProfileInstanceSaga({ meta }: ReturnType<typeof fetchProfilePerform>) {
  try {
    const result = yield* call(apiActions.profile.fetchUser);
    const normalisedResult = normaliseEntitysResponse(result);

    yield* put(fetchProfileSuccess(normalisedResult));
    if (meta?.callback) meta.callback(normalisedResult.result);
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(fetchProfileError(error));
    });
  }
}

function* fetchSaga() {
  yield* takeLatest(ProfileActionTypes.FETCH_PERFORM, fetchProfileInstanceSaga);
}

// / ///////////////////////////////// UPDATE ////////////////////////////////////
function* updateInstanceSaga(action: ReturnType<typeof updatePerform>) {
  const { meta } = action;
  try {
    const userResult = yield* call(apiActions.profile.updateUser, omit(['customer'], action.payload));
    const normalisedResult = normaliseEntitysResponse(userResult);

    let customerResult: Then<ReturnType<typeof apiActions.profile.updateCustomer>> | null = null;
    if (action.payload.customer) {
      customerResult = yield* call(apiActions.profile.updateCustomer, action.payload.customer);
    }

    const customer = customerResult ? customerResult.right : normalisedResult.result.customer;
    const finalData = { ...normalisedResult, result: { ...normalisedResult.result, customer } };

    if (meta?.onCreateOrder) {
      meta.onCreateOrder();
      // Wait for the order to complete before dispatching profile data to the store to prevent view glitches.
      yield* take(OrderActionTypes.CREATE_SUCCESS);
    }

    yield* put(updateSuccess(finalData));
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(updateError(error));
    });
  }
}
function* updateSaga() {
  yield* takeLatest(ProfileActionTypes.UPDATE_PERFORM, updateInstanceSaga);
}

// / ///////////////////////// UPDATE CUSTOMER ONLY //////////////////////////////
function* updateCustomerInstanceSaga(action: ReturnType<typeof updateCustomerPerform>) {
  const { meta } = action;
  try {
    const customerResult = yield* call(apiActions.profile.updateCustomer, action.payload);

    yield* put(updateCustomerSuccess(customerResult.right));

    if (meta?.callback) meta.callback();
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(updateCustomerError(error));
    });
  }
}
function* updateCustomerSaga() {
  yield* takeLatest(ProfileActionTypes.CUSTOMER_UPDATE_PERFORM, updateCustomerInstanceSaga);
}

const spawnedProfileSagas = [spawn(fetchSaga), spawn(updateSaga), spawn(updateCustomerSaga)];

export default spawnedProfileSagas;
