import { NormalizedSchema } from 'normalizr';
import { action } from 'typesafe-actions';

import EntityType from '../../utils/entityType';
import { CartActionTypes } from './types';
import { CataloguesState } from '../catalogues/reducer';
import { FreshCatalogue } from '../catalogues/types';
import { OrderContextData } from '../../components/OrderBuilder/utils/formState';

export const clear = () => action(CartActionTypes.CLEAR);

export const updateCart = (payload: OrderContextData) => action(CartActionTypes.UPDATE_CART, payload);

export const updateCatalogueCart = (payload: Omit<FreshCatalogue, 'id' | 'createdAt' | 'expiresAt'>) =>
  action(CartActionTypes.UPDATE_CATALOGUE_CART, payload);

export const clearCart = () => action(CartActionTypes.CLEAR_CART);

export const fetchCataloguePerform = (
  locality: string | undefined = 'SYDNEY',
  postcode: string | undefined = '2000',
  meta?: any
) => action(CartActionTypes.FETCH_CATALOGUE_PERFORM, { locality, postcode }, meta);

export const fetchCatalogueSuccess = (
  payload: NormalizedSchema<Omit<CataloguesState, EntityType.CATALOGUES>, FreshCatalogue>,
  meta?: any
) => action(CartActionTypes.FETCH_CATALOGUE_SUCCESS, payload, meta);

// TODO: Temporary see FOUND-119
export const fetchCatalogueSoonSuccess = () => action(CartActionTypes.FETCH_CATALOGUE_SUCCESS);

export const fetchCatalogueError = (error: Error) =>
  action(CartActionTypes.FETCH_CATALOGUE_ERROR, undefined, undefined, error);

export const resetCatalogue = () => action(CartActionTypes.FETCH_CATALOGUE_RESET);
