
import EntityType from '../../utils/entityType';
import { catalogueEntitySchema } from '../catalogues/schema';
import { invoicesCollectionEntitySchema } from '../entities/schema';

import { schema } from 'normalizr';

import type { EntityState } from '../entities/types';

export const orderEntitySchema = new schema.Entity<EntityState>(EntityType.ORDERS, {
  [EntityType.INVOICES]: invoicesCollectionEntitySchema,
  catalogue: catalogueEntitySchema,
});

export const orderCollectionSchema = new schema.Array(orderEntitySchema);
