import keys from 'lodash/keys';
import memoize from 'lodash/memoize';
import pick from 'lodash/pick';
import { createSelector } from 'reselect';

import { AppState } from '../reducer';
import { cartCatalogue$ } from '../cart/selectors';
import { optionGroupEntities$, productTypeEntities$ } from '../catalogues/selectors';

export const quoteEntities$ = (state: AppState) => state.quotes;

/**
 * Finds the quote via a provided order ID.
 * @param id Quote of the ID we're looking for
 * @returns {order}
 */
export const quote$ = createSelector(quoteEntities$, (quotes) =>
  memoize((quoteId?: string) => (quoteId ? quotes[quoteId] : undefined))
);

export const quoteExists$ = createSelector(quoteEntities$, (quotes) =>
  memoize((quoteId?: string) => (quoteId ? !!quotes[quoteId] : false))
);

export const quotesCollection$ = createSelector([quoteEntities$], (orderEntities) => keys(orderEntities));

export const quoteCount$ = createSelector([quotesCollection$], (quotes) => quotes.length);

export const quoteProductType$ = createSelector(quoteEntities$, productTypeEntities$, (quotes, products) =>
  memoize((quoteId?: string) => {
    if (quoteId) {
      const { productTypeId } = quotes[quoteId];
      return products[productTypeId];
    }

    return undefined;
  })
);

/**
 * Returns array of an quotes's option groups
 * @param id Quote of the ID we're looking for
 * @returns Quote's option groups
 */
export const quoteOptionGroupsCollection$ = createSelector(
  quoteProductType$,
  optionGroupEntities$,
  (quoteProductType, optionGroupEntities) =>
    memoize((quoteId?: string) => {
      if (quoteId) {
        const optionGroups = quoteProductType(quoteId)?.optionGroups;
        return optionGroups?.map((id) => optionGroupEntities[id]);
      }
      return undefined;
    })
);

export const prices$ = (id?: string) =>
  createSelector([quote$, cartCatalogue$], (quote, cartCatalogue) => {
    const catalogue = cartCatalogue || quote(id)?.catalogue;
    const { basePrice = 0, smallLoadUnitPrice = 0 } = pick(catalogue, ['basePrice', 'smallLoadUnitPrice']);
    return { basePrice, smallLoadUnitPrice };
  });
