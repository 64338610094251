export enum AvailabilityActionTypes {
  FETCH_PERFORM_BFF = '@@availability/FETCH_PERFORM_BFF',
  FETCH_PERFORM = '@@availability/FETCH_PERFORM',
  FETCH_SUCCESS = '@@availability/FETCH_SUCCESS',
  FETCH_SUCCESS_BFF = '@@availability/FETCH_SUCCESS_BFF',
  FETCH_ERROR = '@@availability/FETCH_ERROR',
  CLEAR = '@@availability/CLEAR',
}

export enum ServiceAreaActionTypes {
  FETCH_PERFORM = '@@serviceAreas/FETCH_PERFORM',
  FETCH_SUCCESS = '@@serviceAreas/FETCH_SUCCESS',
  FETCH_ERROR = '@@serviceAreas/FETCH_ERROR',
  CLEAR = '@@serviceAreas/CLEAR',
}
