export enum ColorPalette {
  ALABASTER = '#fafafa',
  AMARANTH = '#ED2647',
  ASTRAL = '#357BB1',
  ACCENT = '#FDD522',
  AZURE_RADIANCE_1 = '#69ceff',
  AZURE_RADIANCE_2 = '#009DFF',
  AZURE_RADIANCE_3 = '#006fcb',
  BASTILLE = '#282132',
  BERMUDA_GREY_1 = '#EEF2F5',
  BERMUDA_GREY_2 = '#DEE5EB',
  BERMUDA_GREY_3 = '#B6C4CF',
  BERMUDA_GREY_4 = 'rgb(109 138 159 / 37%)',
  BLACK = '#000000',
  BLUE = '#001045',
  CANDLE_LIGHT = '#FDD523',
  CLEMENTINE = '#E56700',
  DARK_CLAY_1 = '#D4D5D9',
  DARK_CLAY_2 = '#7F808D',
  DARK_CLAY_3 = '#313353',
  DARK_CLAY_4 = '#1B1D3D',
  DARK_CLAY_5 = '#1B1D3D',
  GREY_NICKEL = '#B3B3B0',
  JUMBO = '#74707A',
  LINE = '#231F20',
  LYNX_WHITE = '#F7F7F7',
  MERCURY = '#E8E8E8',
  MOUNTAIN_MEADOW = '#13AF77',
  QUILL_GREY = '#CFCFCC',
  SCHOOL_BUS_YELLOW = '#FFDB00',
  SHAMROCK = '#38DE80',
  SHIP_GREY = '#3E3747',
  ULTRAMARINE = '#536EF9',
  WHITE = '#fff',
  UI_ERROR = '#B81237',
  UI_WARNING = '#DC5800',
  UI_SUCCESS = '#00A908',
  UI_DEFAULT_BACKGROUND = '#F6F9FB',
  UI_ERROR_BACKGROUND = '#FFE7EC',
  UI_WARNING_BACKGROUND = '#FBE7DA',
  UI_SUCCESS_BACKGROUND = '#E6F6E6',
  UI_INFO = '#DFF7FF',
  UI_BILLING = '#ECEDF4',
  UI_URL = '#0CCC5F',
}
