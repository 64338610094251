import omit from 'lodash/omit';

import { AppState } from './reducer';
import { initialState } from './entities/reducer';

/*
 * Version 0: normalises the invoiceSummaries from inside the orders, so current invoiceSummaries are wiped to be
 * replaced by invoice summary IDs.
 * Version 1: profile is now an empty object, not null to allow seperate customer support.
 * Version 2: clear current orders for refreshed backend ids (patch fix)
 * Version 3: structural changes to entities, catalogues, orders and cart.
 * Version 4: Further structural changes to entities, catalogues, orders and cart.
 */
const migrations = {
  0: (state: any) => {
    const { orders } = state.entities;
    Object.keys(orders).forEach((id) => {
      orders[id] = { ...orders[id], invoiceSummaries: [] };
    });
    return { ...state, entities: { ...state.entities, orders } };
  },
  1: (state: AppState) => ({
    ...state,
    profile: state.profile ?? {},
  }),
  2: (state: AppState) => ({
    ...state,
    entities: initialState,
  }),
  3: (state: AppState) => omit(state, 'app'),
};

export default migrations;
