import type { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: 'Inter',
  // Heading 1
  h1: {
    fontSize: '4.5rem', // * 16 = 72px
    lineHeight: 1.03,
    fontWeight: 700, // Inter-Bold
    letterSpacing: 'normal',
  },
  // Heading 2
  h2: {
    fontSize: '2.5rem', // * 16 = 40px
    lineHeight: 1.3,
    fontWeight: 700, // Inter-Bold
    letterSpacing: 'normal',
  },
  // Heading 3
  h3: {
    fontSize: '2rem', // * 16 = 32px
    lineHeight: 1.25,
    fontWeight: 700, // Inter-Bold
    letterSpacing: 'normal',
  },
  // Heading 4
  h4: {
    fontSize: '1.5rem', // * 16 = 24px
    lineHeight: 1.333,
    fontWeight: 700, // Inter-Bold
    letterSpacing: 'normal',
  },
  // Heading 5
  h5: {
    fontSize: '1.25rem', // * 16 = 20px
    lineHeight: 1.3,
    fontWeight: 700, // Inter-Bold
    letterSpacing: 'normal',
  },
  // Heading 6
  h6: {
    fontSize: '1.125rem', // * 16 = 18px
    fontWeight: 600, // Inter-SemiBold
    lineHeight: 1.33,
  },
  // Body bold
  subtitle1: {
    fontSize: '1rem', // * 16 = 16px
    fontWeight: 500, // Inter-Medium
    lineHeight: 1.5,
  },
  // Small body bold
  subtitle2: {
    fontSize: '1rem', // * 16 = 16px
    fontWeight: 500, // Inter-Medium
    lineHeight: 1.43,
  },
  // Body
  body1: {
    fontSize: '1rem', // * 16 = 16px
    lineHeight: 1.5,
  },
  // Small body
  body2: {
    fontSize: '0.875rem', // * 16 = 14px
    lineHeight: 1.43,
  },
  caption: {
    fontSize: '0.75rem', // * 16 = 12px
    lineHeight: 1.67,
  },
  // OLD:
  // Same as h5
  button: {
    fontSize: '1.25rem', // * 16 = 20px
    lineHeight: 1.3,
    fontWeight: 700,
    letterSpacing: 'normal',
  },
};

// Custom varients coming in MUI v5: https://github.com/mui-org/material-ui/issues/15573
// export const extendedTypography = {
//   input: {
//     fontSize: '1.125rem', // * 16 = 18px
//     fontWeight: 500,
//     lineHeight: 1.33,
//   },
//   inputPlaceholder: {
//     fontSize: '1.125rem', // * 16 = 18px
//     fontWeight: 400,
//     lineHeight: 1.33,
//     // color: ColorPalette.GREY_NICKEL,
//     opacity: 1,
//   },
// };

export default typography;
