import { FeatureToggleActionTypes } from './constants';

import { createReducer } from 'typesafe-actions';

import type { ActionType } from 'typesafe-actions';
import type { fetchError, fetchSuccess } from './actions';
import type { FeatureToggle } from 'foundshared/src/api/features/codecs';

export const initialState = [];

type ActionTypes = ActionType<typeof fetchSuccess> | ActionType<typeof fetchError>;
export type InitialFeatureToggleState = Partial<FeatureToggle>[];

export default createReducer<InitialFeatureToggleState, ActionTypes>(initialState)
  .handleType(FeatureToggleActionTypes.FETCH_SUCCESS, (_state, { payload }) => payload)
  .handleType(FeatureToggleActionTypes.FETCH_ERROR, (state) => state);
