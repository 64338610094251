import omit from 'lodash/omit';
import { ActionType, createReducer } from 'typesafe-actions';

import { Order, OrderActionTypes } from './types';
import {
  createOrderSuccess,
  deleteOrderSuccess,
  fetchOrdersFirstSuccess,
  fetchOrdersSuccess,
  updateOrderSuccess,
} from './actions';

export type OrdersState = Record<string, Order>;

export const initialOrdersState: OrdersState = {};

type ActionTypes =
  | ActionType<typeof updateOrderSuccess>
  | ActionType<typeof fetchOrdersSuccess>
  | ActionType<typeof fetchOrdersFirstSuccess>
  | ActionType<typeof createOrderSuccess>
  | ActionType<typeof deleteOrderSuccess>;

const ordersReducer = createReducer<OrdersState, ActionTypes>(initialOrdersState)
  .handleType(OrderActionTypes.FETCH_SUCCESS, (state, { payload }) => ({
    ...state,
    ...payload.entities.orders,
  }))
  .handleType(OrderActionTypes.FETCH_SUCCESS_FIRST, (state, { payload }) => ({
    ...state,
    ...payload.entities.orders,
  }))
  .handleType(OrderActionTypes.DELETE_SUCCESS, (state, action) => omit(state, action.payload.id))
  .handleType(OrderActionTypes.UPDATE_SUCCESS, (state, action) => ({
    ...state,
    ...action.payload.entities.orders,
  }))
  .handleType(OrderActionTypes.CREATE_SUCCESS, (state, action) => ({
    ...state,
    ...action.payload.entities.orders,
  }));

export default ordersReducer;
