import { groupOrders } from '../../components/Orders/components/OrderListContainer/groupOrders';
import {
  OptionGroupName,
  optionEntities$,
  optionGroupEntities$,
  optionValueEntities$,
  productTypeEntities$,
} from '../catalogues/selectors';
import { OrderFilter } from '../../components/Orders/utils';
import { calculatePricePerCubicMeter } from '../../components/OrderBuilder/utils/pricing';

import { cartCatalogue$ } from '../cart/selectors';
import * as R from 'ramda';
import type { GroupOrderKey} from '../../components/Orders/components/OrderListContainer/groupOrders';


import type { AppState } from '../reducer';

import camelcase from 'camelcase';
import fromPairs from 'lodash/fromPairs';
import isBefore from 'date-fns/isBefore';
import isNil from 'lodash/isNil';
import isSameDay from 'date-fns/isSameDay';
import memoize from 'lodash/memoize';
import pick from 'lodash/pick';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';

import type { OptionValueResponse } from 'foundshared/src/api/catalogue/types';

import { createSelector } from 'reselect';

import type { Option, OptionGroup, OptionValue, ProductType } from '../catalogues/types';
import type {
  OptionGroupByProductType,
  OptionGroupsByProductTypes} from '../catalogues/selectors';


import type { Order } from './types';
import type { OrderContextData } from '../../components/OrderBuilder/utils/formState';


import type { Quote } from '../quotes/types';

import { quote$, quotesCollection$ } from '../quotes/selectors';

export const orderEntities$ = (state: AppState) => state.orders;

// Lodash generates our types better vs Object.values
export const orderCollection$ = createSelector(orderEntities$, (orderEntities) => values(orderEntities));

export const orderIdCollection$ = createSelector(orderEntities$, (orderEntities) => Object.keys(orderEntities));

export const orderCount$ = createSelector([orderCollection$], (orders) => orders.length);

const sortByDeliverAt = R.sortBy(R.prop('deliverAt'));

function isDelivered(deliverAt: string, beforeToday = true) {
  return (
    isBefore(new Date(deliverAt), new Date()) && (beforeToday ? !isSameDay(new Date(deliverAt), new Date()) : true)
  );
}

export const filterUpcoming$ = createSelector(orderEntities$, orderIdCollection$, (orderEntities, orderIdCollection) =>
  memoize((beforeToday?: boolean) =>
    orderIdCollection.filter((id) => !isDelivered(orderEntities[id].deliverAt, beforeToday))
  )
);

export const filterDelivered$ = createSelector(orderEntities$, orderIdCollection$, (orderEntities, orderIdCollection) =>
  memoize((beforeToday?: boolean) =>
    orderIdCollection.filter((id) => isDelivered(orderEntities[id].deliverAt, beforeToday))
  )
);

export const dateSortedUpcomingOrderCollection$ = createSelector([filterUpcoming$], (filterUpcoming) =>
  memoize((beforeToday?: boolean) => sortByDeliverAt(filterUpcoming(beforeToday)))
);

export const dateSortedDeliveredOrderCollection$ = createSelector(filterDelivered$, (filterDelivered) =>
  memoize((beforeToday?: boolean) => sortByDeliverAt(filterDelivered(beforeToday)))
);

export const filteredOrderCollection$ = createSelector(
  filterUpcoming$,
  filterDelivered$,
  (filterUpcoming, filterDelivered) =>
    memoize((filter: OrderFilter) =>
      filter === OrderFilter.UPCOMING ? sortByDeliverAt(filterUpcoming()) : sortByDeliverAt(filterDelivered())
    )
);

export const filterGroupedOrderCollection$ = createSelector(
  [dateSortedUpcomingOrderCollection$, dateSortedDeliveredOrderCollection$],
  (upcoming, delivered) => ({
    [OrderFilter.UPCOMING]: upcoming(),
    [OrderFilter.DELIVERED]: delivered(),
  })
);

export const orderExists$ = createSelector(orderEntities$, (orders) =>
  memoize((orderId?: string) => (orderId ? !!orders[orderId] : false))
);

// export const groupOrders$ = createSelector(orderEntities$, (orders) =>
//   memoize((ids: string[]) => {
//     const ordersDeliverAt = ids.map((id) => ({ id, deliverAt: orders[id].deliverAt }));
//     return groupOrders(ordersDeliverAt);
//   })
// );

export const groupedOrdersAndQuotes$ = createSelector(orderEntities$, quotesCollection$, (orders, quotes) =>
  memoize((orderIds: string[]) => {
    const ordersDeliverAt = orderIds.map((id) => ({ id, deliverAt: orders[id].deliverAt }));
    const quotesIds = quotes.map((id) => ({ id, isQuote: true }));
    return groupOrders([...quotesIds, ...ordersDeliverAt]);
  })
);

export const ordersAndQuotesByGroups$ = createSelector(
  groupedOrdersAndQuotes$,
  (groupedOrdersAndQuotes) => (orderIds: string[], groupNames: Array<GroupOrderKey>) => {
    const group = groupedOrdersAndQuotes(orderIds);

    return pick(group, groupNames);
  }
);

/**
 * Finds the order via a provided order ID.
 * @param {Number} id Order of the ID we're looking for
 * @returns {order}
 */
export const order$ = createSelector(orderEntities$, (orders) =>
  memoize((orderId?: string) => (orderId ? orders[orderId] : undefined))
);

/**
 * Finds the order via a provided order ID.
 * @param {Number} id Order of the ID we're looking for
 * @returns {order}
 */
export const orderProductType$ = createSelector(orderEntities$, productTypeEntities$, (orders, products) =>
  memoize((orderId?: string) => {
    if (orderId) {
      const { catalogue } = orders[orderId];
      return products[catalogue.productType];
    }

    return undefined;
  })
);

/**
 * Returns array of an order's option groups
 * @param {Number} id Order of the ID we're looking for
 * @returns {order}
 */
export const orderOptionGroupsCollection$ = createSelector(
  orderProductType$,
  optionGroupEntities$,
  (orderProductType, optionGroupEntities) =>
    memoize((orderId?: string) => {
      if (orderId) {
        const optionGroups = orderProductType(orderId)?.optionGroups;
        return optionGroups?.map((id) => optionGroupEntities[id]);
      }
      return undefined;
    })
);

/**
 * This will return the 'basePrice' and 'smallLoadUnitPrice' from the cart catalogue (if the id is present in the cart),
 * otherwise it will return the values from the order catlaogue (if an id is provided).
 */
export const prices$ = (id?: string) =>
  createSelector([order$, cartCatalogue$], (order, cartCatalogue) => {
    const catalogue = cartCatalogue || order(id)?.catalogue;
    const { basePrice = 0, smallLoadUnitPrice = 0 } = pick(catalogue, ['basePrice', 'smallLoadUnitPrice']);
    return { basePrice, smallLoadUnitPrice };
  });

/**
 * Takes the fields from our item (order | item) and looks for matching keys within our catalogue options
 * and then matches the field values to catalogue value IDs.
 * @example Takes the 'placement' key and checks for a match
 */
function mapItemKeyValues(
  item: Order | Quote | OrderContextData[keyof OrderContextData],
  options: Option[],
  optionValueEntities: Record<string, OptionValue>
) {
  // Grab all the keys under the item
  const optionValueIds = Object.keys(item) as Array<keyof typeof item>;

  return optionValueIds.reduce((itemOptionValues: OptionValue[], key) => {
    const valueId = item[key];
    // We only want to be matching string values (IDs)
    if (typeof valueId === 'string') {
      /**
       * Find the option by matching the option name with a value key name. We also have to camelcase the name of the
       * key from the option.
       * @example Converts 'truck_spacing' to 'truckSpacing' and checks for a match
       */
      const option = options.find(({ name }) => camelcase(name) === key);

      const optionValue = optionValueEntities[valueId];

      // If the item (order | quote) key doesn't match an item option which contains value IDs or has no value, then ignore it.
      if (!option?.values?.length || isNil(optionValue)) return itemOptionValues;

      return [...itemOptionValues, optionValue];
    }

    return itemOptionValues;
  }, [] as OptionValue[]);
}

export function optionValues(
  itemValues: Order | Quote | OrderContextData[keyof OrderContextData],
  productType: string,
  productTypeEntities: Record<string, ProductType>,
  optionGroupEntities: Record<string, OptionGroup>,
  optionEntities: Record<string, Option>,
  optionValueEntities: Record<string, OptionValueResponse>
) {
  /** All option groups under the product type. */
  const productOptionGroups = productTypeEntities[productType].optionGroups;

  /** All option IDs under the option group. */
  const itemOptionIds = productOptionGroups?.map((optionGroupId) => optionGroupEntities[optionGroupId].options).flat();

  /** Map all those option IDs under the option group to the actual option. */
  const itemOptions = itemOptionIds.map((id) => optionEntities[id]);

  return mapItemKeyValues(itemValues, itemOptions, optionValueEntities);
}

/**
 * @returns An item's (order | quote) representative values (i.e. only options with a chosen value choice)
 */
export const itemValueCollection$ = createSelector(
  productTypeEntities$,
  optionGroupEntities$,
  optionEntities$,
  optionValueEntities$,
  order$,
  quote$,
  (productTypeEntities, optionGroupEntities, optionEntities, optionValueEntities, order, quote) =>
    memoize(({ quoteId, orderId }: { quoteId?: string; orderId?: string }) => {
      // Figure out which item (quote | order) we're dealing with
      const item = (quoteId ? quote(quoteId) : undefined) ?? (orderId ? order(orderId) : undefined);

      if (!item) return undefined;

      const productType = 'productTypeId' in item ? item.productTypeId : item.catalogue.productType;

      return optionValues(
        item,
        productType,
        productTypeEntities,
        optionGroupEntities,
        optionEntities,
        optionValueEntities
      );
    })
);

export const itemPricePerCubicMeter$ = createSelector(
  itemValueCollection$,
  quote$,
  order$,
  (itemValueCollection, getQuote, getOrder) =>
    memoize(({ quoteId, orderId }: { quoteId?: string; orderId?: string }) => {
      const quote = quoteId ? getQuote(quoteId) : undefined;
      const order = orderId ? getOrder(orderId) : undefined;

      // Figure out which item we're dealing with
      const item = quote ?? order;

      if (item) {
        const { basePrice } = item.catalogue;

        const itemValues = itemValueCollection({ quoteId, orderId });

        if (!itemValues) return 0;

        return calculatePricePerCubicMeter(itemValues, basePrice);
      }

      return 0;
    })
);

export const orderBuilderCatalogue$ = createSelector(order$, cartCatalogue$, (order, cartCatalogue) =>
  memoize((orderId?: Order['id']) => {
    const orderCatalogue = order(orderId)?.catalogue;
    return orderCatalogue ?? cartCatalogue;
  })
);

export const cartProductTypeCollection$ = createSelector(
  productTypeEntities$,
  orderBuilderCatalogue$,
  (productTypeEntities, orderBuilderCatalogue) =>
    memoize((orderId?: Order['id']) => {
      const catalogue = orderBuilderCatalogue(orderId);

      const products = catalogue?.productTypes.map((id) => productTypeEntities[id]);

      return products?.sort(
        ({ sortKey = '' }, { sortKey: sortKeyB = '' }) => +(sortKey > sortKeyB) || -(sortKey < sortKeyB)
      );
    })
);

/**
 * This is essentially denormalising the option groups but leaving values.
 *
 * @remarks
 *
 * This needs to be reworked into a global function.
 */
export const optionGroupsByProductTypes$ = createSelector(
  [productTypeEntities$, optionGroupEntities$, optionEntities$, orderBuilderCatalogue$],
  (productTypeEntities, optionGroupEntities, optionEntities, orderBuilderCatalogue) =>
    memoize((orderId?: Order['id']) => {
      const catalogue = orderBuilderCatalogue(orderId);

      const productTypes = catalogue?.productTypes.map((id) => productTypeEntities[id]);

      if (!productTypes?.length) return [];

      const optionGroupNames = [
        OptionGroupName.MIX,
        OptionGroupName.VOLUME,
        OptionGroupName.DELIVERY,
        OptionGroupName.DATE_AND_TIME,
      ];

      return R.reduce(
        (acc: OptionGroupsByProductTypes, productType: ProductType) => {
          const productTypeOptionGroupEntities = R.pick(productType.optionGroups, optionGroupEntities);

          acc[Number(productType.id)] = R.reduce(
            (groupAcc: { [key in OptionGroupName]: OptionGroupByProductType }, name: OptionGroupName) => {
              const withTitle = R.propEq('name', name as string);
              const group = R.find<OptionGroup>(withTitle, R.values(productTypeOptionGroupEntities));

              if (group) {
                const options = R.values(R.pick(group.options, optionEntities));
                // eslint-disable-next-line no-param-reassign
                groupAcc[name] = { ...group, options };
              }
              return groupAcc;
            },
            {} as { [key in OptionGroupName]: OptionGroupByProductType },
            optionGroupNames
          );
          return acc;
        },
        {},
        productTypes
      );
    })
);

export const currentCartOptionGroupsByProductTypes$ = createSelector(
  [optionGroupsByProductTypes$, cartCatalogue$],
  (optionGroupsByProductTypes, cartCatalogue) =>
    fromPairs(
      toPairs(optionGroupsByProductTypes()).filter(
        ([key]) => cartCatalogue && cartCatalogue.productTypes.indexOf(key.toString()) > -1
      )
    )
);
