import { PricingActionType } from './types';

import { action } from 'typesafe-actions';

import type { PricingEntities } from 'foundshared/src/api/pricing/types';


export const fetchPricingPerform = () => action(PricingActionType.FETCH_PRICING_REGION_PERFORM, {});

export const fetchPricingSuccess = (data: PricingEntities) =>
  action(PricingActionType.FETCH_PRICING_REGION_SUCCESS, data);

export const fetchPricingError = (error: Error) => action(PricingActionType.FETCH_PRICING_REGION_ERROR, error);

export const fetchPricingOptionsPerform = (regionId: string) =>
  action(PricingActionType.FETCH_PRICING_REGION_OPTIONS_PERFORM, regionId);

export const fetchPricingOptionsSuccess = (data: PricingEntities) =>
  action(PricingActionType.FETCH_PRICING_REGION_OPTIONS_SUCCESS, data);

export const fetchPricingOptionsError = (error: Error) =>
  action(PricingActionType.FETCH_PRICING_REGION_OPTIONS_ERROR, error);
