import {
  requestPlusLoadsError,
  requestPlusLoadsFinished,
  submitPlusLoadFinished,
  submitPlusLoadError,
} from './actions';
import { PlusLoadsActionTypes } from './types';

import { apiActions } from '../../api';
import tracking from '../../utils/tracking';
import { TrackingEvent } from '../../utils/tracking/types';
import { addSuccess } from '../notifications/actions';
import handleError from '../handleError';

import { call, put, spawn, takeLatest } from 'typed-redux-saga';

import type { submitPlusLoad } from './actions';

export function* fetchPlusLoadsInstanceSaga() {
  try {
    const result = yield* call(apiActions.plusLoads.fetchPlusLoads);
    yield* put(requestPlusLoadsFinished(result.right));
  } catch (error) {
    yield* put(requestPlusLoadsError(error as Error));
  }
}

// / ////////////////////////////// SUBMIT ////////////////////////////////////

function* createPlusLoadInstanceSaga({ payload, meta }: ReturnType<typeof submitPlusLoad>) {
  try {
    const { right } = yield* call(apiActions.plusLoads.createPlusLoad, payload);
    yield* put(submitPlusLoadFinished(right));

    tracking.event(TrackingEvent.NEW_PLUS_LOAD, {
      order_id: right.orderId,
      seqnum: right.seqnum,
      quantity: right.quantity,
      xero_invoice_summary: right.quantity,
    });

    if (meta?.callback) meta.callback(right.orderId);
    if (meta?.successMessage) yield* put(addSuccess(meta.successMessage));
  } catch (error) {
    if (meta?.failedCallback) meta.failedCallback();

    yield* handleError(error as Error, put, function* () {
      yield* put(submitPlusLoadError(error as Error));
    });
  }
}

function* fetchPlusLoadsSaga() {
  yield* takeLatest(PlusLoadsActionTypes.REQUEST_PLUS_LOADS, fetchPlusLoadsInstanceSaga);
}

function* createPlusLoadSaga() {
  yield* takeLatest(PlusLoadsActionTypes.REQUEST_PLUS_LOAD_SUBMISSION, createPlusLoadInstanceSaga);
}

const spawnedAppSagas = [spawn(fetchPlusLoadsSaga), spawn(createPlusLoadSaga)];

export default spawnedAppSagas;
