import { ThemeOptions } from '@mui/material/styles';

const tables: ThemeOptions['components'] = {
  MuiTableBody: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiTableRow-root:nth-child(even)': {
          backgroundColor: theme.palette.background.default,
        },
      }),
    },
  },
};

export default tables;
