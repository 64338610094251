import Link from 'next/link';
import MenuItem from '@mui/material/MenuItem';
import { useRef } from 'react';

import * as desktopItemStyles from './DesktopItem.styles';
import useTrackLinkSource from '../../../../utils/tracking/useTrackLinkSource';
import { LinkProps } from '../../utils/navItems';

export interface StyleProps {
  dark?: boolean;
}

export type DesktopItemProps = LinkProps & StyleProps;

function DesktopItem({ to, primary, onClick, href, trackingLink, dark }: DesktopItemProps) {
  const { classes: menuItemClasses } = desktopItemStyles.useMenuItemStyles({ dark });
  // const { classes: menuItemActiveClasses } = desktopItemStyles.useActiveMenuItemStyles({ dark });

  const linkRef = useRef<HTMLAnchorElement>(null);
  useTrackLinkSource(trackingLink, linkRef);

  if (to) {
    return (
      <Link passHref href={to}>
        <MenuItem component="a" classes={menuItemClasses}>
          {primary}
        </MenuItem>
      </Link>
    );
  }
  if (onClick) {
    return (
      <MenuItem component="button" onClick={onClick} classes={menuItemClasses}>
        {primary}
      </MenuItem>
    );
  }
  return (
    <MenuItem component="a" href={href || ''} ref={linkRef} classes={menuItemClasses}>
      {primary}
    </MenuItem>
  );
}

export default DesktopItem;
