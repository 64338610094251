import { pathOr } from 'ramda';

import { ConcreteCashField, DeliveryFormField, DetailsFormField, SiteFormField } from '../../../utils/orderForms';
import { Order } from '../../../store/orders/types';
import { Quote } from '../../../store/quotes/types';

export enum OrderStep {
  DETAILS,
  SITE,
  DELIVERY,
  BILLING,
  FINISH,
}

export interface DetailsStepContext {
  [DetailsFormField.ADD_MESSAGE]: boolean;
  [DetailsFormField.ADDITIVES]?: string;
  [DetailsFormField.AGG]?: string;
  [DetailsFormField.COLOUR]?: string;
  [DetailsFormField.LOCATION]:
    | {
        postcode: string;
        locality: string;
        state: string;
        lat: number;
        long: number;
      }
    | undefined;
  [DetailsFormField.MPA]?: string;
  [DetailsFormField.PRODUCT_TYPE_ID]?: string;
  [DetailsFormField.QUANTITY]: number;
  [DetailsFormField.SLUMP]?: string;
  [DetailsFormField.IS_COLOUR_MATCHING_JOB]?: boolean;
  [DetailsFormField.MAX_QUANTITY_REQUIRED]?: number;
}

export interface SiteStepContext {
  [SiteFormField.DRIVER_NOTES]: string | undefined;
  [SiteFormField.ONSITE_PERSON_NAME]: string;
  [SiteFormField.ONSITE_PERSON_MOBILE]: string;
  [SiteFormField.PLACEMENT]?: string;
  [SiteFormField.SITE_ACCESS_RESTRICTION]: string | null | undefined;
  [SiteFormField.STREET_ADDRESS]?: string | undefined;
  [SiteFormField.SUPPLIER_NOTES]: string | undefined;
  [SiteFormField.TRUCK_SIZE]?: string | undefined;
  [SiteFormField.TRUCK_SPACING]: number;
  [SiteFormField.LOCATION]:
    | {
        postcode: string;
        locality: string;
        state: string;
        lat: number;
        long: number;
      }
    | undefined;
}

export interface DeliveryStepContext {
  [DeliveryFormField.DELIVERY_AT]?: string;
}

export interface BillingStepContext {
  [ConcreteCashField.APPLY_CASH]: number;
}
export interface OrderContextData {
  [OrderStep.DETAILS]: DetailsStepContext;
  [OrderStep.SITE]: SiteStepContext;
  [OrderStep.DELIVERY]: DeliveryStepContext;
  [OrderStep.BILLING]: BillingStepContext;
}

export interface OrderContext {
  data: OrderContextData;
}

export const initialOrderContextLocation: SiteStepContext['location'] = {
  postcode: '',
  locality: '',
  state: '',
  lat: 0,
  long: 0,
};

export const initialOrderContext: OrderContext = {
  data: {
    [OrderStep.DETAILS]: {
      [DetailsFormField.ADD_MESSAGE]: false,
      [DetailsFormField.ADDITIVES]: undefined,
      [DetailsFormField.AGG]: undefined,
      [DetailsFormField.COLOUR]: undefined,
      [DetailsFormField.LOCATION]: initialOrderContextLocation,
      [DetailsFormField.MPA]: undefined,
      [DetailsFormField.PRODUCT_TYPE_ID]: undefined,
      [DetailsFormField.QUANTITY]: 0,
      [DetailsFormField.SLUMP]: undefined,
      [DetailsFormField.IS_COLOUR_MATCHING_JOB]: false,
      [DetailsFormField.MAX_QUANTITY_REQUIRED]: 0,
    },
    [OrderStep.SITE]: {
      [SiteFormField.DRIVER_NOTES]: '',
      [SiteFormField.ONSITE_PERSON_NAME]: '',
      [SiteFormField.ONSITE_PERSON_MOBILE]: '',
      [SiteFormField.PLACEMENT]: undefined,
      [SiteFormField.STREET_ADDRESS]: '',
      [SiteFormField.SUPPLIER_NOTES]: '',
      [SiteFormField.TRUCK_SIZE]: undefined,
      [SiteFormField.SITE_ACCESS_RESTRICTION]: null,
      [SiteFormField.TRUCK_SPACING]: 15,
      [SiteFormField.LOCATION]: initialOrderContextLocation,
    },
    [OrderStep.DELIVERY]: {
      [DeliveryFormField.DELIVERY_AT]: undefined,
    },
    [OrderStep.BILLING]: {
      [ConcreteCashField.APPLY_CASH]: 0,
    },
  },
};

export function updateCartStep<T>(step: OrderStep, cart: OrderContextData, values: T) {
  return { ...cart, [step]: values };
}

export const editOrderContext = (item: Order | Quote): OrderContextData => ({
  ...('deliverAt' in item
    ? {
        [OrderStep.SITE]: {
          [SiteFormField.DRIVER_NOTES]: item.driverNotes,
          [SiteFormField.ONSITE_PERSON_NAME]: item.onsitePersonName,
          [SiteFormField.ONSITE_PERSON_MOBILE]: item.onsitePersonMobile,
          [SiteFormField.PLACEMENT]: item.placement,
          [SiteFormField.STREET_ADDRESS]: item.location.streetAddress,
          [SiteFormField.SUPPLIER_NOTES]: item.supplierNotes,
          [SiteFormField.TRUCK_SIZE]: item.truckSize,
          [SiteFormField.SITE_ACCESS_RESTRICTION]: item.siteAccessRestriction,
          [SiteFormField.TRUCK_SPACING]: item.truckSpacing,
          [SiteFormField.LOCATION]: {
            locality: pathOr('', ['location', 'locality'], item),
            state: pathOr('', ['location', 'adminArea'], item),
            postcode: pathOr('', ['location', 'postcode'], item),
            lat: 0,
            long: 0,
          },
        },
        [OrderStep.DELIVERY]: {
          [DeliveryFormField.DELIVERY_AT]: item.deliverAt,
        },
        [OrderStep.BILLING]: {
          [ConcreteCashField.APPLY_CASH]: item.walletUsed,
        },
      }
    : initialOrderContext.data),

  [OrderStep.DETAILS]: {
    [DetailsFormField.AGG]: item.agg,
    [DetailsFormField.ADD_MESSAGE]: 'addMessage' in item ? item.addMessage : false,
    [DetailsFormField.ADDITIVES]: item.additives,
    [DetailsFormField.COLOUR]: item.colour,
    [DetailsFormField.LOCATION]: {
      locality: pathOr('', ['location', 'locality'], item),
      state: pathOr('', ['location', 'adminArea'], item),
      postcode: pathOr('', ['location', 'postcode'], item),
      lat: 0,
      long: 0,
    },
    [DetailsFormField.MPA]: item.mpa,
    [DetailsFormField.PRODUCT_TYPE_ID]: 'productTypeId' in item ? item.productTypeId : item.catalogue.productType,
    [DetailsFormField.QUANTITY]: Number(item.quantity),
    [DetailsFormField.SLUMP]: item.slump,
    [DetailsFormField.IS_COLOUR_MATCHING_JOB]: 'isColourMatchingJob' in item ? item.isColourMatchingJob : false,
    [DetailsFormField.MAX_QUANTITY_REQUIRED]: 'maxQuantityRequired' in item ? Number(item.maxQuantityRequired) : 0,
  },
});
