import { AvailabilityActionTypes, ServiceAreaActionTypes } from './constants';

import { createReducer } from 'typesafe-actions';

import type { AvailabilityResponse } from 'foundshared/src/api/availability/codecs';
import type { ActionType, StateType } from 'typesafe-actions';
import type {
  clear as clearAvailability,
  clearSuggestions,
  fetchAvailabilitySuccess,
  fetchAvailabilitySuccessBFF,
  fetchSuccess as fetchServiceAreaSuccess,
} from './actions';
import type { ServiceAreaResponse } from 'foundshared/src/api/serviceAreas/codecs';

export const initialState = {
  suggestions: [] as ServiceAreaResponse[],
  availability: [] as AvailabilityResponse[],
  availabilityBFF: false as boolean,
};

type InitialStateType = typeof initialState;

type ActionTypes =
  | ActionType<typeof clearSuggestions>
  | ActionType<typeof fetchServiceAreaSuccess>
  | ActionType<typeof clearAvailability>
  | ActionType<typeof fetchAvailabilitySuccess>
  | ActionType<typeof fetchAvailabilitySuccessBFF>;

// Reducer
const reducer = createReducer<InitialStateType, ActionTypes>(initialState)
  .handleType(
    ServiceAreaActionTypes.FETCH_SUCCESS,
    (state: InitialStateType, action: ActionType<typeof fetchServiceAreaSuccess>) => ({
      ...state,
      suggestions: action.payload.suggestions,
    })
  )
  .handleType(ServiceAreaActionTypes.CLEAR, (state: InitialStateType) => ({
    ...state,
    suggestions: [] as ServiceAreaResponse[],
  }))
  .handleType(
    AvailabilityActionTypes.FETCH_SUCCESS,
    (state: InitialStateType, action: ActionType<typeof fetchAvailabilitySuccess>) => ({
      ...state,
      availability: action.payload.availabilities,
    })
  )
  .handleType(
    AvailabilityActionTypes.FETCH_SUCCESS_BFF,
    (state: InitialStateType, action: ActionType<typeof fetchAvailabilitySuccessBFF>) => ({
      ...state,
      availabilityBFF: action.payload,
    })
  )
  .handleType(AvailabilityActionTypes.CLEAR, (state: InitialStateType) => ({
    ...state,
    availability: [] as AvailabilityResponse[],
    availabilityBFF: false,
  }));

export type ServiceAreaState = StateType<typeof reducer> | undefined;

export default reducer;
