// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';

import environment from './src/utils/environment.ts';

if (environment.SENTRY_DSN) {
  const release =
    environment.SENTRY_RELEASE_PREFIX &&
    environment.SENTRY_RELEASE &&
    `${environment.SENTRY_RELEASE_PREFIX}${environment.SENTRY_RELEASE}`;

  init({
    dsn: environment.SENTRY_DSN || 'https://064b49a24cf74f1d97b0f8a8b7bf9e48@o925125.ingest.sentry.io/5338726',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: environment.ENVIRONMENT,
    normalizeDepth: 7,
    maxBreadcrumbs: 200,
    ...(environment.isProduction && release ? { release } : {}),
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
