import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface Styles {
  borderColor: string;
  backgroundColor: string;
}

export const useSnackBarBase = makeStyles()(({ palette, spacing }: Theme) => ({
  root: {
    padding: spacing(2),
    borderLeft: spacing(0.5),
    borderLeftStyle: 'solid',
    color: palette.common.black,
  },
}));

export const useSnackBarTheme = makeStyles<Styles>()((_theme, { borderColor, backgroundColor }) => ({
  root: {
    borderColor,
    backgroundColor,
  },
}));
