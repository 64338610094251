import * as R from 'ramda';
import { createSelector } from 'reselect';

import { AppState } from '../reducer';
import { SessionState } from './reducer';

export const sessionState$ = (state: AppState) => state.session;

export const authenticated$ = createSelector([sessionState$], (state: SessionState) =>
  R.pathOr(false, ['authenticated'], state)
);

export const token$ = createSelector([sessionState$], (session: SessionState) => session.token);

export const firstName$ = createSelector([sessionState$], (state: SessionState) =>
  R.pathOr(undefined, ['firstName'], state)
);

/** The pricing tier the logged-in customer is on. */
export const pricingRegionId$ = createSelector([sessionState$], (session) => session.pricingRegionId);
