import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useWrapper = makeStyles()(({ spacing }: Theme) => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: spacing(3),
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    display: 'block',
  },
}));
