import { createSelector } from 'reselect';
import { pathOr, propEq } from 'ramda';

import { AppState } from '../reducer';
import { NotificationKind } from './types';
import { NotificationState } from './reducer';

export const notificationState$ = (state: AppState) => pathOr([], ['notifications'], state);

export const snackbarNotifications$ = createSelector([notificationState$], (notifications: NotificationState) =>
  notifications.filter(propEq('kind', NotificationKind.SNACKBAR))
);

export const dialogueNotifications$ = createSelector([notificationState$], (notifications: NotificationState) =>
  notifications.filter(propEq('kind', NotificationKind.DIALOGUE))
);
