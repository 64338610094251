import { ActionType } from 'typesafe-actions';

export type ErrorsState = Record<string, string>;
const initialState: ErrorsState = {};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state: ErrorsState = initialState, action: ActionType<any>) => {
  const { type } = action;
  const matches = /(.*)\/.*_(PERFORM|SUCCESS|ERROR)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'ERROR' ? action.error : null,
  };
};

export default reducer;
