import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { StaticImageData } from 'next/image';
import { capitalize } from '@mui/material';

import boral from '../../../assets/images/boral.png';
import concrite from '../../../assets/images/concrite.png';
import gunlake from '../../../assets/images/gunlake.jpg';
import hytec from '../../../assets/images/hy-tec.png';
import readymix from '../../../assets/images/readymix.png';
import { AppContextField } from '../../../context/app/types';

export enum InvoiceGroup {
  OVERDUE = 'OVERDUE',
  DUE = 'DUE',
  DUE_BEFORE_END_OF_MONTH = 'DUE_BEFORE_END_OF_MONTH',
  DUE_AFTER_END_OF_MONTH = 'DUE_AFTER_END_OF_MONTH',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAID = 'PAID',
}

export type PostPayInvoiceGroup =
  | InvoiceGroup.OVERDUE
  | InvoiceGroup.DUE_BEFORE_END_OF_MONTH
  | InvoiceGroup.DUE_AFTER_END_OF_MONTH
  | InvoiceGroup.PAYMENT_PENDING;
export type PrePayInvoiceGroup = InvoiceGroup.OVERDUE | InvoiceGroup.DUE;

export enum OrderSorting {
  INVOICE_DUE,
  DELIVER_DATE,
}

const UPCOMING_ORDERS = 'Upcoming';
const DELIVERED_ORDERS = 'Delivered';

export enum OrderFilter {
  UPCOMING,
  DELIVERED,
}

export const OrderFilterMap = {
  [OrderFilter.UPCOMING]: UPCOMING_ORDERS,
  [OrderFilter.DELIVERED]: DELIVERED_ORDERS,
};

export const OrderFilterTitle = [UPCOMING_ORDERS, DELIVERED_ORDERS];

export enum OrderMode {
  VIEW,
  EDIT,
  NEW,
  /** This will let us know that we don't need to clear the cart */
  QUOTE_TO_ORDER,
}

export enum Suppliers {
  BORAL = 'boral',
  CONCRITE = 'concrite',
  ADVANCED_READYMIX = 'advanced-readymix',
  HY_TEC = 'hy-tec',
}

export const supplierLogos: Record<string, StaticImageData> = {
  'advanced-readymix': readymix,
  'hy-tec': hytec,
  boral,
  concrite,
  gunlake,
};

export const orderFilterFromParams = (filter: string | undefined) =>
  filter ? (OrderFilterTitle.indexOf(capitalize(filter)) as OrderFilter) : undefined;

/** Correctly format and map URL quieries, and only pass back those with values. */
export const mapValuesFromParams = (params: Record<string, any>) =>
  omitBy(
    {
      [AppContextField.FILTER]: orderFilterFromParams(params.filter),
      [AppContextField.ORDER_ID]: params.order,
      [AppContextField.QUOTE_ID]: params.quote,
    },
    isNil
  );
