import { ThemeOptions } from '@mui/material/styles';

const containers: ThemeOptions['components'] = {
  MuiContainer: {
    styleOverrides: {
      root: ({ theme, ownerState }) =>
        !ownerState.disableGutters
          ? {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(9),
                paddingRight: theme.spacing(9),
              },
            }
          : {},
    },
  },
};

export default containers;
