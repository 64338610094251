import { IdentifyField, TrackingEvent } from './types';

import { bffActions } from '../../bff';

import { isNil } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import type { TrackLinkProps, TrackingEventProps } from './types';

let pageName = '';
let pageStartTime = 0;
let pageAppearTime = 0;
let isCurrentPageSentMetric = false;
let localEmail = '';
declare const window: any;

const isBrowser = () => typeof window !== 'undefined' && !isNil(window.origin) && window.origin !== 'null';

export function setPageName(name: string): void {
  pageName = name;
}
export function setPageStart(): void {
  pageStartTime = new Date().getTime();
  isCurrentPageSentMetric = false;
}
export function setPageRendered(): void {
  pageAppearTime = new Date().getTime();
}

function addTrackingToPool(item: { type: string; value: string; time: number; route: string }) {
  if (isBrowser()) {
    const tracking = JSON.parse(window.localStorage.getItem('tracking')) || [];
    tracking.push(item);
    window.localStorage.setItem('tracking', JSON.stringify(tracking));
  }
}

function resetTrackingToPool() {
  if (isBrowser()) {
    window.localStorage.setItem('tracking', JSON.stringify([]));
  }
}

function getTrackingToPool() {
  return JSON.parse(window.localStorage.getItem('tracking')) || [];
}

export function addFoundTracking(type: string, value: string) {
  if (isBrowser()) {
    addTrackingToPool({
      type,
      value,
      time: new Date().getTime(),
      route: `${window.location.pathname}${window.location.search}`,
    });
  }
}

let trackingUserId: number | string | undefined | null = null;

function getUuid() {
  let returnValue = isBrowser() ? window.localStorage.getItem('uuid') : '';
  if (!returnValue) {
    returnValue = uuidv4();
    if (isBrowser()) {
      window.localStorage.setItem('uuid', returnValue);
    }
  }
  return returnValue;
}

export function resetUuid() {
  const returnValue = uuidv4();
  if (isBrowser()) {
    window.localStorage.setItem('uuid', returnValue);
  }
}

const uuid = getUuid();

function sendEvent() {
  const path = window.location.pathname;
  const isV2 = path.substr(0, 2) === '/p';
  if (getTrackingToPool().length > 0 && isBrowser() && window.navigator.onLine && !isV2) {
    void bffActions.tracking.addTracking({
      events: getTrackingToPool(),
      meta: {
        userAgent: window.navigator.userAgent,
        os: window.navigator.appVersion,
      },
      uuid,
      userId: trackingUserId,
    });
    resetTrackingToPool();
  }
}

if (isBrowser()) {
  const root = document.querySelector('body');

  if (root) {
    root.addEventListener('click', (e: any) => {
      if (e && e.target && e.target.innerText) {
        addFoundTracking('click', e.target.innerText);
      }
    });
  }
}

if (isBrowser()) {
  sendEvent();
  setInterval(() => {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        sendEvent();
      });
    } else {
      sendEvent();
    }
  }, 30 * 1000);
}

function resetMetric() {
  pageName = '';
  pageStartTime = 0;
  pageAppearTime = 0;
  isCurrentPageSentMetric = false;
}

const identify = (props: { [IdentifyField.ID]?: number; [IdentifyField.EMAIL]?: string }) => {
  const { [IdentifyField.ID]: id, [IdentifyField.EMAIL]: email } = props;
  localEmail = email || '';
  trackingUserId = id;
  if (id) {
    window.analytics?.identify(id, { email: localEmail });
  } else {
    window.analytics?.identify();
  }
};

const page = (name: string) => {
  setPageStart();
  window.analytics?.page(name, { email: localEmail });
};

const reset = () => {
  if (process.env.BROWSER) {
    window.analytics?.reset();
  }
};

// https://stackoverflow.com/a/53143568/3114826
const event: TrackingEventProps = (name: string, traits: any) => {
  // console.log(name, traits);
  if (traits) {
    window.analytics?.track(name, { email: localEmail, ...traits });
    addFoundTracking(name, JSON.stringify({ email: localEmail, ...traits }));
  } else {
    window.analytics?.track(name, { email: localEmail });
    addFoundTracking(name, JSON.stringify({ email: localEmail }));
  }
};

const trackLink: TrackLinkProps = (htmlLink, name: any, traits: any) => {
  if (traits) {
    window.analytics?.trackLink(htmlLink, name, { email: localEmail, ...traits });
  } else {
    window.analytics?.trackLink(htmlLink, name, { email: localEmail });
  }
};

export function setPageLoaded(): void {
  if (!isCurrentPageSentMetric && pageName) {
    const metricContent = {
      PageName: pageName,
      FMP: pageAppearTime - pageStartTime,
      TTI: new Date().getTime() - pageStartTime,
    };
    event(TrackingEvent.PAGE_METRIC, metricContent);
    isCurrentPageSentMetric = true;
  }
  if (!pageName) {
    resetMetric();
  }
}

export function sendTrace(obj: any): void {
  event(TrackingEvent.TRACE, obj);
}

export const trackingAnalytics = { identify, page, reset, event, trackLink };

export default trackingAnalytics;
