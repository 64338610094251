import { signupOrSigninSaga } from './signup';

import handleError from '../../handleError';
import tracking, { resetUuid } from '../../../utils/tracking';
import { AccountField } from '../../../utils/forms';
import { SessionActionTypes } from '../types';
import { TrackingEvent } from '../../../utils/tracking/types';
import {
  createSessionError,
  createSessionSuccess,
  deleteError,
  deleteSuccess,
  requestCodeError,
  requestCodeSuccess,
} from '../actions';
import { fetchProfilePerform } from '../../profile/actions';
import { token$ } from '../selectors';
import { apiActions } from '../../../api';

import { parsePhoneNumber } from 'react-phone-number-input/input';
import { call, put, select, spawn, takeLatest } from 'typed-redux-saga';

import type { createSessionPerform, deletePerform, requestCodePerform } from '../actions';

// / /////////////////////////////  REQUEST CODE  ////////////////////////////////
// async function requestAuthCode(mobile: string) {
//   const data = { mobile_auth: { mobile } };
//   return axiosInstance.post(ApiEndpoint.MOBILE_AUTH, data);
// }

function* requestCodeInstanceSaga(action: ReturnType<typeof requestCodePerform>) {
  try {
    const { payload, meta } = action;
    // Temp solution
    const nationalMobile = parsePhoneNumber(payload[AccountField.MOBILE], 'AU')?.nationalNumber;
    const nationalMobileLeadingZero = `0${nationalMobile}`;

    if (!nationalMobile) throw Error('Incorrect mobile format');

    const res = yield* call(apiActions.session.requestAuthCode, nationalMobileLeadingZero);

    meta?.callback();

    yield* put(requestCodeSuccess(res.right));
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(requestCodeError(error));
    });
  }
}

function* requestCodeSaga() {
  yield* takeLatest(SessionActionTypes.REQUEST_CODE_PERFORM, requestCodeInstanceSaga);
}

// / /////////////////////////////  VERIFY CODE //////////////////////////////////
// async function verifyAuthCode(mobile: string, password: string) {
//   const data = {
//     session: { mobile, key: password.toString() },
//   };
//   return axiosInstance.post(ApiEndpoint.SESSION, data).then(decodeEntityResponse(SessionResponseCodec));
// }

// async function deleteSession(token: string) {
//   const data = {
//     data: {
//       token,
//     },
//   };

//   return axiosInstance.delete(ApiEndpoint.SESSION, data);
// }

export function* createSessionInstanceSaga(action: ReturnType<typeof createSessionPerform>) {
  try {
    const { meta } = action;

    const { mobile, verificationCode } = action.payload;

    const result = yield* call(apiActions.session.postAuthCodeVerification, mobile, verificationCode);

    yield* put(createSessionSuccess(result.right, meta));

    if (meta?.callback) meta.callback();

    yield* put(fetchProfilePerform({ callback: meta?.profileCallback }));
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(createSessionError(error));
    });
  }
}

function* createSessionSaga() {
  yield* takeLatest(SessionActionTypes.CREATE_SESSION_PERFORM, createSessionInstanceSaga);
}

// / ///////////////////////////////  SIGNOUT ////////////////////////////////////
function* logoutInstanceSaga(action: ReturnType<typeof deletePerform>) {
  try {
    const { meta } = action;

    const token = yield* select(token$);

    yield* call(apiActions.session.deleteSession, token);
    yield* put(deleteSuccess({ authenticated: false }));

    tracking.event(TrackingEvent.SIGN_OUT);
    resetUuid();

    if (meta?.callback) meta.callback();
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(deleteError(error));
    });
  }
}

function* logoutSaga() {
  yield* takeLatest(SessionActionTypes.DELETE_PERFORM, logoutInstanceSaga);
}

const spawnedSessionSagas = [
  spawn(requestCodeSaga),
  spawn(createSessionSaga),
  spawn(logoutSaga),
  spawn(signupOrSigninSaga),
];

export default spawnedSessionSagas;
