import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertBox from '../AlertBox';
import ErrorNotice from '../ErrorNotice';
import { Notification } from '../../store/notifications/types';
import { dialogueNotifications$ } from '../../store/notifications/selectors';
import { remove } from '../../store/notifications/actions';

function NotificationDialogue() {
  const dispatch = useDispatch();
  const notifications = useSelector(dialogueNotifications$);
  const [notification, setNotification] = useState<Notification | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!notifications.length) {
      setNotification(undefined);
    }

    if (notifications.length && notifications[0] !== notification) {
      setNotification(notifications[0]);
      setTimeout(() => setIsOpen(true), 500);
    }
  }, [notification, notifications]);

  const removeNotification = () => {
    if (notification) {
      dispatch(remove(notification.id));
    }
  };

  const handleClose = () => {
    removeNotification();
    setIsOpen(false);
  };

  if (notification) {
    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4">Something went wrong!</Typography>
            <Box my={3}>
              <AlertBox severity={notification.type}>{notification && notification.message}</AlertBox>
            </Box>
            {notification.type === 'error' && <ErrorNotice />}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
}

export default NotificationDialogue;
