import * as schemas from './schema';
import { OrderActionTypes } from './types';
import {
  createOrderError,
  createOrderSuccess,
  deleteOrderError,
  deleteOrderSuccess,
  fetchOrdersError,
  fetchOrdersFirstSuccess,
  fetchOrdersSuccess,
  loadError,
  loadSuccess,
  stopPolling,
  updateOrderSuccess,
} from './actions';
import { order$ } from './selectors';

import { addSuccess } from '../notifications/actions';
import handleError from '../handleError';
import { updateError } from '../entities/actions';
import { apiActions } from '../../api';

import { normalize } from 'normalizr';
import { call, delay, put, race, select, spawn, take, takeLatest } from 'typed-redux-saga';

import type {
  createOrderPerform,
  deleteOrderPerform,
  fetchOrdersFirstPerform,
  fetchOrdersPerform,
  loadPerform,
  startPolling,
  updateOrderPerform,
} from './actions';
import type { Right } from 'fp-ts/lib/Either';
import type { OrderResponse, OrdersResponse } from 'foundshared/src/api/orders/types';

const normaliseEntityResponse = (result: Right<OrderResponse>) =>
  normalize<OrdersResponse>(result.right, schemas.orderEntitySchema);
const normaliseEntitysResponse = (result: Right<OrdersResponse>) =>
  normalize<OrdersResponse>(result.right, schemas.orderCollectionSchema);

export function* ordersInstanceSaga(action: ReturnType<typeof fetchOrdersPerform>) {
  try {
    const result = yield* call(apiActions.orders.fetchOrders, false);
    const resultNormalised = normaliseEntitysResponse(result);

    yield* put(fetchOrdersSuccess(resultNormalised, action.meta));

    if (action.meta?.callback) action.meta.callback();
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(fetchOrdersError(error));
    });
  }
}

export function* ordersFirstInstanceSaga(action: ReturnType<typeof fetchOrdersFirstPerform>) {
  try {
    const result = yield* call(apiActions.orders.fetchOrders, true);
    const resultNormalised = normaliseEntitysResponse(result);

    yield* put(fetchOrdersFirstSuccess(resultNormalised, action.meta));

    if (action.meta?.callback) action.meta.callback();
  } catch (error) {
    yield* handleError(error, put, function* () {
      yield* put(fetchOrdersError((error as any).message));
    });
  }
}

function* orderInstanceSaga(action: ReturnType<typeof loadPerform>) {
  const { id } = action.payload;
  try {
    const result = yield* call(apiActions.orders.fetchOrder, id);
    const resultNormalised = normaliseEntityResponse(result);

    yield* put(loadSuccess(resultNormalised));
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(loadError(error));
    });
  }
}

// / /////////////////////////////// FETCH POLL //////////////////////////////////
function* fetchPollPerform(action: ReturnType<typeof startPolling>) {
  const { id } = action.payload;

  while (true) {
    try {
      const result = yield* call(apiActions.orders.fetchOrder, id);
      const resultNormalised = normaliseEntityResponse(result);

      yield* put(fetchOrdersSuccess(resultNormalised));
    } catch (error) {
      yield* handleError(error, put, function* () {
        if (error instanceof Error) yield* put(fetchOrdersError(error));
      });

      // this must come last because of the race condition in
      // `fetchPollSaga`
      yield* put(stopPolling());
    }
    yield* delay(500);
  }
}

function* createOrderInstanceSaga(action: ReturnType<typeof createOrderPerform>) {
  try {
    const result = yield* call(apiActions.orders.createOrder, action.payload);
    const resultNormalised = normaliseEntityResponse(result);
    if (resultNormalised.entities) {
      yield* put(createOrderSuccess(resultNormalised, action.meta));
      const id = Object.keys(resultNormalised.entities.orders!)[0];
      if (action.meta?.callback) action.meta.callback(id);
    }
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(createOrderError(error));
    });
  }
}

function* updateOrderInstanceSaga(action: ReturnType<typeof updateOrderPerform>) {
  try {
    const result = yield* call(apiActions.orders.updateOrder, action.payload.id, action.payload.data);
    const resultNormalised = normaliseEntityResponse(result);

    yield* put(updateOrderSuccess(resultNormalised, action.meta));
    yield* put(addSuccess('Update successful!'));
    if (action.meta?.callback) action.meta.callback();
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(updateError(error));
    });
  }
}

function* deleteOrderInstanceSaga(action: ReturnType<typeof deleteOrderPerform>) {
  try {
    const { id } = action.payload;

    const order = yield* select(order$);

    const invoiceSummaries = order(id)?.invoiceSummaries;

    const result = yield* call(apiActions.orders.deleteOrder, id);

    if (result.right.removedOrder) {
      yield* put(deleteOrderSuccess(id, invoiceSummaries));
      action.meta?.callback(id);
    }
  } catch (error) {
    yield* handleError(error, put, function* () {
      if (error instanceof Error) yield* put(deleteOrderError(error));
    });
  }
}

export function* fetchPollSaga() {
  while (true) {
    const action: Then<ReturnType<typeof startPolling>> = yield take(OrderActionTypes.START_POLLING);
    yield race([call(fetchPollPerform, action), take(OrderActionTypes.STOP_POLLING)]);
  }
}

// / /////////////////////////////////  END //////////////////////////////////////

export function* fetchOrdersSaga() {
  yield* takeLatest(OrderActionTypes.FETCH_PERFORM, ordersInstanceSaga);
}

export function* fetchOrdersFirstSaga() {
  yield* takeLatest(OrderActionTypes.FETCH_PERFORM_FIRST, ordersFirstInstanceSaga);
}

export function* createOrderSaga() {
  yield* takeLatest(OrderActionTypes.CREATE_PERFORM, createOrderInstanceSaga);
}

export function* deleteOrderSaga() {
  yield* takeLatest(OrderActionTypes.DELETE_PERFORM, deleteOrderInstanceSaga);
}

export function* loadOrderSaga() {
  yield* takeLatest(OrderActionTypes.LOAD_PERFORM, orderInstanceSaga);
}

export function* updateOrderSaga() {
  yield* takeLatest(OrderActionTypes.UPDATE_PERFORM, updateOrderInstanceSaga);
}

const spawnedAppSagas = [
  spawn(createOrderSaga),
  spawn(deleteOrderSaga),
  spawn(fetchOrdersSaga),
  spawn(fetchOrdersFirstSaga),
  spawn(fetchPollSaga),
  spawn(loadOrderSaga),
  spawn(updateOrderSaga),
];

export default spawnedAppSagas;
