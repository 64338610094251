import type { CSSObject } from 'tss-react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useLayoutStyles = makeStyles()(({ mixins, palette }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  toolbar: {
    ...(mixins.toolbar as CSSObject),
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    flexGrow: 1,
    overflow: 'scroll',
  },
  content: {
    flexGrow: 1,
    backgroundColor: palette.background.default,
  },
}));
