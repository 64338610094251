import { declareActionFactory } from '../utilities';

import type { Notifier } from '../utilities';
import type { Availabilities } from 'foundshared/src/api/availability/codecs';

const declareAction = declareActionFactory('@@availability');

// ////////////////////////////////// fetch  ////////////////////////////////////
export const fetch = declareAction<{
  notifier: Notifier<Availabilities>;
  locality: string;
  postcode: string;
}>('fetch');

// //////////////////////////////  fetchSuccess  ////////////////////////////////
export const fetchSuccess = declareAction<Availabilities>('fetchSuccess');
