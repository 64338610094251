import { ThemeOptions } from '@mui/material/styles';

const alerts: ThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      standardInfo: ({ theme }) => ({
        color: theme.palette.text.primary,
        '& .Mui-icon': {
          color: 'inherit',
        },
      }),
      standardWarning: ({ theme }) => ({
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.warning.main,
        '& .Mui-icon': {
          color: 'inherit',
        },
      }),
      standardSuccess: ({ theme }) => ({
        color: theme.palette.text.primary,
        '& .Mui-icon': {
          color: 'inherit',
        },
      }),
      standardError: ({ theme }) => ({
        color: theme.palette.text.primary,
        '& .Mui-icon': {
          color: 'inherit',
        },
      }),
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        fontWeight: 700,
      },
    },
  },
};

export default alerts;
